import EngineeringIcon from '@mui/icons-material/Engineering';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';

export type TSelectedView = 'medical' | 'service' | 'service setting'
export interface IViewSelectorProps {
    initialSelection: TSelectedView
    selectionCallback: (selected: TSelectedView) => void
}

export default function ViewSelector(props: IViewSelectorProps) {
  const [selection, setSelection] = useState(props.initialSelection)

  const handleSelection = (
    event: React.MouseEvent<HTMLElement>,
    newSelection: TSelectedView,
  ) => {
    if (newSelection !== null) {
      setSelection(newSelection)
      props.selectionCallback(newSelection)
    }
  }

  return (
    <Box sx={{display:'flex', height:'0px', mt:'-10px'}}>
    <ToggleButtonGroup sx={{ml:'auto'}}
      value={selection}
      exclusive
      onChange={handleSelection}
    >
      <ToggleButton value={'medical'} size='medium'>
        <MedicalServicesIcon />
      </ToggleButton>
      <ToggleButton value={'service'} size='medium'>
        <EngineeringIcon />
      </ToggleButton>
      <ToggleButton value={'service setting'} size='medium'>
        <SettingsIcon />
      </ToggleButton>
    </ToggleButtonGroup>
    </Box>
  )
}