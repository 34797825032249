import { createAsyncThunk } from '@reduxjs/toolkit'
import { IBasicEntityBase } from '../data/Common'
import { TMemProcData } from '../data/ProcData'
import { TimelineValuesArray, TTrasposedDataContainer } from './transposeData'
import { getUserInfoAsync } from '../utils/PrinicpalUtils'

export function createGetDataThunk(contextString: string) {
    return createAsyncThunk(
        contextString,
        async (identity: IBasicEntityBase) => {
            const requestUrl = `/api/getProcData?partitionKey=${identity.partitionKey}&rowKey=${identity.rowKey}`
            const response = await fetch(requestUrl)
            if (response.ok) {
                const json: TMemProcData = await response.json()
                return json
            } else {
                console.log(`Error fetching proc data ${identity.partitionKey}:${identity.rowKey}. Status: ${response.status}`)
                return null
            }
        }
    )
}


export async function updateActionLogsAsync(contextString: string) {
    createAsyncThunk(
        contextString,
        async (identity: IBasicEntityBase) => {
            const user = await getUserInfoAsync()
            const email = user.userDetails

            const response = await fetch('/api/addUserActionLogs', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({'identity': identity, 'email': email})
            })
            
            return response.ok ? '' : response.statusText
        }
    )
    
  
  }

export interface IProcDataState {
    data: TMemProcData
    transposed?: TTrasposedDataContainer
    chartData?: TimelineValuesArray  
    status: 'idle' | 'loading' | 'failed'
}

export function  createInitialProcDataState(): IProcDataState  {
    return {   
        data: {...initialStepDataProto},
        status: 'idle'
    }
}


export const initialStepDataProto:TMemProcData = {
    '0:00': {},
    '0:10': {},
    '0:20': {},
    '32°C': {},
    '0:30': {},
    '0:40': {},
    '0:50': {},
    '0:55': {},
    '1:30': {},
    '1:55': {},
    '2:30': {},
    '2:55': {},
    '3:30': {},
    '3:55': {},
    '4:30': {},
    '4:55': {},
    '5:30': {},
    '5:55': {},
    '6:30': {},
    '6:55': {},
    '7:30': {},
    '7:55': {},
    '8:30': {},
    '8:55': {},
    '9:30': {},
    '9:55': {},
    '10:30': {},
    '10:55': {},
    '11:30': {},
    '11:55': {},
    '12:30': {},
    '12:55': {},
    '13:30': {},
    '13:55': {},
    '14:30': {},
    '14:55': {},
    '15:30': {},
    '15:55': {},
    '16:30': {},
    '16:55': {},
    '17:30': {},
    '17:55': {},
    '18:30': {},
    '18:55': {},
    '19:30': {},
    '19:55': {},
    '20:30': {},
    '20:55': {},
    '21:30': {},
    '21:55': {},
    '22:30': {},
    '22:55': {},
    '23:30': {},
    '23:55': {},
    '24:30': {},
    '24:55': {},
    '25:30': {},
    '25:55': {},
    '26:30': {},
    '26:55': {},
    '27:30': {},
    '27:55': {},
    '28:30': {},
    '28:55': {},
    '29:30': {},
    '29:55': {},
    '30:30': {},
    '30:55': {},
    '31:30': {},
    '31:55': {},
    '32:30': {},
    '32:55': {},
    '33:30': {},
    '33:55': {},
    '34:30': {},
    '34:55': {},
    '35:30': {},
    '35:55': {},
    '36:30': {},
    '36:55': {},
    '37:30': {},
    '37:55': {},
    '38:30': {},
    '38:55': {},
    '39:30': {},
    '39:55': {},
    '40:30': {},
    '40:55': {},
    '41:30': {},
    '41:55': {},
    '42:30': {},
    '42:55': {},
    '43:30': {},
    '43:55': {},
    '44:30': {},
    '44:55': {},
    '45:30': {},
    '45:55': {},
    '46:30': {},
    '46:55': {},
    '47:30': {},
    '47:55': {},
    '48:30': {},
    '48:55': {},
    '49:30': {},
    '49:55': {},
    '50:30': {},
    '50:55': {},
    '51:30': {},
    '51:55': {},
    '52:30': {},
    '52:55': {},
    '53:30': {},
    '53:55': {},
    '54:30': {},
    '54:55': {},
    '55:30': {},
    '55:55': {},
    '56:30': {},
    '56:55': {},
    '57:30': {},
    '57:55': {},
    '58:30': {},
    '58:55': {},
    '59:30': {},
    '59:55': {},
    '60:30': {},
    '60:55': {},
    '61:30': {},
    '61:55': {},
    '62:30': {},
    '62:55': {},
    '63:30': {},
    '63:55': {},
    '64:30': {},
    '64:55': {},
    '65:30': {},
    '65:55': {},
    '66:30': {},
    '66:55': {},
    '67:30': {},
    '67:55': {},
    '68:30': {},
    '68:55': {},
    '69:30': {},
    '69:55': {},
    '70:30': {},
    '70:55': {},
    '71:30': {},
    '71:55': {},
    '72:30': {},
    '72:55': {},
    '73:30': {},
    '73:55': {},
    '74:30': {},
    '74:55': {},
    '75:30': {},
    '75:55': {},
    '76:30': {},
    '76:55': {},
    '77:30': {},
    '77:55': {},
    '78:30': {},
    '78:55': {},
    '79:30': {},
    '79:55': {},
    '80:30': {},
    '80:55': {},
    '81:30': {},
    '81:55': {},
    '82:30': {},
    '82:55': {},
    '83:30': {},
    '83:55': {},
    '84:30': {},
    '84:55': {},
    '85:30': {},
    '85:55': {},
    '86:30': {},
    '86:55': {},
    '87:30': {},
    '87:55': {},
    '88:30': {},
    '88:55': {},
    '89:30': {},
    '89:55': {},
    '90:30': {},
    '90:55': {},
    '91:30': {},
    '91:55': {},
    '92:30': {},
    '92:55': {},
    '93:30': {},
    '93:55': {},
    '94:30': {},
    '94:55': {},
    '95:30': {},
    '95:55': {},
    '96:30': {},
    '96:55': {},
    '97:30': {},
    '97:55': {},
    '98:30': {},
    '98:55': {},
    '99:30': {}
}
