import { Link as MUILink, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { SyntheticEvent, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { EntityTypeIndex, EntityTypes } from '../../data/Common'
import { replaceHeader } from '../../utils/replaceHeader'
import WaitingLabel from '../../utils/WaitingLabel'
import { useAppDispatch, useAppSelector } from './AdminReduxHooks'
import { store } from './AdminStore'
import { selectStatus as selectDevicesStatus } from './device/DevicesSlice'
import { selectStatus as selectUserLogsStatus } from './userLogs/UserLogsSlice'
import { getDevicesAsync } from './device/DevicesThunks'
import { getServiceSettingsAsync } from './device/service/ServiceSettingsThunk'
import { selectStatus as selectHospitalStatus } from './org/HospitalsSlice'
import { selectStatus as selectLabStatus } from './org/LabsSlice'
import { getHospitalsAsync, getLabsAsync } from './org/OrgThunks'
import { selectStatus as selectDoctorStatus } from './person/DoctorsSlice'
import { getDoctorsAsync, getTechsAsync } from './person/PersonThunks'
import { selectStatus as selectTechStatus } from './person/TechsSlice'
import HelpBox from '../../common/HelpBox'
import { getUserLogsAsync } from './userLogs/UserLogsThunk'

export default function Admin() {
  return (
    <Provider store={store}>
      <Administrator/>
    </Provider>
  )
}

function Administrator() {
  const [tabIndex, setTabIndex] = useState(0)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const labStatus = useAppSelector(selectLabStatus)  
  const hospitalStatus = useAppSelector(selectHospitalStatus)  
  const devicesStatus = useAppSelector(selectDevicesStatus)  
  const techStatus = useAppSelector(selectTechStatus)  
  const doctorStatus = useAppSelector(selectDoctorStatus)
  const logsStatus = useAppSelector(selectUserLogsStatus)
  
  useEffect(() => {
    replaceHeader('Admin', `${window.location.origin}/adminIcon.ico`)
  },[])

  
  useEffect(() => {
    dispatch(getLabsAsync())
    dispatch(getHospitalsAsync())
    dispatch(getTechsAsync())
    dispatch(getDoctorsAsync())
    dispatch(getDevicesAsync())
    dispatch(getServiceSettingsAsync())
    dispatch(getUserLogsAsync())
  },[dispatch])

  useEffect(() => {
    navigate(EntityTypes[EntityTypeIndex.lab], { replace: true })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
 
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }

  return (
      <Box sx={{ width: '100%', minWidth: '1000px' }}>
        <Stack direction={'row'}>
          <Tabs value={tabIndex} onChange={handleChange} sx={{mb:'5px'}}>
            <Tab value={0} label={<WaitingLabel title='Labs' target={labStatus} />} to={EntityTypes[EntityTypeIndex.lab]} component={Link} />
            <Tab value={1} label={<WaitingLabel title='Perfusionists' target={techStatus} />} to={EntityTypes[EntityTypeIndex.tech]} component={Link} />
            <Tab value={2} label={<WaitingLabel title='Devices' target={devicesStatus} />} to={EntityTypes[EntityTypeIndex.device]} component={Link} />
            <Tab value={3} label="Procedures" to={EntityTypes[EntityTypeIndex.procedure]} component={Link} />
            <Tab value={4} label={<WaitingLabel title='Hospitals' target={hospitalStatus} />} to={EntityTypes[EntityTypeIndex.hospital]} component={Link} />
            <Tab value={5} label={<WaitingLabel title='Surgeons' target={doctorStatus} />} to={EntityTypes[EntityTypeIndex.doctor]} component={Link} />
            <Tab value={6} label='Consultants' to='consultant' component={Link} />
            <Tab value={7} label={<WaitingLabel title='User Logs' target={logsStatus} />} to={EntityTypes[EntityTypeIndex.userLogs]} component={Link} />

          </Tabs>
          <Box sx={{ position: 'absolute', top: 0, right: 8 }}>
            <HelpBox/>
            <MUILink href='/.auth/logout'>Log out</MUILink>
          </Box>
        </Stack>
        <Outlet />
      </Box>
  )
}

