import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/DoneOutline'
import { default as Button, default as IconButton } from '@mui/material/Button'
import { Component, MouseEvent } from 'react'

const numpadStyle = {
    width: 100,
    height: 50,
    margin: '1px'
};

const clearStyle = { ...numpadStyle, color: 'red', 'background': 'lightgrey' }
const checkStyle = { ...numpadStyle, color: 'green', 'background': 'lightgrey' }


export interface NumpadProps {
    resultcallback: (result: string) => void
    buttonClickedCallback?: () => void
    encodeResultDisplay? : boolean
}

type NumpadState = {
    resultString: string,
    encoded: string
}

type SpecialButtonProps = {
    resultString: string,
    callback: (event: MouseEvent<HTMLButtonElement>) => void
}

class DoneButton extends Component<SpecialButtonProps>{
    render() {
        return (
            this.props.resultString.length === 0
                ?<IconButton sx={checkStyle} disabled onClick={this.props.callback}> <CheckIcon /> </IconButton>
                :<IconButton sx={checkStyle} onClick={this.props.callback}> <CheckIcon /> </IconButton>
        )
    }
}

class ClearButton extends Component<SpecialButtonProps>{
    render() {
        return (
            this.props.resultString.length === 0
                ?<IconButton sx={clearStyle} disabled onClick={this.props.callback}> <ClearIcon /> </IconButton>
                :<IconButton sx={clearStyle} onClick={this.props.callback}> <ClearIcon /> </IconButton>
        )
    }
}

export default class Numpad extends Component<NumpadProps, NumpadState> {
    
    constructor(props: NumpadProps){
        super(props)
        this.state = {resultString: '', encoded: ''}
        this.handleDoneClick = this.handleDoneClick.bind(this)
        this.handleNumberClick = this.handleNumberClick.bind(this)
        this.handleClearClick = this.handleClearClick.bind(this)
    }

    invokeClickCallback = () => {
        if (typeof this.props.buttonClickedCallback == 'function')
        {
            this.props.buttonClickedCallback(); 
        }        
    }

    handleNumberClick(char: string) {
        this.invokeClickCallback()
        this.setState({resultString: this.state.resultString + char, encoded: this.state.encoded + '*'})
    }

    handleDoneClick(event: MouseEvent<HTMLButtonElement>) {
        this.invokeClickCallback()
        this.props.resultcallback(this.state.resultString)
    }

    handleClearClick(event: MouseEvent<HTMLButtonElement>) {
        this.invokeClickCallback()
        this.setState({resultString: '', encoded: ''})
    }

    render() {
        return (
            <div style={{width: '307px'}}>
                <div style={{ width: '303px', textAlign:'center', fontWeight: 'semibold', marginBottom:'10px',  border:'solid', borderWidth:'1px', height:'20px'}}>
                    {this.props.encodeResultDisplay ? this.state.encoded : this.state.resultString}</div>
                <div>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('1')}>1</Button>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('2')}>2</Button>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('3')}>3</Button>
                </div>
                <div>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('4')}>4</Button>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('5')}>5</Button>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('6')}>6</Button>
                </div>
                <div>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('7')}>7</Button>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('8')}>8</Button>
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('9')}>9</Button>
                </div>
                <div>
                    <ClearButton resultString={this.state.resultString} callback={this.handleClearClick}/> 
                    <Button sx={numpadStyle} variant='contained' onClick={() => this.handleNumberClick('0')}>0</Button>
                    <DoneButton resultString={this.state.resultString} callback={this.handleDoneClick}/> 
                </div>
            </div>
        )
    }
}


