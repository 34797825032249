import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function ImageCompare() {
    const { first, second } = useParams();
    
    const imgStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        objectFit: 'contain', // You can use 'contain' or other values based on your requirement
      };
    return (
      <Grid container>
        <Grid item xs={6}>
            <img width='100%' src={first} alt='' style={imgStyle} />
        </Grid>
        <Grid item xs={6}>
            <img width='100%' src={second} alt='' style={imgStyle} />
        </Grid>
      </Grid>
    )
}