import { Checkbox, Divider, FormControl, FormControlLabel, Grid, MenuItem, Stack, TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { IOrg } from '../../../data/Org'
import { IPerson } from '../../../data/Person'

export interface IEditFormProps {
    data: IOrg
    type: string
    onDataItemChanged: (id: string, value: string | boolean) => void
    contactSource: Array<IPerson>
}


export default function EditForm(props: IEditFormProps) {
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        props.onDataItemChanged(id, id === 'isActive' ||  id === 'insighTxPermission' ? 
            (e.target as HTMLInputElement).checked : e.target.value)
    }

    return (
        <FormControl fullWidth>
            <Divider textAlign="left" sx={{mb:'10px'}}>Organization</Divider>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <FormControlLabel sx={{mt:'10px'}} control={<Checkbox id='isActive' onChange={onChange} checked={props.data['isActive']}  />} label="Active" />
                </Grid>
                
                <Grid item xs={3}>
                    <TextField fullWidth label='Label' 
                        value={props.data['label']} id='label' onChange={onChange} 
                        disabled/> 
                </Grid>
                <Grid item xs={7}>
                    <TextField fullWidth label='Full Name' 
                        value={props.data['fullName']} id='fullName' onChange={onChange} required/>
                </Grid>
                {
                props.type === 'hospital'?
                <Grid item xs={2}>
                    <FormControlLabel sx={{mt:'10px'}} control={<Checkbox id='insighTxPermission' 
                        onChange={onChange} checked={props.data.insighTxPermission || false}  />} 
                        label="InsighTx Permission" />
                </Grid>
                : null
                }
            </Grid>
            <Divider textAlign="left" sx={{mb:'10px', mt:'32px'}}>Contact</Divider>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField select fullWidth  id='contact' name='contact'
                        value={props.data['contact']}  
                        onChange={onChange} 
                        label='Select contact'>
                            {props.contactSource.map(x => 
                                <MenuItem key={x.eMail} value={x.eMail}>
                                    <Stack direction='row'>
                                        {`${x.honorific} ${x.firstName} ${x.lastName},`}
                                        <div style={{fontWeight:'bold'}} >&nbsp;&nbsp;eMail:&nbsp;</div>{x.eMail} 
                                        <div style={{fontWeight:'bold'}} >,&nbsp;&nbsp;phone #:&nbsp;</div>{x.phoneNumber} 
                                    </Stack>
                                </MenuItem>)}
                    </TextField> 
                </Grid>
            </Grid> 
        </FormControl>
    )    
}