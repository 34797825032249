import { Box, CircularProgress, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { TMediaContent } from '../../data/Media';
import { getAssessmentPointLabel, TStep } from '../../data/WorkflowSteps';
import { useAppSelector } from './monitorReduxHooks';
import { selectMediaContent } from './silceMediaContent';
import { selectTransposed } from './sliceMonitorData';
import { selectProcHeader } from './sliceMonitorProcHeader';


export default function TabMedia() {
    const transposed = useAppSelector(selectTransposed)
    const content = useAppSelector(selectMediaContent)
    const header = useAppSelector(selectProcHeader)
    let isFinised = false
    if (header?.disposition) {
        isFinised = header.disposition === 'finished'
    }
     
    
    return (
        <>
            {transposed && content
                ? <MediaTabs tabsNumber={transposed.dataLength + 1} content={content} isFinished={isFinised} />
                : <CircularProgress size='36px' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}

        </>
    )
}

function MediaTabs({ tabsNumber, content, isFinished }: { tabsNumber: number, content: TMediaContent, isFinished: boolean }) {
    const [tabValue, setTabValue] = useState<TStep>('0:00')
    const navigate=useNavigate()
    const handleTabValueChange = (event: SyntheticEvent, newValue: TStep) => {
        if (newValue !== null) {
            setTabValue(newValue)
        }
    }

    useEffect(() => {navigate(tabValue)},[navigate, tabValue] )

    return (
        <>
            <Stack direction={'row'}>
                <ToggleButtonGroup orientation='vertical' exclusive onChange={handleTabValueChange} value={tabValue}>
                    {Object.keys(content).map((x, index) =>
                        index < tabsNumber
                            ? <ToggleButton key={x} value={x} to={x} component={Link}>{getAssessmentPointLabel(x as TStep)}</ToggleButton>
                            : null
                    )}
                    {isFinished 
                        ? <ToggleButton key='END' value={'END'} to = {'END'} component={Link}>END</ToggleButton>
                        : null
                    }
                </ToggleButtonGroup>
                <Box p='5px'>
                    <Outlet />
                </Box>
            </Stack>
        </>
    )
}


