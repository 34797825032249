import { IProcHeader, IStartProcParams } from '../../../data/Procedure'

export async function createProcedureAsync(procedure: IProcHeader) {
    const response = await fetch('/api/addProcedure', { 
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(procedure)
    })
    return response.ok ? '' : response.statusText
}

export async function cancelProcedureAsync(partitionKey: string, rowKey: string) {
    const response = await fetch(`/api/cancelProcedure?partitionKey=${partitionKey}&rowKey=${rowKey}`, {
        method: 'DELETE'} )
    return response.ok ? '' : response.statusText
}

export async function archiveProcedureAsync(partitionKey: string, rowKey: string) {
    const response = await fetch(`/api/archiveProcedure?partitionKey=${partitionKey}&rowKey=${rowKey}`, {
        method: 'POST'} )
    return response.ok ? '' : response.statusText
}

export async function getArchiveAsync() {
    const response = await fetch('/api/getArchive?role=tech')
    return response.ok ? await response.json() : response.statusText
}


export async function doStartProcedureAsync(startParams: IStartProcParams) {
   await fetch('/api/startProcedure', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(startParams)
    })
}

