import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getWorkflowStateFromStep, isAssessmentStep, TRampupStep, TSteadyStep, TStep, TWorkflowState } from '../../data/WorkflowSteps'
import { IActionListItem } from './ActionsList'
import { BMRampup, BMSetup, BMSteady } from './BottomMenu'
import RunHeader from './RunHeader'
import AssessmentHost from './UIAssessmentHost'
import UIAssessmentVentParameters from './UIAssessmentVentParameters'
import UIClinicalData from './UIClinicalData'
import UIFinish from './UIFinishScreen'
import UIRampupStep0t00 from './UIRampupStep0t00'
import UIRampupStep0t10 from './UIRampupStep0t10'
import UIRampupStep0t20 from './UIRampupStep0t20'
import UIRampupStep0t30 from './UIRampupStep0t30'
import UIRampupStep0t32C from './UIRampupStep0t32C'
import UIRampupStep0t4050 from './UIRampupStep0t4050'
import UIRecruitmentStepXt30 from './UIRecruitmentStep'
import UISetup from './UISetup'
import UISteadyHost from './UISteadyHost'
import { WSRampup, WSSetup, WSSteady } from './WorkflowSteppers'
import soundFile from '../../utils/EVLPWizardProgression.mp3';
import React from 'react'


export default function RunScreen() {
    const {step} = useParams()
    const workfowState = getWorkflowStateFromStep(step as TStep)
    return (
        <RunContentSelector workflowState={workfowState} step={step as TStep}/>
    )
}

function RunContentSelector({workflowState, step}: {workflowState:TWorkflowState, step:TStep}) {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [canPlayAudio, setCanPlayAudio] = useState(false)

    useLayoutEffect(() => {
        if (canPlayAudio){
            audioRef.current?.play()
            setCanPlayAudio(false)
        }
    }, [canPlayAudio])
return (
    <>
    <audio ref={audioRef} src={soundFile} style={{ display: 'none' }}></audio>
    {
    workflowState ==='setup'?
                    <>
                    <RunHeader workflowState={workflowState} setCanPlayAudio={setCanPlayAudio} />
                    <WSSetup/>
                    {step === 'Procedure'
                        ? <UIClinicalData/>
                        : <UISetup/>
                    }
                    <BMSetup setCanPlayAudio={setCanPlayAudio} />
                    </>
     
    : workflowState === 'rampup'?

              <>
                    <RunHeader workflowState={workflowState} setCanPlayAudio={setCanPlayAudio} />
                    <WSRampup/>
                    {RampupUISelection[step as TRampupStep]!}
                    <BMRampup/>
              </>

    :
        workflowState === 'steady'?
           
                <>
                    <RunHeader workflowState={workflowState} setCanPlayAudio={setCanPlayAudio} />
                    <WSSteady/>
                    <SteadyUISelection step={step as TSteadyStep}/>
                    <BMSteady/>
                </>
    :
               
    workflowState === 'finished'?
                <UIFinish />
                : null
    }
    </>)

}


const actions0_55: IActionListItem[]  = [
    {field: 'IsVentilationSettingsInAssessment', secondLine:<UIAssessmentVentParameters/>},
    {field: 'IsXrayPerformed'},
    {field: 'IsBronchoscopyPeformed'}
]

const actions2_55: IActionListItem[] = [
    {field:'IsVentilationSettingsInAssessment', secondLine:<UIAssessmentVentParameters/>},
    {field: 'IsXrayPerformed'},
    {field: 'IsBronchoscopyPeformed'},
    {field: 'IsDeflationTestPerformed'},
    {field: 'IsPhotoTaken'}
]

const actions3_55: IActionListItem[]  = [
    {field:'IsVentilationSettingsInAssessment', secondLine:<UIAssessmentVentParameters/>},
    {field: 'IsXrayPerformed'},
]

const actions_MostOfAssessments: IActionListItem[] = [
    {field:'IsVentilationSettingsInAssessment', secondLine:<UIAssessmentVentParameters/>},
]
React.memo(SteadyUISelection)
function SteadyUISelection({step}: {step:TSteadyStep}) {
    let ui = null
    if (isAssessmentStep(step)) {
        if (step === '0:55') {
            ui = <AssessmentHost actions={actions0_55} key={Math.random()}/>
        } else if (step === '2:55') {
            ui = <AssessmentHost actions={actions2_55} key={Math.random()}/>
        } else if (step === '3:55') {
            ui = <AssessmentHost actions={actions3_55} key={Math.random()}/>
        } else {
            ui = <AssessmentHost actions={actions_MostOfAssessments} key={Math.random()}/>
        }
    } else { // reqruitment step
        ui = <UIRecruitmentStepXt30 key={Math.random()}/>
    }
    return (<UISteadyHost ui={ui}/>)
}


type TRampupSelection = {
    [key in TRampupStep]: ReactNode
}

const RampupUISelection: TRampupSelection = {
    '0:00': <UIRampupStep0t00/>,
    '0:10': <UIRampupStep0t10/>,
    '0:20': <UIRampupStep0t20/>,
    '32°C': <UIRampupStep0t32C/>,
    '0:30': <UIRampupStep0t30/>,
    '0:40': <UIRampupStep0t4050 key={'0:40'}/>,
    '0:50': <UIRampupStep0t4050 key={'0:50'}/>
}



