import BlockIcon from '@mui/icons-material/Block';
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogTitle, FormControlLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useState } from 'react';
import { IProcHeader } from '../../data/Procedure';
import { TStep } from '../../data/WorkflowSteps';
import { useAppDispatch } from '../tech/TechReduxHooks';
import { useAppSelector } from './monitorReduxHooks';
import { IMediaContentState, getMediaContentAsync } from './silceMediaContent';
import { selectProcHeader } from './sliceMonitorProcHeader';
import { ThunkDispatch, AnyAction, Dispatch } from '@reduxjs/toolkit';



export default function ImageUploadDialog({ file, stepHour, onFinish }:
    { file: File | null, stepHour: TStep, onFinish: () => void }) {
    const [isUploading, setIsUploading] = useState(false)
    const procHeader = useAppSelector(selectProcHeader)
    const dispatch = useAppDispatch()
    const [description, setDescription] = useState('')
    const [iscomparison, setIscomparison] = useState(false)

    const onComparisonChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setIscomparison(e.target.checked)
    }

    let comparisonControl = null   

    let previewFunction = otherPreview
    let isOkDisabled = true
    if (file !== null) {
        if (file.type.startsWith('image')) {
            previewFunction = imagePreview
            comparisonControl =   
                <FormControlLabel sx={{ml:'5px', mr:'15px', mt:'0px', mb:'15px'}} control={<Checkbox  onChange={onComparisonChanged}/>} label="Compare" value={iscomparison} />
            isOkDisabled = false
        } else if (file.type.startsWith('video')) {
            previewFunction = videoPreview
            comparisonControl = null
            isOkDisabled = false
        }
    }
    const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
    }
    

    const onOk = async () => {
        if (file !== null) {
            setIsUploading(true)
            await uploadFile(file, description, iscomparison, stepHour, procHeader)
            setIsUploading(false)
            onFinish()
            dispatch(getMediaContentAsync({partitionKey:procHeader!.partitionKey, rowKey: procHeader!.rowKey}))
        }
    }



    return (
        <Dialog open={file !== null} maxWidth='lg'>
            <DialogTitle>
                { isUploading
                  ? <>Please wait while file is uploading</>
                  : <>Upload the file?</>
                } 
            </DialogTitle>
            <Box sx={{ height: '400px', width: '800px', p: 1, position: 'relative', justifyContent: 'center', alignItems: 'center', display: 'flex' }} border='1px'>
                {previewFunction(file)}
                {isUploading
                    ? <CircularProgress size='36px' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                    : null}
            </Box>
            <TextField sx={{ml:'15px', mr:'15px', mt:'5px', mb:'15px'}} variant='standard' value={description} onChange={onDescriptionChange} label='Description'/>
            {comparisonControl}
            <DialogActions>
                <Button disabled={isUploading} onClick={() => onFinish()}>Cancel</Button>
                <Button disabled={isOkDisabled || isUploading} onClick={() => onOk()}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

function imagePreview(file: File | null) {
    if (file === null) {
        return <></> // that's impossible
    }
    return (
        <img style={{ objectFit: 'scale-down', height: '400px', width: '800px' }}
            src={URL.createObjectURL(file)} loading='lazy'
            alt='' />
    )
}

function videoPreview(file: File | null) {
    if (file === null) {
        return <></> // that's impossible
    }
    return (
        <video height='400px' controls>
            <source src={URL.createObjectURL(file)} type={file.type}  />
            No support
        </video>
    )
}

function otherPreview(file: File | null) {
    return (
        <BlockIcon fontSize='large' sx={{color:'red'}} />
    )
}



async function uploadFile(file: File, description:string, iscomparison: boolean | undefined,  stepHour: TStep, procHeader: IProcHeader | null) {
    if (procHeader === null) {
        return
    }
    const formData = new FormData();
    formData.append(
        description,
        file,
        file.name
    );
    if (iscomparison !== undefined) {
        formData.append('iscomparison', iscomparison.toString())
    }
    /*const response = */ await fetch( // TODO: deal with the errors here
        `/api/addMediaFile?device=${procHeader?.performedOn}&proc=${procHeader?.rowKey}&time=${stepHour}`
        , {
            method: 'POST',
            body: formData
        })
}

export async function deleteFile(file: string, proc: {partitionKey: string, rowKey:string} | null, dispatch: ThunkDispatch<{ mediaContentSlice: IMediaContentState; }, undefined, AnyAction> & Dispatch<AnyAction>) {
    const url = file.split('?')[1]
    await fetch( 
        `/api/removeMediaFile?${url}`
        , {
            method: 'POST',
        })
    dispatch(getMediaContentAsync({partitionKey:proc!.partitionKey, rowKey: proc!.rowKey}))
}