import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TEntryField } from '../../data/ProcData'
import { TSteadyStep } from '../../data/WorkflowSteps'
import aGetFormCompletion from './aGetFormCompletion'
import DF, { ViewNumberField as VF } from './DictionaryNumberField'
import EntryItem from './RampupEntryItem'
import { useAppSelector } from './RunReduxHooks'
import { selectData } from './sliceTimeline'

export const fieldList:TEntryField[] = [
    'LAPressure', 'PAPressure', 
    'PerfusateFlow', 'PumpSpeed',        
    'PeakAWP', 'MeanAWP', 'PlateauAWP',
    'DynamicCompliance', 'StaticCompliance']

export default function ATab2SystemData({completion} : {completion: (allChecked: boolean) => void}) {
    const { step } = useParams()
    const stepData = useAppSelector(selectData)

    useEffect(() => {
        completion(aGetFormCompletion(stepData, step as TSteadyStep, fieldList))
    }, [stepData, step, completion])

    const GRIDSPACING = 2
    return (
        <>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={6}>
                        <DF step={step} field='PAPressure' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <DF step={step} field='LAPressure' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={4}>
                        <DF step={step} field='PerfusateFlow' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='PumpSpeed' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <VF step={step} field='PVResistance' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={4}>
                        <DF step={step} field='PeakAWP' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='MeanAWP' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='PlateauAWP' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={6}>
                        <DF step={step} field='DynamicCompliance' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <DF step={step} field='StaticCompliance' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>

        </>
    )

}