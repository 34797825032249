import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import DF from './DictionaryNumberField';
import EntryItem from './RampupEntryItem';
import UIRampupVentParameters from './UIRampupVentParameters';
import AutoScrollContainer from '../../utils/AutoScrollContainer';
import UIRampupBGAOnPASampleSelector from './UIRampupBGAOnPASampleSelector';

export default function UIRampupStep0t32C() {
    const { step } = useParams()

    return (
        <AutoScrollContainer bottomOffset={70}>
            <EntryItem>
                <Typography color='warning.main' variant='h5'>Set ventilation parameters</Typography>
                <UIRampupVentParameters />
            </EntryItem>
            <EntryItem>
                <Typography variant='h5'>Disengage airway clamp</Typography>
            </EntryItem>
            <EntryItem>
                <Typography variant='h5'>Press "Start Ventilation" on the Ventilator</Typography>
            </EntryItem>
            <EntryItem>
                <Typography variant='h5'>Start the EVLP gas flow</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <DF fullWidth field={'EVLPGasFlow'} step={step} />
                    </Grid>
                </Grid>
            </EntryItem>
            <UIRampupBGAOnPASampleSelector />
        </AutoScrollContainer>
    )
}


