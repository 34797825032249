import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchServicesSettingAsync } from "../../common/FetchApi"

export const getServiceSettingsAsync = createAsyncThunk(
  'serviceSettings/fetchServiceSettings',
  async () => {
      const response = await fetchServicesSettingAsync()
      return response
  }
)
