import { Stack, Typography, Divider, IconButton, Button } from '@mui/material'
import getDateFromMilliseconds from './getDateFromMilliseconds'
import { IOrg } from '../data/Org'
import { IProcHeader } from '../data/Procedure'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import LogsDialog from './LogsDialog'
import { useState } from 'react'

const linkWidht = '36px'
const evlpCaseWidth = '150px'
const deviceWidth = '150px'
const notifyWidth = '350px'
const startedAtWidth = '300px'
const logsWidth = '200px'
const durationWidth = '180px'
const statusWidth = '100px'
const stepWidth = '120px'

export function Header() {
    return (
        <>
            <Stack direction='row'>
                <Typography variant='h6' width={linkWidht}> &nbsp; </Typography>
                <Typography variant='h6' width={evlpCaseWidth}>EVLP ID</Typography>
                <Typography variant='h6' width={deviceWidth}>Perform on</Typography>
                <Typography variant='h6' width={notifyWidth}>Notify</Typography>
                <Typography variant='h6' width={startedAtWidth}>Started at (UTC)</Typography>
                <Typography variant='h6' width={durationWidth}>Duration</Typography>
                <Typography variant='h6' width={statusWidth}>Status</Typography>
                <Typography variant='h6' width={stepWidth}>Step</Typography>
                <Typography variant='h6' width={stepWidth}>Data Logs</Typography>

            </Stack>
            <Divider />
        </>
    )
}

export function Body({ rows, hospitals }: { rows: IProcHeader[], hospitals: IOrg[] | undefined }) {
    const [openLogs, setOpenLogs] = useState<string | undefined>(undefined)
    return (
        <>
            {rows.map(d => 
                <Stack direction='row' key={d.rowKey}>
                    <Typography width={linkWidht}> <IconButton sx={{mt:'-5px'}} 
                        onClick={() => window.open(`/api/getReport?partitionKey=${d.partitionKey}&rowKey=${d.rowKey}`, '_blank')}>
                            <SummarizeOutlinedIcon fontSize='small'/>
                        </IconButton> </Typography>
                    <Typography width={evlpCaseWidth}>{d.evlpCaseNumber}</Typography>
                    <Typography width={deviceWidth}>{d.performedOn}</Typography>
                    <Typography width={notifyWidth}>{hospitals?.find(x => x.rowKey === d.notify)?.fullName}</Typography>
                    <Typography width={startedAtWidth}>{getDateFromMilliseconds(d?.startTime, 'UTC')}</Typography>
                    <Typography width={durationWidth}>{
                        getDateFromMilliseconds(
                            d === null || d.currentTime === undefined || d.startTime === undefined
                                ? undefined
                                : d.currentTime - d.startTime, 'duration')}</Typography>
                    <Typography width={statusWidth}>{d?.disposition}</Typography>
                    <Typography width={stepWidth}>{d?.step}</Typography>
                    <Typography width={logsWidth}> <Button onClick={()=>setOpenLogs(d.rowKey)} >Open Logs</Button> </Typography>
                    
                            {openLogs === d.rowKey && <LogsDialog openLogsDialog={true} handleOpenLogsDialog={setOpenLogs} partitionKey={d.partitionKey} rowKey={d.rowKey} />}

                </Stack>
            )}
        </>
    )
}
