import { saveProcedureFromOfflineAsync } from "../routes/run/saveThunk"
import { finishProcedureAsync } from "../routes/run/sliceRunProcHeader"

const fields = ['savedProcedure', 'finishProcedure']

export const uploadOfflineData = () => {
    for (const field of fields){
       const data = localStorage.getItem(field)

        if (!data){
            console.log(`Error retrieving data to upload from ${field}`)
            return
        }

        const payload = JSON.parse(data)
        switch (field){
            case 'savedProcedure':
                saveProcedureFromOfflineAsync(payload)
                break

            case 'finishProcedure':
                finishProcedureAsync(payload)
        }
        localStorage.removeItem(field)
    }
}

export const isDataOffline = () => {
    for (let field of fields){
        const data = localStorage.getItem(field)
        if (data) {
            return true
        }
    }
    return false
}
