import { IPerson } from "../../../data/Person"
import type { AdminRootState } from '../AdminStore'
import { createPersonSliceHelper, IPersonsState } from './PersonSliceHelpers'
import { getTechsAsync } from './PersonThunks'

const initialState: IPersonsState = {
  all: new Array<IPerson>(),
  status: 'idle'
}

export const personsSlice = createPersonSliceHelper('tech', initialState, getTechsAsync)

export const { add, update } = personsSlice.actions

export const selectAll = (state: AdminRootState) => state.techs.all
export const selectStatus = (state:  AdminRootState) => state.techs.status

export default personsSlice.reducer

