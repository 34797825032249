import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IProcHeader } from '../../data/Procedure'
import { DocRootState } from './docStore'


async function fetchProceduresAsync(): Promise<Array<IProcHeader>> {
    const requestUrl = `/api/getProcedures?role=doctor`
    const response = await fetch(requestUrl)
    if (response.ok) {
        const json = await response.json()
        return json
    } else {
        console.log(`Error fetching procedures. Status: ${response.status}`)
        return new Array<IProcHeader>()
    }
}

export const getProceduresAsync = createAsyncThunk(
    'procedures/fetchProcedures',
    async () => {
        const response = await fetchProceduresAsync()
        return response
    }
)


export interface IProceduresState {
    all: IProcHeader[]
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IProceduresState = {
    all: [],
    status: 'idle'
}


export const proceduresSlice = createSlice({
    name: 'procedures',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProceduresAsync.pending, (state) => {
                state.all.splice(0)
                state.status = 'loading'
            })
            .addCase(getProceduresAsync.fulfilled, (state, action) => {
                state.status = 'idle'
                state.all = action.payload
            })
    },
})


export const selectAll = (state: DocRootState) => state.proceduresSlice.all
export const selectStatus = (state: DocRootState) => state.proceduresSlice.status
export default proceduresSlice.reducer
