import AvTimerIcon from '@mui/icons-material/AvTimer';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { Grid, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TEntryField } from '../../data/ProcData';
import { TSteadyStep, TTimelineStep } from '../../data/WorkflowSteps';
import WaitingContainer from '../../utils/WaitingContainer';
import ATab2SystemData, { fieldList as aTab2Fields } from './ATab2SystemData';
import ATab3BGA, { fieldList as aTab3Fields } from './ATab3BGA';
import ATab4VeinBGA from './ATab4VeinBGA';
import ATab6Perfusate, { fieldList as aTab6Fields } from './ATab6Perfusate';
import ActionsList, { IActionListItem, IActionListProps } from './ActionsList';
import DF, { ViewNumberField as VF } from './DictionaryNumberField';
import EntryItem from './RampupEntryItem';
import { useAppDispatch, useAppSelector } from './RunReduxHooks';
import UIRampupVentParameters from './UIRampupVentParameters';
import aGetFormCompletion from './aGetFormCompletion';
import { getWarpedMilliseconds } from './allTime';
import { selectCurrentTime } from './sliceRunProcHeader';
import { selectData, setflag } from './sliceTimeline';
import { TabIcon, TabPanel } from './utilsTabView';
import React from 'react';

const perfusateFieldsPre: TEntryField[] = ['PerfusateLevelPre']

//React.memo(AssessmentHost)
export default function AssessmentHost({ actions }: IActionListProps) {
    const [tabValue, setTabValue] = useState(0)
    const { step } = useParams()
    const stepData = useAppSelector(selectData)
    const dispatch = useAppDispatch()

    const [allPreAssessment, setAllPreAssessment] = useState(
        stepData[step as TTimelineStep].IsVentilationSettingsInAssessment as boolean
        && aGetFormCompletion(stepData, step as TTimelineStep, perfusateFieldsPre))
    const [allATab2SystemData, setAllATab2SystemData] = useState(aGetFormCompletion(stepData, step as TTimelineStep, aTab2Fields))
    const [allATab3BGA, setAllATab3BGA] = useState(aGetFormCompletion(stepData, step as TTimelineStep, aTab3Fields))
    const [allPostAssessment, setAllPostAssessment] = useState(stepData[step as TTimelineStep].IsNormalVentParamsRestored as boolean)
    const [allATab6Perfusate, setAllATab6Perfusate] = useState(aGetFormCompletion(stepData, step as TTimelineStep, aTab3Fields))

    useEffect(() => {
        const entryFinished =
            stepData[step as TTimelineStep].IsVentilationSettingsInAssessment as boolean
            && aGetFormCompletion(stepData, step as TTimelineStep, aTab2Fields)
            && aGetFormCompletion(stepData, step as TTimelineStep, aTab3Fields)
            && stepData[step as TTimelineStep].IsNormalVentParamsRestored as boolean
            && aGetFormCompletion(stepData, step as TTimelineStep, aTab6Fields)

        dispatch(setflag({
            fieldName: 'IsMandatoryDataEntered',
            step: step as TTimelineStep,
            value: entryFinished
        }))

    }, [stepData, step, dispatch])

    const [fiveMinExpired, setFiveMinExpired] = useState(false)
    const [twoMinExpired, setTwoMinExpired] = useState(false)
    const [startTime, setStartTime] = useState<number | null>(null)
    const currentTime = useAppSelector(selectCurrentTime)

    useEffect(() => {
        if (allPreAssessment && startTime === null) {
            setStartTime(currentTime)
        }
    }, [allPreAssessment, currentTime, startTime])

    useEffect(() => {
        if (startTime !== null) {
            if (currentTime - startTime >= getWarpedMilliseconds(2)) {
                setTwoMinExpired(true)
            }
            if (currentTime - startTime >= getWarpedMilliseconds(5)) {
                setFiveMinExpired(true)
            }
        }
    }, [currentTime, startTime])

    const restoreActions: IActionListItem[] = [
        { field: 'IsNormalVentParamsRestored', secondLine: <UIRampupVentParameters /> },
    ]

    const handleTabValueChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }

    const preAssessmentCompletion = (isComplete: boolean) => {
        if (!allPreAssessment) {
            setAllPreAssessment(isComplete && aGetFormCompletion(stepData, step as TTimelineStep, perfusateFieldsPre))
        }
    }

    const postAssessmentCompletion = (isComplete: boolean) => {
        setAllPostAssessment(isComplete)
    }

    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'center' }}>Start EVLP assessment</Typography>
            <Tabs value={tabValue} onChange={handleTabValueChange} style={{ height: '60px' }}>
                <Tab label={<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Pre-Assessment</Typography>}
                    icon={TabIcon(allPreAssessment)} iconPosition='end' />
                <Tab label={<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>System Data</Typography>}
                    icon={TabIconWithStopAndTimer(allATab2SystemData, allPreAssessment, twoMinExpired)} iconPosition='end' />
                <Tab label={<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>BGA</Typography>}
                    icon={TabIconWithStopAndTimer(allATab3BGA, allPreAssessment, fiveMinExpired)} iconPosition='end' />
                <Tab label={<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Vein BGA</Typography>}
                    icon={TabIconWithStopAndTimerNoCompletion(allPreAssessment, fiveMinExpired)} iconPosition='end' />
                <Tab label={<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Post-Assessment</Typography>}
                    icon={TabIconWithStop(allPostAssessment, allATab2SystemData && allATab3BGA)} iconPosition='end' />
                <Tab label={<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Perfusate</Typography>}
                    icon={TabIconWithStop(allATab6Perfusate, allPostAssessment)} iconPosition='end' />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
                <PreAssessmentPerfusate step={step as TSteadyStep} />
                <ActionsList actions={actions}
                    completionCallback={preAssessmentCompletion}
                    mandatoryFields={['IsVentilationSettingsInAssessment']} >
                    {startTime !== null && !twoMinExpired
                        ? <>
                            <Typography marginTop='20px' variant='h5'
                                color='warning.main'>Prepare to enter System Data in two minutes</Typography>
                            <LinearProgress sx={{ width: '50%' }} variant="determinate"
                                value={100 * (currentTime - startTime) / getWarpedMilliseconds(2)} /><br />
                        </>
                        : null}
                    {startTime !== null && !fiveMinExpired
                        ? <>
                            <Typography marginTop='20px' variant='h5'
                                color='warning.main'>Prepare to enter BGA variables in five minutes</Typography>
                            <LinearProgress sx={{ width: '50%' }} variant="determinate"
                                value={100 * (currentTime - startTime) / getWarpedMilliseconds(5)} />
                        </>
                        : null}
                </ActionsList>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <WaitingContainer show={!twoMinExpired}>
                    <ATab2SystemData completion={(isComplete) => setAllATab2SystemData(isComplete)} />
                </WaitingContainer>
                {startTime !== null && !fiveMinExpired
                            ? <>
                                <Typography marginTop='20px' variant='h5'
                                    color='warning.main'>Prepare to enter BGA variables in five minutes</Typography>
                                <LinearProgress sx={{ width: '50%' }} variant="determinate"
                                    value={100 * (currentTime - startTime) / getWarpedMilliseconds(5)} />
                            </>
                            : null}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <WaitingContainer show={!fiveMinExpired}>
                    <ATab3BGA completion={(isComplete) => setAllATab3BGA(isComplete)} />
                </WaitingContainer>
                {/*  */}
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <WaitingContainer show={!fiveMinExpired} >
                    <ATab4VeinBGA />
                </WaitingContainer>
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
                <WaitingContainer show={!allATab3BGA && allATab2SystemData}>
                    <ActionsList actions={restoreActions}
                        completionCallback={postAssessmentCompletion}
                        mandatoryFields={['IsNormalVentParamsRestored']} />
                </WaitingContainer> 
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
                <WaitingContainer show={!allPostAssessment}>
                    <ATab6Perfusate completion={(isComplete) => setAllATab6Perfusate(isComplete)} />
                </WaitingContainer>
            </TabPanel>
        </>
    )
}


function PreAssessmentPerfusate({ step }: { step: TSteadyStep }) {
    return (
        <EntryItem>
            <Typography variant='h6'>Register perfusate data</Typography>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <DF step={step} field='PerfusateLevelPre' fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <DF step={step} field='PerfusateAddedPre' fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <VF step={step} field='PerfusateLoss' fullWidth />
                </Grid>
            </Grid>
        </EntryItem>
    )
}

function TabIconWithStopAndTimer(isComplete: boolean, isStartset: boolean, isTimerExpired: boolean) {
    const combination = (isStartset ? 10 : 0) + (isTimerExpired ? 1 : 0)
    switch (combination) {
        case 0: return <DoNotDisturbAltIcon color='warning' /> // not started and not expired
        case 1: return <AvTimerIcon color='warning' />   // not set, but expired - impossible combination, but whatever
        case 10: return <AvTimerIcon color='warning' />  // start set, but timer is ticking
        case 11: return TabIcon(isComplete)
    }
}

function TabIconWithStopAndTimerNoCompletion(isStartset: boolean, isTimerExpired: boolean) {
    const combination = (isStartset ? 10 : 0) + (isTimerExpired ? 1 : 0)
    switch (combination) {
        case 0: return <DoNotDisturbAltIcon color='warning' /> // not started and not expired
        case 1: return <AvTimerIcon color='warning' />   // not set, but expired - impossible combination, but whatever
        case 10: return <AvTimerIcon color='warning' />  // start set, but timer is ticking
        case 11: return undefined
    }
}



function TabIconWithStop(isComplete: boolean, isStartset: boolean) {
    const combination = (isStartset ? 1 : 0)
    switch (combination) {
        case 0: return <DoNotDisturbAltIcon color='warning' /> // can't start 
        case 1: return TabIcon(isComplete)   // can enter data
    }
}


