import { Divider, FormControl, Grid, MenuItem, Stack, TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { IOrg } from '../../../data/Org'
import { IPerson } from '../../../data/Person'

export interface IAddFormProps {
    data: IPerson
    onDataItemChanged: (id: string, value: string) => void
    orgs: Array<IOrg>
}

export default function AddForm(props: IAddFormProps) {
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let targetFieldId = e.target.id ?? e.target.name // MUI bug when target id isn't passed through
        props.onDataItemChanged(targetFieldId, e.target.value)
    }


    return (
        <FormControl fullWidth>
            <Divider textAlign="left" sx={{mb:'10px'}}>Organization</Divider>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField select fullWidth  id='partitionKey' name='partitionKey'
                        value={props.data['partitionKey']}  
                        onChange={onChange} 
                        label='Select organization'>
                            {props.orgs.map(x => 
                                <MenuItem key={x.rowKey} value={x.rowKey}>
                                    <Stack direction='row'>
                                        {x.label + ','}
                                        <div style={{fontWeight:'bold'}} >&nbsp;&nbsp;Full Name:&nbsp;</div>{x.fullName} 
                                    </Stack>

                                </MenuItem>)}
                    </TextField> 
                </Grid>
            </Grid>
            <Divider textAlign="left" sx={{mb:'10px', mt:'32px'}}>Contact</Divider>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <TextField fullWidth label='Honorific' 
                        value={props.data['honorific']} id='honorific' onChange={onChange} 
                        required/>
                </Grid>
                <Grid item xs={5}>
                    <TextField fullWidth label='First Name' 
                        value={props.data['firstName']} id='firstName' onChange={onChange} 
                        required/> 
                </Grid>
                <Grid item xs={5}>
                    <TextField fullWidth label='Last Name' 
                        value={props.data['lastName']} id='lastName' onChange={onChange} 
                        required/>
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label='eMail' 
                        value={props.data['eMail']} id='eMail' onChange={onChange} 
                        required/> 
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label='Phone Number' 
                        value={props.data['phoneNumber']} id='phoneNumber' onChange={onChange} 
                        required/>
                </Grid>
            </Grid>
        </FormControl>        
    )
}