import CheckIcon from '@mui/icons-material/Check';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { consNames, IOrg } from '../../../data/Org';
import MessageBox, { TMBReturnValue } from '../../../utils/MessageBox';
import { useAppDispatch, useAppSelector } from '../AdminReduxHooks';
import { updateOrganizationAsync } from '../common/FetchApi';
import { selectAll as selectAllHospitals, update } from '../org/HospitalsSlice';
import { selectAll as selectAllDoctors } from '../person/DoctorsSlice';
import ClearIcon from '@mui/icons-material/Clear';

const deleteWidth = '36px'
const orgLabelWidth = '150px'
const firstNameWidth = '200px'
const lastNameWidth = '200px'
const emailWidth = '300px'
const phoneWidth = '150px'
const isActiveWidth = '16px'


export function Header() {
    return (
        <Fragment>
        <Stack direction='row'>
            <Typography variant='h6' width={deleteWidth}> </Typography>
            <Typography variant='h6' width={orgLabelWidth}>Org. Label</Typography>
            <Typography variant='h6' width={firstNameWidth}>First Name</Typography>
            <Typography variant='h6' width={lastNameWidth}>Last Name</Typography>
            <Typography variant='h6' width={emailWidth}>eMail</Typography>
            <Typography variant='h6' width={phoneWidth}>Phone #</Typography>
            <Typography variant='h6' width={isActiveWidth}>Active?</Typography>
        </Stack>
        <Divider/>
        </Fragment>
    )
}

export function Body(props: {target: string}) {
    const allHospitals = useAppSelector(selectAllHospitals)
    const allDoctors = useAppSelector(selectAllDoctors)
    const theHospital = allHospitals.find(x => x.rowKey === props.target)
    const doctorByConsId = (consFieldName:string) => {
        if (theHospital === undefined) {
            return null
        } 
        if (theHospital[consFieldName as keyof IOrg] === undefined) {
            return null
        }
        const d = allDoctors.find(x => x.rowKey === theHospital[consFieldName as keyof IOrg]) 
        if (d === undefined) {
            return null
        }
        return (
            <Stack direction='row' key={d.rowKey}>
                <Typography width={deleteWidth}><DeleteButton width={deleteWidth} doctorId={d.rowKey} hospitalId={theHospital.rowKey}/> </Typography>
                <Typography width={orgLabelWidth}>{d.orgLabel}</Typography>
                <Typography width={firstNameWidth}>{d.firstName}</Typography>
                <Typography width={lastNameWidth}>{d.lastName}</Typography>
                <Typography width={emailWidth}>{d.eMail}</Typography>
                <Typography width={phoneWidth}>{d.phoneNumber}</Typography>
                {d.isActive ? <CheckIcon width={isActiveWidth} fontSize='small'/> : ''}
            </Stack>
        )
    }
    return (
        <>
         { consNames.map((x) => doctorByConsId(x)) } 
        </>
    )
}

function removeConsultant(hospital:IOrg, consultantId: string) {
    const retval: IOrg = {...hospital}

    for(const x of consNames) {
        if (retval[x as keyof IOrg] === consultantId) {
            (retval as any)[x]  = undefined
            break
        }
    }
    return retval 
}



function DeleteButton({hospitalId, doctorId, width}: {hospitalId: string, doctorId: string, width: string}) {
    const [showConfirmation, setShowConfirmation] = useState(false)
    const theHospital = useAppSelector(selectAllHospitals).find(x => x.rowKey === hospitalId)
    const dispatch = useAppDispatch()

    const onConfirmationClosed = (ret: TMBReturnValue) => {
        if (ret === 'IDYES') {
            const updatedHospital = removeConsultant(theHospital!, doctorId)
            dispatch(update(updatedHospital))
            updateOrganizationAsync(updatedHospital)
        }
        setShowConfirmation(false)
    }
    return(
        <>
            <IconButton sx={{mt:'-5px'}} onClick={() =>setShowConfirmation(true)} >
                <ClearIcon width={width} fontSize='small'/>
            </IconButton>
            <MessageBox open={showConfirmation}
                    buttonsType='MB_YESNO'
                    message='Are you sure you want to remove this consultant?'
                    callback={(ret) => onConfirmationClosed(ret)}
                    severity='warning' />
        </>

    )
}