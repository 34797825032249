import { Alert, AlertColor, AlertTitle, Button, Dialog, DialogActions } from '@mui/material'

export type TMBButtonsType='MB_OK' | 'MB_OKCANCEL' | 'MB_YESNO' 
export type TMBReturnValue = 'IDCANCEL' | 'IDOK' | 'IDYES' | 'IDNO'
export type TMBCallback = (retval: TMBReturnValue) => void

export interface IMessageBoxParams {
    open: boolean
    message: string,
    buttonsType: TMBButtonsType,
    callback: TMBCallback
    title?: string
    severity?: AlertColor
}

export default function MessageBox(props: IMessageBoxParams) {
    return (
        <Dialog open={props.open}>
            <Alert severity={props.severity}>
                <AlertTitle>{props.title}</AlertTitle>
                {props.message}
            </Alert>
            <DialogActions>
                <Buttons buttonsType={props.buttonsType} callback = {props.callback} />
            </DialogActions>
        </Dialog>
    )
}

function Buttons({buttonsType, callback}: {buttonsType: TMBButtonsType, callback: TMBCallback}) {
    switch (buttonsType) {
        case 'MB_OK': return( <Button onClick={() => callback('IDOK')}>OK</Button>)
        case 'MB_OKCANCEL': return (<> <Button onClick={() => callback('IDOK')}>OK</Button> 
                                       <Button onClick={() => callback('IDCANCEL')}>Cancel</Button> </>)
        case 'MB_YESNO': return (<> <Button onClick={() => callback('IDYES')}>Yes</Button> 
                                    <Button onClick={() => callback('IDNO')}>NO</Button> </>)
    }
}
