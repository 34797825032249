import { IOrg } from '../../../data/Org'
import type { AdminRootState } from '../AdminStore'
import { createOrgSliceHelper, IOrganizationsState } from './OrgSliceHelpers'
import { getLabsAsync } from './OrgThunks'

const initialState: IOrganizationsState = {
  all: new Array<IOrg>(),
  status: 'idle'
}

export const orgsSlice = createOrgSliceHelper('lab', initialState, getLabsAsync)

export const { add, update } = orgsSlice.actions

export const selectAll = (state: AdminRootState) => state.labs.all
export const selectStatus = (state:  AdminRootState) => state.labs.status
export default orgsSlice.reducer

