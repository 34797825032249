import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import { EntityTypeIndex, EntityTypes } from './data/Common';
import './index.css';
import Admin from './routes/admin/Admin';
import Consultants from './routes/admin/consultant/Consultants';
import Devices from './routes/admin/device/Devices';
import Hospitals from './routes/admin/org/Hospitals';
import Labs from './routes/admin/org/Labs';
import Doctors from './routes/admin/person/Doctors';
import Techs from './routes/admin/person/Techs';
import Procedures from './routes/admin/procedure/Procedures';
import DocArchive from './routes/doctor/DocArchive';
import DocCurrent from './routes/doctor/DocCurrent';
import Doctor from './routes/doctor/Doctor';
import {store as docStore} from './routes/doctor/docStore'
import Monitor from './routes/monitor/Monitor';
import { store as monitorStore } from './routes/monitor/monitorStore';
import TabGraphical from './routes/monitor/TabGraphical';
import TabMedia from './routes/monitor/TabMedia';
import TabMediaHour from './routes/monitor/TabMediaHour';
import TabProcedure from './routes/monitor/TabProcedure';
import TabReport from './routes/monitor/TabReport';
import TabTabulated from './routes/monitor/TabTabulated';
import Run from './routes/run/Run';
import RunScreen from './routes/run/RunScreen';
import { persistor as runPersistor, store as runStore } from './routes/run/RunStore';
import UIFinish from './routes/run/UIFinishScreen';
import TechAbout from './routes/tech/about/About';
import TechArchive from './routes/tech/archive/Archive';
import TechCurrent from './routes/tech/current/Current';
import Tech from './routes/tech/Tech';
import ImageCompare from './routes/imagecompare/ImageCompare';
import ArchivedReport from './common/ArchivedReport';
import ArchivedReportTech from './routes/tech/archive/ArchivedReport';
import { PersistGate } from 'redux-persist/integration/react';
import InsighTxTab from './common/PredictionTab';
import UserLogs from './routes/admin/userLogs/UserLogs';

ReactDOM.render(
    <React.StrictMode>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="admin" element={<Admin />} >
                <Route path={EntityTypes[EntityTypeIndex.lab]} element = {<Labs/>} />
                <Route path={EntityTypes[EntityTypeIndex.tech]} element = {<Techs/>} />
                <Route path={EntityTypes[EntityTypeIndex.device]} element = {<Devices/>}/>
                <Route path={EntityTypes[EntityTypeIndex.procedure]} element = {<Procedures/>} />
                <Route path={EntityTypes[EntityTypeIndex.hospital]} element = {<Hospitals/>} />
                <Route path={EntityTypes[EntityTypeIndex.doctor]} element = {<Doctors/>} />
                <Route path={EntityTypes[EntityTypeIndex.userLogs]} element = {<UserLogs/>}/>

                <Route path='consultant' element = {<Consultants/>} /> 
            </Route>
            <Route path="tech" element= {<Tech/>}>
                <Route path='current' element = {<TechCurrent/>}/>
                <Route path='archive' element = {<TechArchive/>}/>
                <Route path='about' element = {<TechAbout />}/>
                <Route path='report' element = { <ArchivedReportTech /> } />
            </Route>
            <Route path="doctor" element={<Provider store={docStore}><Doctor /></Provider>}>
                <Route path='current' element = {<DocCurrent/>}/>
                <Route path='archive' element = {<DocArchive/> }/>
                <Route path='report' element = { <ArchivedReport/> } />
            </Route>
            <Route path="run/:partitionKey/:rowKey" element={
                <Provider store={runStore}>
                    <PersistGate loading={null} persistor={runPersistor}>
                        <Run/>
                    </PersistGate>
                </Provider>} >                    
                        <Route path = 's/:step' element={<RunScreen/>} />
                        <Route path = 'finish' element={<UIFinish/>} />
            </Route>
            <Route path="monitor/:partitionKey/:rowKey" element={<Provider store={monitorStore}> <Monitor/> </Provider>}>
                <Route path = 'procedure' element={<TabProcedure/>}/>
                <Route path = 'tabulated' element={<TabTabulated/>}/>
                <Route path = 'graphical' element={<TabGraphical/>}/>
                <Route path = 'media' element={<TabMedia/>}>
                    <Route path=':hour' element = {<TabMediaHour/>}/>
                </Route>
                <Route path = 'report' element = {<TabReport/>} />
                <Route path = 'InsighTx' element = {<InsighTxTab/>} />
            </Route>
            <Route path="imagecompare/:first/:second" element={<ImageCompare/>}>
            </Route>

        </Routes>    
    </BrowserRouter>
    </React.StrictMode>, 
    document.getElementById('root'));
