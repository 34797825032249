import { Stack, Typography, Divider, IconButton, Box, Button } from '@mui/material'
import getDateFromMilliseconds from './getDateFromMilliseconds'
import { IOrg } from '../data/Org'
import { IProcHeader } from '../data/Procedure'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useState } from 'react'
import LogsDialog from './LogsDialog'

const linkWidth = '36px'
const evlpCaseWidth = '150px'
const lungsWidth = '100px'
const deviceWidth = '150px'
const notifyWidth = '350px'
const startedAtWidth = '300px'
const durationWidth = '180px'
const logsWidth = '300px'

type ContextType = { reportUrl: string, updateUrl: (url: string) => void, updateTab: (num: number) => void };


export function Header({canDeleteParam}: {canDeleteParam?: boolean }) {
    const canDelete = canDeleteParam ?? false
    return (
        <>
            <Stack direction='row'>
                <Typography variant='h6' width={linkWidth}> &nbsp; </Typography>
                <Typography variant='h6' width={evlpCaseWidth}>EVLP ID</Typography>
                <Typography variant='h6' width={lungsWidth}>Lungs</Typography>
                <Typography variant='h6' width={deviceWidth}>Perform on</Typography>
                <Typography variant='h6' width={notifyWidth}>Notify</Typography>
                <Typography variant='h6' width={startedAtWidth}>Started at (UTC)</Typography>
                <Typography variant='h6' width={durationWidth}>Duration</Typography>
                <Typography variant='h6' width={logsWidth}>Data Logs</Typography>

                { canDelete ? <Typography variant='h6' width={linkWidth}> &nbsp; </Typography> : null }
            </Stack>
            <Divider />
        </>
    )
}

export type TDeleteRequest = (partitionKey: string, rowKey: string) => void

function lungsDecision(d: IProcHeader) {
    if (d.disposition === 'finished' && d.isLungsAccepted) {
        return (
            <Box sx={{color:'green', fontWeight:'bold'}}>Accepted</Box>
        )
    } else if (d.disposition === 'finished' && !(d.isLungsAccepted)) {
        return (
            <Box sx={{color:'orange', fontWeight:'bold'}}>Declined</Box>
        )
    } else { // it is logically an abandoned procedure
        return (
            <Box sx={{color:'lightgrey'}}>N/A</Box>
        )
    }
}

export function Body({ rows, hospitals, deleteRequest, newTab }: 
        { rows: IProcHeader[], hospitals: IOrg[] | undefined, deleteRequest?: TDeleteRequest, newTab?: boolean }) {
    const canDelete = deleteRequest !== undefined
    const [openLogs, setOpenLogs] = useState<string | undefined>(undefined)

    let {updateUrl, updateTab} =  {updateUrl: (s: string) => {}, updateTab: (n: number) => {}}

    try {
        const context =  useOutletContext<ContextType>()
        updateUrl = context.updateUrl
        updateTab = context.updateTab

    } catch (error) {
        console.log(error)
    }
    const navigate = useNavigate()
    const onReportClick =  (reportUrlParam: string) => {
        
        updateUrl(reportUrlParam)
        updateTab(3)
        navigate('../report')
        
    }
    return (
        <>
            {rows.map(d => 
                <Stack direction='row' key={d.rowKey}>
                    <Typography width={linkWidth}> <IconButton sx={{mt:'-5px'}} 
                        onClick={() => {
                            if (!newTab)
                                window.open(`/api/getReportFromArchive?partitionKey=${d.partitionKey}&rowKey=${d.rowKey}`, '_blank')
                            else
                                onReportClick(`/api/getReportFromArchive?partitionKey=${d.partitionKey}&rowKey=${d.rowKey}`)
                            }
                        }>
                            <SummarizeOutlinedIcon fontSize='small'/>
                        </IconButton> 
                    </Typography>
                    <Typography width={evlpCaseWidth}>{d.evlpCaseNumber}</Typography>
                    <Typography width={lungsWidth}>{lungsDecision(d)}</Typography>
                    <Typography width={deviceWidth}>{d.performedOn}</Typography>
                    <Typography width={notifyWidth}>{hospitals?.find(x => x.rowKey === d.notify)?.fullName}</Typography>
                    <Typography width={startedAtWidth}>{getDateFromMilliseconds(d?.startTime, 'UTC')}</Typography>
                    <Typography width={durationWidth}>{
                        getDateFromMilliseconds(
                            d === null || d.currentTime === undefined || d.startTime === undefined
                                ? undefined
                                : d.currentTime - d.startTime, 'duration')}</Typography>

                    <Typography width={logsWidth}> <Button onClick={()=>setOpenLogs(d.rowKey)} >Open Logs</Button> </Typography>

                    
                    {openLogs === d.rowKey && <LogsDialog openLogsDialog={true} handleOpenLogsDialog={setOpenLogs} partitionKey={d.partitionKey} rowKey={d.rowKey} />}

                    { canDelete ? <Typography width={linkWidth}> <IconButton sx={{mt:'-5px'}} 
                        onClick={() => deleteRequest(d.partitionKey,d.rowKey)}>
                            <DeleteForeverOutlinedIcon fontSize='small'/>
                        </IconButton> </Typography>
 : null }
                </Stack>
            )}
        </>
    )
}