import { Box, Button, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../routes/doctor/doctorReduxHooks'
import { selectDoctors } from './sliceArchivedDoctors';
import { useEffect, useState } from 'react';
import MessageBox from '../utils/MessageBox';
import { getWindowDimensions } from '../utils/AutoScrollContainer';
import { useOutletContext } from 'react-router-dom';
import { IMonitorDoctor, IPreferences } from '../data/Person';
import { getTechsAsync, selectTechs } from './sliceTechs';
import RecepientFC, { filterRelevantInfo, getEmailsFromPeopleInfo, getPersonInfo, isValidList } from './RecipientFormControl';
import { getUserEmail, selectPreferences, setPreferencesAsync, setSend } from './slicePreferences';

type ContextType = { reportUrl: string, updateUrl: (url: string) => void, updateTab: (num: number) => void };

export default function ArchivedReport(){
    const {reportUrl} =  useOutletContext<ContextType>()
    let openReport = reportUrl !== ''
    const doctors = useAppSelector(selectDoctors)
    const docs = doctors!.filter(x => !(x.isConsultant))
    const cons = doctors!.filter(x => x.isConsultant) 

    const [preferences, setPreferences] = useState( useAppSelector(selectPreferences))
    const [showSent, setShowSent] = useState(false)

    const partitionKey = reportUrl.split('?')[1].split('&')[0].split('=')[1]
    const rowKey = reportUrl.split('?')[1].split('&')[1].split('=')[1]

    const techs: IMonitorDoctor[] = useAppSelector(selectTechs)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getTechsAsync( partitionKey ))

    }, [partitionKey, dispatch])

    const [docsName, setDocsName] = useState<string[]>([])
    const [consName, setConsName] = useState<string[]>([])
    const [techsName, setTechsName] = useState<string[]>([])

    useEffect(() => {

        const docs = doctors!.filter(x => !(x.isConsultant))
        const cons = doctors!.filter(x => x.isConsultant)

        let docs_info, cons_info, techs_info

        if (preferences){
            if (preferences.docs){
                docs_info =  preferences.docs.map((doc: string) => filterRelevantInfo(docs, doc))
                if (isValidList(docs_info))
                    setDocsName(docs_info)
            }
            if(preferences.cons){
                cons_info =  preferences.cons.map((con: string) => filterRelevantInfo(cons, con))
                if (isValidList(cons_info))
                    setConsName(cons_info)
            }
            if(preferences.techs.length){
                techs_info =  preferences.techs.map((tech: string) => filterRelevantInfo(techs, tech))
                if (isValidList(techs_info))
                    setTechsName(techs_info)
            }
        }

        if (techs){
            if (!preferences || !(isValidList(docs_info) || isValidList(cons_info) || isValidList(techs_info))) {
                const techs_info = techs.map((tech) => getPersonInfo(tech))
                if (techs_info && techs_info[0]){
                    setTechsName(techs_info)
                }
            }
        }
    }, [preferences, doctors, techs])

    const executeSend = async () => {

        let sendAddresses = ''
        const allPeople = getEmailsFromPeopleInfo(docsName.concat(consName, techsName))
      
        allPeople?.forEach((x) => {    
                sendAddresses += x.slice(1,-1) 
                sendAddresses += ','
            }
        )
        sendAddresses = sendAddresses.slice(0, -1)

        const sendApi = `/api/sendReportFromArchive?partitionKey=${partitionKey}&rowKey=${rowKey}&email=${sendAddresses}`
        await fetch(sendApi)
        setShowSent(true)
        
        const newPreferences: IPreferences = {docs: docsName, cons: consName, techs: techsName}
        setPreferences(newPreferences)
        dispatch(setSend(newPreferences))

        const table = 'doctor'
        
        getUserEmail().then((eMail) => {
            dispatch(setPreferencesAsync({table, eMail, preferences: newPreferences}))
        })

    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    
    useEffect(() =>{
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }
      
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)    
    }, [windowDimensions.height])

    return (
        <>
        {openReport && techs?
            <>
                <Box border={4} 
                    borderLeft={0}
                    borderRight={0}
                    borderColor= "silver">

                    <Typography variant='h5'>
                        Recipients 
                    </Typography>

                    <RecepientFC recepients={docs} 
                        selectedNames={docsName} 
                        handleChangeNames={setDocsName}
                        role='Surgeons'/> 

                    <RecepientFC recepients={cons} 
                        selectedNames={consName} 
                        handleChangeNames={setConsName}
                        role='Consultants'/> 

                    <RecepientFC recepients={techs} 
                        selectedNames={techsName} 
                        handleChangeNames={setTechsName}
                        role='Perfusionists'/> 

                    <br/>
                    <Button disabled={!(docsName?.length + consName?.length + techsName?.length)} onClick={executeSend}>Send report to the selected recipients</Button>
                    <MessageBox open={showSent}
                        buttonsType='MB_OK'
                        message='Report sent!'
                        callback={() => setShowSent(false)}
                        severity='info' />

                    <iframe
                        style={{ border: 10, borderColor: 'solid red', color: 'green' }}
                        id='frame'
                        title='Report'
                        width='100%'
                        height={800}
                        src={reportUrl}
                        />
                </Box>
            </>

        :null}
        </>
    )
}