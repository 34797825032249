import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { TEntryField } from '../../data/ProcData'
import DF, { ViewNumberField as VF } from './DictionaryNumberField'
import EntryItem from './RampupEntryItem'

export const fieldList: TEntryField[] = [
    'RUVPO2', 'RLVPO2',
    'LUVPO2', 'LLVPO2'
]

export default function ATab4VeinBGA() {
    const { step } = useParams()
    const GRIDSPACING = 2
    return (
        <>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={6}>
                        <DF step={step} field='RUVPO2' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <VF step={step} field='DeltaPFRUV' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <DF step={step} field='RLVPO2' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <VF step={step} field='DeltaPFRLV' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={6}>
                        <DF step={step} field='LUVPO2' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <VF step={step} field='DeltaPFLUV' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <DF step={step} field='LLVPO2' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <VF step={step} field='DeltaPFLLV' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
        </>
    )

}