import AddIcon from '@mui/icons-material/Add'
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material"
import NL from "../../../utils/NavigationLabel"


export interface INavBarProps {
  onAddClicked?: () => void
}


export default function NavBar(props: INavBarProps) {
  const addVisibility = props.onAddClicked === undefined 
        ? { visibility: 'hidden' } 
        : { visibility: 'visible' }

  return(
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation showLabels >
        <BottomNavigationAction sx={{ visibility: 'hidden' }} />
        <BottomNavigationAction sx = {addVisibility}
          label={NL('ADD')} icon={<AddIcon />}
          onClick={props.onAddClicked}
        />
        <BottomNavigationAction sx={{ visibility: 'hidden' }} />
      </BottomNavigation>
    </Paper>)
}