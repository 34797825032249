import { TOrg, TPerson } from "../../../data/Common"
import { IDevice, IServiceRecord } from '../../../data/Device'
import { ICreateOrgPayload, IOrg } from "../../../data/Org"
import { ActionQueue, IPerson } from "../../../data/Person"
import { EditableServiceSettings } from "../device/service/ServiceTypes"
import { Logins } from "../../../utils/UserSessionLogs"


async function fetchListAsync<TLabel, T>(urlTepmplate: string, typeLabel: TLabel,):Promise<Array<T>> {
  const requestUrl = urlTepmplate + typeLabel // i.e. '/api/getOrganizations?type=' + hospital
  const response = await fetch(requestUrl)
  if (response.ok) {
    const json = await response.json()
    return json
  } else {
    console.log(`Error fetching data list. Status: ${response.status}`)
    return new Array<T>()
  }
}

export async function fetchOrganizationsAsync(orgType: TOrg): Promise<Array<IOrg>> {
  return fetchListAsync<TOrg, IOrg>('/api/getOrganizations?type=', orgType)
}

export async function fetchPersonsAsync(personType: TPerson): Promise<Array<IPerson>> {
  return fetchListAsync<TPerson, IPerson>('/api/getPersons?type=', personType)
}

export async function fetchDevicesAsync(): Promise<Array<IDevice>> {
  return fetchListAsync<'device', IDevice>('/api/getDevices?type=', 'device')
}

export function fetchUserLogsAsync(): Promise<Array<Logins>> {
  return fetchListAsync<'userLogs', Logins>('/api/getLogs?type=', 'userLogs')
}

export function fetchUserActionLogsAsync({rowKey, partitionKey}: {rowKey: string, partitionKey: string}): Promise<ActionQueue[]> {
  return fetchListAsync<'userActionLogs', ActionQueue>(`/api/getLogs?rowKey=${rowKey}&partitionKey=${partitionKey}&type=`, 'userActionLogs')
}

export async function fetchServicesSettingAsync(): Promise<EditableServiceSettings> {  
  const requestUrl = '/api/getServiceSetting'
  const settings: EditableServiceSettings = await (await fetch(requestUrl)).json()

  if (settings) {
    return settings

  } else {
    console.log(`Error fetching service settings. Status: }`)
    return {} as EditableServiceSettings
  }
}

export async function updateServicesSettingAsync(settings: EditableServiceSettings) {
  const response = await fetch('/api/setServiceSettings', { 
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(settings)
  })
  return response.ok ? '' : response.statusText
}


export async function createOrganizationAsync(createOrgData: ICreateOrgPayload) {
  const response = await fetch('/api/addOrganization', { 
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(createOrgData)
  })
  return response.ok ? '' : response.statusText
}

export async function updateOrganizationAsync(org: IOrg) {
  const response = await fetch('/api/updateOrganization', { 
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(org)
  })
  return response.ok ? '' : response.statusText
}


export async function createPersonAsync(person: IPerson) {
  const response = await fetch('/api/addPerson', { 
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(person)
  })
  return response.ok ? '' : response.statusText
}
  
export async function updatePersonAsync(org: IPerson) {
  const response = await fetch('/api/updatePerson', { 
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(org)
  })
  return response.ok ? '' : response.statusText
}

export async function updatePersonLogsAsync(email: string, role: string) {
  const response = await fetch('/api/addUserLoginSession', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({'email': email, 'role': role})
  })

  return response.ok ? '' : response.statusText
}

export async function updateActionLogsAsync(email: string, proc_id: string, action: string) {
  const response = await fetch('/api/addUserActionLogs', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({'email': email, 'proc_id': proc_id, 'action': action})
  })

  return response.ok ? '' : response.statusText
}

export async function createDeviceAsync(device: IDevice) {
  const response = await fetch('/api/addDevice', { 
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(device)
  })
  return response.ok ? '' : response.statusText
}
 
export async function createServiceAsync(device: IDevice, newRecord: IServiceRecord) {
  // Our api expects a device with the array of service records containing only the newly added one
  const deviceCopy = {...device}
 
  deviceCopy.serviceRecords = [newRecord]

  const response = await fetch('/api/addService', { 
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(device)
  })
  return response.ok ? '' : response.statusText
}

export async function updateDeviceAsync(device: IDevice) {
  device.serviceRecords.splice(0) // We're not updating service records, only the device itself
  const response = await fetch('/api/updateDevice', { 
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(device)
  })
  return response.ok ? '' : response.statusText
}
