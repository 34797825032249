import { Box } from '@mui/material';
import { ReactNode } from 'react';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

export interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}


export function TabIcon(isComplete: boolean) {
    return (
        isComplete
            ? <CheckBoxOutlinedIcon color='success' />
            : <CheckBoxOutlineBlankOutlinedIcon />
    )
}

