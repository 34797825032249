import { ReactElement } from 'react'
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RunState } from './RunStore'

export interface ICheckItem {
    label: string | ReactElement
    checkIndex?: number
}

export const FinishChecksSystem:ICheckItem[]  = [
    {label: 'Stop ventilation and perfusion'},
    {label: 'Remove lungs from the system'},
    {label: 'Stop the pump and end perfusion on Cart Control Screen'},
    {label: 'Stop, drain and disconnect heater/cooler'},
    {label: 'Open blue flow sensor latch, remove Organ Chamber and drip tray and dispose as biohazardous waste'},
    {label: 'Clean Cart '},
    {label: 'Cover Cart manifold with provided protective cover'},
]

export const FinishChecksLungs: ICheckItem[] = [
    {label: 'Adjust Heater/Cooler temperature to 10°C'},
    {label: <>Set FiO<sub>2</sub> to 50%</>},
    {label: 'Once the temperature has reached 10C, Inflate lungs and clamp ventilation tube'},
]

type TFinishState = { 
    lungsChecks: Array<boolean>,
    systemChecks: Array<boolean>,
}

type TCheckTarget = keyof TFinishState

export interface ISetStateCheck {
    target: TCheckTarget
    index: number
    value: boolean
}


function initSetupFinishState(): TFinishState {
    let retState:TFinishState = { 
        lungsChecks: new Array<boolean>(),
        systemChecks: new Array<boolean>()
    }

    FinishChecksLungs.forEach((element, index) => {
        element.checkIndex = index
        retState.lungsChecks.push(false)
    })

    FinishChecksSystem.forEach((element, index) => {
        element.checkIndex = index
        retState.systemChecks.push(false)
    })
    return retState    
}

export const finishSlice = createSlice({
    name: 'finishSteps',
    initialState: initSetupFinishState,
    reducers: {
        setcheck: (state, action: PayloadAction<ISetStateCheck>) => {
            state[action.payload.target][action.payload.index] = action.payload.value
        }
    }
})

export const selectFinishChecks = (state: RunState) => state.finishChecks
export const { setcheck } = finishSlice.actions
export default finishSlice.reducer

