import { Divider, Stack, Typography } from "@mui/material"
import { Fragment } from "react"
import { Logins } from "../../../utils/UserSessionLogs"

const emailWidth = '36px'
const labelWidth = '250px'
const orgLabelWidth = '150px'
const datetimeWidth = '260px'

export function Header() {
    return (
        <Fragment>
        <Stack direction='row'>
            <Typography variant='h6' width={emailWidth}/>
            <Typography variant='h6' width={labelWidth}>Email</Typography>
            <Typography variant='h6' width={datetimeWidth}>Date & Time</Typography>
            <Typography variant='h6' width={orgLabelWidth}>Labs/Hospital</Typography>
        </Stack>
        <Divider/>
        </Fragment>
    )
}

export function Body(
    props: {userLogs: Logins[]}) {
        
    return (
        <Fragment>
         {
            props.userLogs.map((x) => 
                <Stack direction='row' key={x.email+x.rowKey}>
                    <Typography width={emailWidth}></Typography>
                    <Typography width={labelWidth}>{x.email}</Typography>
                    <Typography width={datetimeWidth}>{x.rowKey}</Typography>
                    <Typography width={orgLabelWidth}>{x.org}</Typography> 
                </Stack>
            )
         }
        </Fragment>
    )
}
