import { Button, Card, CardActions } from '@mui/material'
import { useState } from 'react'
import ProcInfoView from '../../../common/ProcInfoView'
import { selectHospitals } from '../../../common/ProfileSlice'
import { IProcHeader, TProcDisposition } from '../../../data/Procedure'
import MessageBox, { TMBReturnValue } from '../../../utils/MessageBox'
import { useAppSelector } from '../TechReduxHooks'

export default function ProcCard(
    { d, onFirstButton, onSecondButton , sx }:
        { d: IProcHeader, onFirstButton: (i: IProcHeader) => void, onSecondButton?: (i: IProcHeader) => void, sx?: any }) {

    const hospitals = useAppSelector(selectHospitals)
    

    const [showCancelConfirmation, setShowConfirmation] = useState(false)
    const onConfirmationClosed = (ret: TMBReturnValue) => {
        if (ret === 'IDYES') {
            onSecondButton!(d)
        }
        setShowConfirmation(false)
    }

    if (d.disposition === 'idle'){
        localStorage.clear()
    }

    return (
        <Card variant='elevation' elevation={3} sx={{ ...sx, p: 1, m: 1 }}>
                <ProcInfoView d={d} hospitals={hospitals}/>
                
            <CardActions>
                <Button size="small" onClick={() => onFirstButton(d)}>{getFirstButtonLabel(d.disposition)}</Button>
                {onSecondButton !== undefined
                    ? <Button size="small" onClick={() => setShowConfirmation(true)}>{d.disposition === 'init' ? 'Cancel' : 'Archive'}</Button>
                    : null
                }
            </CardActions>
            <MessageBox open={showCancelConfirmation}
                buttonsType='MB_YESNO'
                message={d.disposition === 'init' ? 'Are you sure you want to cancel the procedure?' : 'Are you sure you want to archive the procedure?'}
                callback={(ret) => onConfirmationClosed(ret)}
                severity='warning' />
        </Card>

    )
}

function getFirstButtonLabel(disposition: TProcDisposition) {
    switch (disposition) {
        case 'init': return 'Start';
        case 'ongoing': return 'Monitor';
        case 'idle': return 'Continue'
        case 'finished': 
        case 'abandoned': return 'Review';
    }
}