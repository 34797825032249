import { useEffect, useState } from "react";
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { isDataOffline, uploadOfflineData } from "./offlineData";

export default function WifiStatus() {
    const [isOnline, setIsOnline] = useState(navigator.onLine)

    useEffect(() => {
      const handleOnlineStatusChange = () => {
        setIsOnline(navigator.onLine)

        if (navigator.onLine && isDataOffline()){
          uploadOfflineData()
        }
      }

      window.addEventListener('online', handleOnlineStatusChange)
      window.addEventListener('offline', handleOnlineStatusChange)

      return () => {
        window.removeEventListener('online', handleOnlineStatusChange)
        window.removeEventListener('offline', handleOnlineStatusChange)
      }
    }, [])

    return (
    <>
    {   !isOnline?
            <WifiOffIcon style={{fill: "red", padding: "5px"}}/>
        : null
    }
    </>)
}