import { combineReducers, configureStore } from '@reduxjs/toolkit'
import profileReducer from '../../common/ProfileSlice'
import proceduresReducer from './current/ProceduresSlice'
import doctorsReducer from './archive/sliceArchivedDoctors'
import techReducer from '../../common/sliceTechs'
import preferencesReducer from '../../common/slicePreferences'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'techStore',
  storage,
}

const reducer = combineReducers({
  profileSlice: profileReducer,
  proceduresSlice: proceduresReducer,
  doctorsSlice: doctorsReducer,
  techSlice: techReducer,
  preferencesSlice: preferencesReducer
})

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})
  
export type TechRootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)

export const clearStorage = async () => {
  sessionStorage.clear()
  await persistor.purge()
}