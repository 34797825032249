import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { selectHospitals } from '../../../common/ProfileSlice'
import { IProcHeader } from '../../../data/Procedure'
import { ResizableTable } from '../../../utils/ResizableTable'
import { getArchiveAsync } from '../current/fetchApi'
import { useAppSelector } from '../TechReduxHooks'
import { Body, Header } from '../../../common/ArchiveTable'

// Note: we don't use Redux here since we don't want to cache archived procedures. It would be a waste of memory.
export default function Archive() {
    const [archive, setArchive] = useState<IProcHeader[] | null>(null)
    const [showProgress, setShowProgress] = useState(true)
    const hospitals = useAppSelector(selectHospitals)

    useEffect(() => {
        const fetchData = async () => {
            setShowProgress(true)
            const fetchResult = await getArchiveAsync()
            if (typeof fetchResult !== 'string') {
                setArchive(fetchResult)
            }
            setShowProgress(false)
        }

        fetchData()
        return () => setArchive(null)
    }, [])

    if (showProgress) {
        return <CircularProgress size='96px' sx={{ position: 'absolute', top: '45%', left: '43%', transform: 'translate(-45%, -43%)' }} />
    } else {
        return (
            <>
                {archive === null
                    ? 'No data'
                    : <ResizableTable header={<Header />} body={<Body rows={archive} hospitals={hospitals} newTab={true}/>} />}
            </>)
    }
}

