import { IBasicEntityBase } from './Common'
import { TRampupStep, TStep } from './WorkflowSteps'

// The following states are for interapp communciations only, they are persisted in DB
export const ProcDispositions = ['init', 'ongoing', 'idle', 'finished', 'abandoned', 'archived'] as const
export type TProcDisposition = typeof ProcDispositions[number]

export interface IProcHeaderBase extends IBasicEntityBase {
    lastUpdatedAt: number,
    recordCreatedAt?: number
    disposition: TProcDisposition
    step: TStep
    performedOn: string
    initiatedBy: string
    performedBy: string
    notify: string
    startTime: number
    currentTime: number
    timeZone?: string
    isOperationTimeRecorded?: boolean,
    userActionLogs?: string
}

export interface IStartProcParams extends IBasicEntityBase {
    lockingClient: string
    performedBy: string
}

export const SexTypes = ['male', 'female'] as const
export type TSex = typeof SexTypes[number]

export const DonorTypes = ['unspecified',  'cDCD', 'uDCD', 'MAID', 'DBD'] as const
export type TDonorType = typeof DonorTypes[number]

export const BloodTypes = ['unspecified', 'A', 'B', 'AB', 'O'] as const
export type TBloodType = typeof BloodTypes[number]

export const LungTypes = ['left', 'right', 'both']
export type TLung = typeof LungTypes[number]

export interface IProcClinical {
    evlpCaseNumber: string 
    perfusionist: string
    lung: TLung
    sex: TSex
    height_cm: number
    idealBodyWeight_Kg?: number
    cardiacOutput_LPmin?: number
    evlpMaxFlow_LPmin?: number
    steadyStateVV_mL?: number
    assessmentVV_mL?: number
    recruitmentVV_mL?: number
    respiratoryRate_bpm?: number
    PEEP_cmH20?: number
    FiO2_Percent?: number
    age_years: number
    weight_Kg: number
    bodyMassIndex: number
    donorType: TDonorType
    bloodType: TBloodType
    crossClampDT: number | null
    pTLC?: number 
    rampUpParameters?: {[key in TRampupStep]: number}
    rampUpParametersPersistent?: string
    isLungsAccepted: boolean
    evlpKitNumber: string
    isPABGAPerformedAt32Cplus5min?: boolean   
    isPABGADataEnteredAt32Cplus5min?: boolean //These flags will be only on 32C step to indicate workflow 
    PABGAat32MinExpirationTime?: number  // states of the intermitten point @ 32C+5min
}



export interface IEVLPIndications {
    isConsernsOfAspiration?: boolean 
    isLowDonorPO2?: boolean 
    isFromMarginalDCD?: boolean
    isHighRiskHistory?: boolean
    isPresenceOfEndema?: boolean
    isPoorCompliance?: boolean
    otherIndications?: string
}

export interface IProcHeader extends IProcHeaderBase, IProcClinical, IEVLPIndications {

}
