import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IBasicEntityBase } from '../../data/Common'
import { TNotes } from '../../data/Notes'
import { MonitorState } from './monitorStore'

export interface INotesState {
    data: TNotes | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: INotesState = {
    data: null,
    status: 'idle',
}

export const getNotesAsync = createAsyncThunk(
    'mediaContent/fetchNotes',
    async (identity: IBasicEntityBase) => {
        const requestUrl = `/api/getNotes?partitionKey=${identity.partitionKey}&rowKey=${identity.rowKey}`
        const response = await fetch(requestUrl)
        if (response.ok) {
            let json: TNotes = await response.json()
            return json
        } else {
            console.log(`Error fetching notes for procedure  ${identity.partitionKey}:${identity.rowKey}. Status: ${response.status}`)
            return null
        }
    }
)

export const notesSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotesAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getNotesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                const init = action.payload
                state.data = init
            })
        }
    }
)


export const selectNotes = (state: MonitorState) => state.notesSlice.data
export const selectStatus = (state: MonitorState) => state.notesSlice.status
export default notesSlice.reducer


