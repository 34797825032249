import { configureStore } from '@reduxjs/toolkit'
import labsReducer from './org/LabsSlice'
import hospitalsReducer from './org/HospitalsSlice'
import techsReducer from './person/TechsSlice'
import doctorsReducer from './person/DoctorsSlice'
import devicesReducer from './device/DevicesSlice'
import userLogsReducer from './userLogs/UserLogsSlice'
import userActionLogsReducer from './userLogs/UserActionLogsSlice'

import serviceSettingsReducer from './device/service/ServiceSettingsSlice'


export const store = configureStore({
    reducer: {
      labs: labsReducer,
      hospitals: hospitalsReducer,
      techs: techsReducer,
      doctors: doctorsReducer,
      devices: devicesReducer,
      serviceSettings: serviceSettingsReducer,
      userLogs: userLogsReducer,
      userActionLogs: userActionLogsReducer,

    }
  })
  
  export type AdminRootState = ReturnType<typeof store.getState>
  export type AppDispatch = typeof store.dispatch