import { TEntryField, TMemProcData } from '../../data/ProcData'
import { TTimelineStep } from '../../data/WorkflowSteps'

export default function aGetFormCompletion(stepData:TMemProcData, stepDataLabel:TTimelineStep, fields: TEntryField[] ){
    let retval = true
    for(let f of fields) {
        if  (stepData[stepDataLabel][f] === undefined) {
          retval = false
          break  
        }
    }
    return retval
}
