import { useEffect } from 'react';
import { IProcHeader } from '../../data/Procedure';
import AutoScrollContainer from '../../utils/AutoScrollContainer';
import { useAppDispatch, useAppSelector } from './doctorReduxHooks';
import ProcCard from './ProcCard';
import { getProceduresAsync, selectAll } from './ProceduresSlice';

export default function DocCurrent() {
    const procedures = useAppSelector(selectAll)
        .slice()
        .sort((first, second) => second.recordCreatedAt! - first.recordCreatedAt!)

    const dispatch = useAppDispatch()

    useEffect(() => {
        const interval = setInterval(() => {
                dispatch(getProceduresAsync())
        }, 1 * 60 * 1000);
        return () => clearInterval(interval);
    }, [dispatch]);

    // TODO: deal with orchestration here
    const onFirstButton = (i: IProcHeader) => {
        switch (i.disposition) {
            case 'init':
                break
            case 'ongoing':
                window.open(`/monitor/${i.partitionKey}/${i.rowKey}`, '_blank')
                break
            case 'idle':
                window.open(`/monitor/${i.partitionKey}/${i.rowKey}`, '_blank')
                break
            case 'finished': 
                window.open(`/monitor/${i.partitionKey}/${i.rowKey}`, '_blank')
                break
        }
    }

    return (
        <>
            <AutoScrollContainer bottomOffset={70} >
                {procedures.map(p =>
                    <ProcCard d={p} key={p.rowKey} sx={{ mt: '5px' }}
                        onFirstButton={() => onFirstButton(p)}
                    />
                )}
            </AutoScrollContainer>
        </>)
}