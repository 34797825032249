import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchOrganizationsAsync } from "../common/FetchApi"

export const getLabsAsync = createAsyncThunk(
  'labs/fetchLabs',
  async () => {
      const response = await fetchOrganizationsAsync('lab')
      return response
  }
)

export const getHospitalsAsync = createAsyncThunk(
  'hospitals/fetchHospitals',
  async () => {
      const response = await fetchOrganizationsAsync('hospital')
      return response
  }
)









