import { TOrg } from '../../../data/Common'
import { ICreateOrgPayload, IOrg, IOrgData } from '../../../data/Org'
import { IPersonData } from '../../../data/Person'

export interface ICreateOrgData extends IOrgData, IPersonData {
}

export function NewCreateOrgData(orgType:TOrg): ICreateOrgData {
    return {
        label: '',
        fullName: '',
        contact: '',
        typeTag: orgType === 'lab' ? 'tech' : 'doctor',
        honorific: '',
        firstName: '',
        lastName: '',
        eMail: '', 
        phoneNumber: '',
    }
}

export function CreateOrgPayloadFromData(data: ICreateOrgData): ICreateOrgPayload {
    const orgRowKey = crypto.randomUUID()
    let org: IOrg = {
        partitionKey: data.typeTag === 'tech' ? 'lab' : 'hospital',
        rowKey: orgRowKey,
        isActive: true,
        label: data.label,
        fullName: data.fullName,
        contact: data.eMail,
    }

    if (data.typeTag !== 'tech') {
        org.insighTxPermission = data.insighTxPermission
    }

    return {
        org: {...org},
        contact: {
            partitionKey: orgRowKey,
            rowKey: crypto.randomUUID(),
            isActive: true,
            typeTag: data.typeTag,
            honorific: data.honorific,
            firstName: data.firstName,
            lastName: data.lastName,
            eMail: data.eMail,
            phoneNumber: data.phoneNumber,
            orgLabel: data.label
        }
    }
}