import { Checkbox, Divider, FormControl, FormControlLabel, Grid, TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { IOrg } from '../../../data/Org'
import { IPerson } from '../../../data/Person'
import OrgInfoDisplay from './OrgInfoDisplay'

export interface IEditFormProps {
    data: IPerson
    onDataItemChanged: (id: string, value: string | boolean) => void
    org: IOrg
}

export default function EditForm(props: IEditFormProps) {
    

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        props.onDataItemChanged(id, id === 'isActive' ? (e.target as HTMLInputElement).checked : e.target.value)
    }

    return (
        <FormControl fullWidth>
            <Divider textAlign='left' sx={{mb:'20px', mt:'2px'}}/>
            <Grid container spacing={1}>
               <Grid item xs={2}>
                    <FormControlLabel sx={{mt:'10px'}} 
                        control={<Checkbox id='isActive' onChange={onChange} checked={props.data['isActive']}  
                        disabled = {props.org.isActive && (props.org.contact === props.data.eMail)}/>} label='Active' />
               </Grid>

               <Grid item xs={2}>
                    <TextField fullWidth label='Honorific' 
                        value={props.data['honorific']} id='honorific' onChange={onChange} 
                        />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label='First Name' 
                        value={props.data['firstName']} id='firstName' onChange={onChange} 
                        /> 
                </Grid>
                <Grid item xs={2}>
                    <TextField fullWidth label='Last Name' 
                        value={props.data['lastName']} id='lastName' onChange={onChange} 
                        />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth 
                        label={'eMail' + (props.org.contact === props.data.eMail ? '(Org. contact)' : '') } 
                        disabled = {props.org.contact === props.data.eMail}
                        value={props.data['eMail']} id='eMail' onChange={onChange} 
                        /> 
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label='Phone Number' 
                        value={props.data['phoneNumber']} id='phoneNumber' onChange={onChange} 
                        />
                </Grid>
            </Grid>
            <Divider textAlign='left' sx={{mb:'10px', mt:'20px'}}>Belongs to Organization</Divider>
            <OrgInfoDisplay org={props.org}/>
        </FormControl>
    )    
}