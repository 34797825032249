import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IPreferences } from "../data/Person"
import { getUserInfoAsync } from "../utils/PrinicpalUtils"

export interface ISetCheck {
    id: string,
    value: boolean
}

export interface IPreferencesState {
    data: IPreferences | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IPreferencesState = {
    data: null,
    status: 'idle'
}

export const getUserEmail = async () => {
    const userInfo =  await getUserInfoAsync()
    return userInfo.userDetails
}
export const getUserRoles = async () => {
    const userInfo =  await getUserInfoAsync()
    return userInfo.userRoles
}

export const getPreferencesAsync = createAsyncThunk(
    'user/getUserPreferences',
    async (user: {table: 'doctor' | 'tech', eMail: string}) => {
        const {table, eMail} =  user
        const requestUrl = `/api/getUserPreferences?table=${table}&eMail=${eMail}`

        const response = await fetch(requestUrl)
        if (response.ok) {
            const json: IPreferences = await response.json()
            return json
        } else {
            console.log(`Error fetching preferences from ${eMail} data from ${table}. Status: ${response.status}`)
            return null
        }
    }
)

export const setPreferencesAsync = createAsyncThunk(
    'user/setUserPreferences',
    async (user: {table: 'doctor' | 'tech', eMail: string, preferences: IPreferences}) => {
        const {table, eMail, preferences} =  user
        const requestUrl = `/api/setUserPreferences?table=${table}&eMail=${eMail}`

        const response = await fetch(requestUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(preferences)
        })
        
        if (response.ok) {
            return response.ok
        } else {
            console.log(`Error updating preferences from ${eMail} data from ${table}. Status: ${response.status}`)
            return null
        }
    }
)

export const preferencesSlice = createSlice({
    name: 'preferences',
    initialState: initialState,
    reducers: {
        setSend: (state, action) => {
            if (action.payload)
                state.data = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPreferencesAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getPreferencesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload!
            })
    }
})

export const selectPreferences = (state: { preferencesSlice: { data: any } }) => state.preferencesSlice.data
export const { setSend } = preferencesSlice.actions

export default preferencesSlice.reducer