import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, IconButton, ImageList, Stack, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TSteadyStep, TStep } from '../../data/WorkflowSteps'
import ImageUploadDialog, { deleteFile } from './ImageUploadDialog'
import { useAppDispatch, useAppSelector } from './monitorReduxHooks'
import NotesEditDialog from './NotesEditDialog'
import { selectMediaContent, setImageComparisonFlagAsync } from './silceMediaContent'
import { selectNotes } from './silceNotes'
import { selectProcHeader, setAllowUpdates } from './sliceMonitorProcHeader'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function TabMediaHour() {
    const { hour } = useParams()
    const stepHour = hour! as TStep
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [showNotesEdit, setShowNotesEdit] = useState(false)
    const images = useAppSelector(selectMediaContent)![stepHour as TSteadyStep]
    let   notes = useAppSelector(selectNotes)![stepHour as TSteadyStep]
    notes = !notes ? '' : notes
    const header = useAppSelector(selectProcHeader)
    const device = header?.performedOn
    const proc = header?.rowKey
    const dispatch = useAppDispatch()
    const onImageChanged = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length !== 0) {
            dispatch(setAllowUpdates(false))
            setSelectedFile(event.target.files[0])
        }
    }

    return (
        <>
            <Button component="label">
                <input hidden accept="image/*,video/*" type="file" onChange={onImageChanged} />
                Add media
            </Button>
            <Button component="label" onClick={() => { dispatch(setAllowUpdates(false)); setShowNotesEdit(true) }}>
                Add/edit notes
            </Button>
            <Divider  textAlign="left">Media</Divider>
            <ImageList rowHeight={'auto'} cols={4} sx={{ pl: '5px' }}>
                {images!.map((x =>
                    x.type === 'image'
                        ? <ImagePreview key={x.name} device={device!} proc={proc!} hour={stepHour} fileName={x.name} description={x.description} iscomparison={x.iscomparison}/>
                        : <VideoPreview key={x.name} device={device!} proc={proc!} hour={stepHour} fileName={x.name} description={x.description}/>
                ))}
            </ImageList>
            <Divider  textAlign="left">Notes</Divider>
            <Typography sx={{whiteSpace:'pre-wrap'}}>{notes}</Typography>
            <ImageUploadDialog file={selectedFile} key={Math.random()} stepHour={stepHour} 
                onFinish={() => { setSelectedFile(null); dispatch(setAllowUpdates(true)); }} />
            <NotesEditDialog key={Math.random()} show={showNotesEdit} 
                notesBefore={notes!} onFinish={() => {setShowNotesEdit(false); dispatch(setAllowUpdates(true))}} 
                stepHour={stepHour} />
        </>
    )
}


function mediaUrl(device: string, proc: string, hour: TStep, fileName: string) {
    return `/api/getMediaFile?device=${device}&proc=${proc}&time=${hour}&name=${fileName}`
}

function ImagePreview({ device, proc, hour, fileName, description, iscomparison }: 
                        { device: string, proc: string, hour: TStep, fileName: string, description: string, iscomparison: boolean | undefined  }) {
    const [isLoading, setIsLoading] = useState(true)
    const url = mediaUrl(device, proc, hour, fileName)
    const dispatch = useAppDispatch()
    const header = useAppSelector(selectProcHeader)
    const onCompChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setImageComparisonFlagAsync({device: device, time: hour, name: fileName, proc: proc, flag: e.target.checked}))
    }

    const handleDelete = () => {
        deleteFile(url, header, dispatch);
      }

    return (
        <Box sx={{ p: '3px', position: 'relative', display: 'flex', alignItems: 'left'}} border={isLoading ? 1 : 0}>
            {isLoading
                ? <CircularProgress size='36px' sx={{ position: 'absolute', top: '30%', left: '40%', transform: 'translate(-30%, -40%)' }} />
                : null
            }

            <Stack>
                <Stack direction={'row'}>
                    <a href={url} target='_blank' rel='noopener noreferrer'>
                        <img onLoad={() => setIsLoading(false)} key={fileName} style={{ objectFit: 'scale-down', height: '100px', width: 'fit-content' }}
                            src={mediaUrl(device, proc, hour, fileName)} loading='lazy'
                            alt='' />
                    </a>

                    <IconButton
                        onClick={handleDelete}
                        >
                        <DeleteForeverIcon />
                    </IconButton>
                </Stack>

                <Typography>{description}</Typography>
                <FormControlLabel sx={{mr:'15px', mt:'0px', mb:'15px'}} control={<Checkbox checked={iscomparison} onChange={onCompChange} />} label="Compare"  />

            </Stack>


            
           <br/> 
        </Box>
    )
}

function VideoPreview({ device, proc, hour, fileName, description  }: { device: string, proc: string, hour: TStep, fileName: string, description: string }) {
    const url = mediaUrl(device, proc, hour, fileName)
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useAppDispatch()
    const header = useAppSelector(selectProcHeader)

    return (
        <Box sx={{ p: '3px', position: 'relative' }} border={isLoading ? 1 : 0}>
            {isLoading
                ? <CircularProgress size='36px' sx={{ position: 'absolute', top: '30%', left: '40%', transform: 'translate(-30%, -40%)' }} />
                : null
            }

            <a href={url} target='_blank' rel='noopener noreferrer'>
                <video key={fileName} height='100px' width='180px'
                    onCanPlay={() => { setIsLoading(false)}}
                    onLoad={() => { setIsLoading(true)} } >
                    <source src={url} />
                    No support
                </video>
            </a>
            <Typography>{description}</Typography>
            <br/>
            <Button onClick={()=> {deleteFile(url, header, dispatch)}}>Delete</Button>
        </Box>
    )
}
