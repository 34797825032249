import { Grid, TextField } from '@mui/material'
import { useAppSelector } from './RunReduxHooks'
import { selectProcHeader } from './sliceRunProcHeader'


const dsx = {
    label: { WebkitTextFillColor: "black", fontSize: 22 },
    '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: 'black', fontWeight: 'bold', fontSize: 18 }
}


export default function UIRampupVentParameters() {
    const procHeader = useAppSelector(selectProcHeader)

    return (
        <Grid container spacing={1} >
            <Grid item xs={3}>
                <TextField sx={dsx} variant='standard' disabled value={procHeader!.steadyStateVV_mL!.toFixed(0) + ' ml'}
                    label='Steady-State Ventilation Tidal Volume' />
            </Grid>
            <Grid item xs={3}>
                <TextField sx={dsx} variant='standard' disabled value={procHeader!.recruitmentVV_mL!.toFixed(0) + ' ml'}
                    label='Recruitment Ventilation Tidal Volume' fullWidth/>
            </Grid>
            <Grid item xs={3}>
                <TextField sx={dsx} variant='standard' disabled value={procHeader!.assessmentVV_mL!.toFixed(0) + ' ml'}
                    label='Assessment Ventilation Tidal Volume' fullWidth/>
            </Grid>
            <Grid item xs={3}>
                <TextField sx={dsx} variant='standard' disabled value={procHeader!.PEEP_cmH20!.toFixed(0)}
                    label={<>PEEP (cm H<sub>2</sub>O)</>} />
            </Grid>
            <Grid item xs={3} >
                <TextField sx={dsx} variant='standard' disabled value={procHeader!.respiratoryRate_bpm!.toFixed(0)}
                    label='Respiratory Rate (bpm)' />
            </Grid>
            <Grid item xs={3}>
                <TextField sx={dsx} variant='standard' disabled value={procHeader!.FiO2_Percent!.toFixed(0) + '%'}
                    label={<>FiO<sub>2</sub></>} />
            </Grid>
        </Grid>
    )
}