export type TDateOut = 'UTC' | 'local' | 'duration'
export default function getDateFromMilliseconds(time: number | undefined, outFormat: TDateOut, tz?: string): string {
    if (time === undefined) {
        return ''
    }
    let date = new Date(time)
    switch (outFormat) {
        case 'UTC': return date.toUTCString()
        case 'local': 
            if (tz) {
                return date.toLocaleDateString('en-us', {
                    year:'numeric', 
                    month:'long', 
                    day:'numeric', 
                    hour:'numeric', 
                    minute:'numeric',
                    timeZone:tz})    
            } else {
               return date.toLocaleString()
            }
        case 'duration':
            const elaspedSeconds = Math.round(time / 1000)
            const elaspedMinutes = Math.floor(elaspedSeconds / 60)
            const elaspedHours = Math.floor(elaspedMinutes / 60)
            const minutes = elaspedMinutes - (elaspedHours * 60)
            return `${elaspedHours} hours ${minutes < 10 ? '0' + minutes : minutes} minutes`
    }
}
