import React, { useState } from 'react';
import { Grid, TextField, MenuItem, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { DateTimeField, DateTimePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isSetupStep } from '../../data/WorkflowSteps';
import NumericInput from '../../utils/NumericInput';
import gD from '../../common/getDateFromMilliseconds';
import { BloodTypes, DonorTypes, IProcHeader, TSex } from '../../data/Procedure';
import { MinAge, MaxAge, MinHeight, MaxHeight, MinWeight, MaxWeight } from '../tech/current/AddProcDialog';
import { EVLPIndications } from '../../common/ProcInfoView';


const DonorDataTabContent = ({d, setD }: {d: IProcHeader, setD: (x: IProcHeader) => void}) => {

    const [tempCCDT, setCCDT] = useState<dayjs.Dayjs>(dayjs(d.crossClampDT)) 


    const handleBloodTypeChange = (n: any) => {
        if (n && setD) {
            //dispatch(setBloodType(n))
            let newData = {...d}
            newData.bloodType = n
            newData.lastUpdatedAt = Date.now()
            setD(newData)
        }
    }

    const handleNumberChange = (e: any, n: number) => {
        if (n) {
            let newData = {...d}
            newData.age_years = n
            setD(newData)    
        }
    }

    const handleWeightChange = (n: number | undefined, e:React.ChangeEvent<HTMLInputElement>) => {
        if (n) {
            let newData = {...d}
            newData.weight_Kg = n
            setD(newData)
        }
    }

    const handleHeightChange = (n: number | undefined, e:React.ChangeEvent<HTMLInputElement>) => {
        if (n) {
            let newData = {...d}
            newData.height_cm = n
            setD(newData)
        }
    }

    const handleDonorTypeChange = (n: any) => {
        if (n) {
            let newData = {...d}
            newData.donorType = n
            setD(newData)
        }
    }

    const handleCCTChange : DateTimePickerProps<dayjs.Dayjs>['onChange'] = (n) => {
        
        let newData = {...d}
        if (n){
            newData.crossClampDT = (n.toDate()).getTime()
            setD(newData)
            setCCDT(n)
        } 
    }

    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newData = {...d}
        newData.sex = (event.target as HTMLInputElement).value as TSex
        setD(newData)
    }

    let CIT1 = ''
    if (d.crossClampDT === undefined || d.crossClampDT == null){
        CIT1 = 'N/A'
    }
    else if (d.startTime !== 0 && d.crossClampDT) {
        CIT1 = `${gD(d.startTime - d.crossClampDT, 'duration')}`
    }

    return (
        <Grid container spacing={1} sx={{ mb: '10px', mt: '3px' }}>
            <Grid item xs={4}>
                <DateTimeField 
                    fullWidth 
                    value={tempCCDT} 
                    label='Cross clamp date/time' 
                    format='YYYY-MM-DD HH:mm' 
                    onChange={handleCCTChange} 
                    disabled={!isSetupStep(d.step)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField 
                    fullWidth 
                    select 
                    name='donorType' 
                    label='Donor Type' 
                    value={d?.donorType} 
                    onChange={(e) => handleDonorTypeChange(e.target.value)} 
                    disabled={!isSetupStep(d.step)}
                >
                    {DonorTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField 
                    fullWidth 
                    select 
                    name='bloodType' 
                    label='Donor Blood Type' 
                    value={d?.bloodType} 
                    onChange={(e) => handleBloodTypeChange(e.target.value)} 
                    disabled={!isSetupStep(d.step)}
                >
                    {BloodTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                </TextField> 
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel>Age (years)</FormLabel>
                    <NumericInput 
                        fullWidth 
                        id='age_years'
                        variant='standard'
                        step={1}
                        initValue={d?.age_years} 
                        onNumberChanged={(n, e) => handleNumberChange(e, n)}
                        min={MinAge}
                        max={MaxAge}
                        disabled={!isSetupStep(d.step)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel>Donor Height (cm)</FormLabel>
                    <NumericInput  
                        fullWidth 
                        id='height_cm'
                        variant='standard'
                        step={1}
                        initValue={d?.height_cm}
                        onNumberChanged={(n, e) => handleHeightChange(n, e)}
                        min={MinHeight}
                        max={MaxHeight}
                        disabled={!isSetupStep(d.step)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel>Donor Weight (kg)</FormLabel>
                    <NumericInput 
                        fullWidth 
                        id='weight_Kg'
                        variant='standard'
                        step={.1}
                        initValue={d?.weight_Kg} 
                        onNumberChanged={(n, e) => handleWeightChange(n, e)} 
                        min={MinWeight}
                        max={MaxWeight}
                        disabled={!isSetupStep(d.step)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <>
                    <FormLabel>Donor Sex</FormLabel>
                    <RadioGroup name='gender' value={d?.sex} onChange={handleGenderChange} row>
                        <FormControlLabel value='male' control={<Radio />} label='Male' disabled={!isSetupStep(d.step)}/>
                        <FormControlLabel value='female' control={<Radio />} label='Female' disabled={!isSetupStep(d.step)}/>
                    </RadioGroup>
                </>
            </Grid>
            <Grid item xs={4}>
                <b>pTLC: </b>{d?.pTLC}
            </Grid>
            <Grid item xs={4}>
                {CIT1 !== ''
                    ? <><b>CIT1: </b>{CIT1}</>
                    : <><b>CIT1: </b>N/A</>
                }
            </Grid>
            <EVLPIndications d={d} />
        </Grid>
    );
}

export default DonorDataTabContent;
