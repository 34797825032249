import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, ToggleButtonGroup, Grid, ToggleButton, Typography } from '@mui/material';
import { useState } from 'react'
import { useAppDispatch } from '../tech/TechReduxHooks';
import { useAppSelector } from './RunReduxHooks'
import { selectProcHeader, setlung } from './sliceRunProcHeader'

export default function ChangeProcedureDialog({ open, closeCallback }: {open: boolean, closeCallback: () => void }) {
    const lung = useAppSelector(selectProcHeader)!.lung
    const [tmpLung, setTmpLung] = useState(lung)
    const [canOk, setCanOk] = useState(false)
    const dispatch = useAppDispatch()

    const handleProcedureChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        setTmpLung(newValue)
        setCanOk(newValue !== lung)
    }

    const doChangeProcedure = () => {
        dispatch(setlung(tmpLung))
        closeCallback()
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Change procedure type?</DialogTitle>
            <DialogContent>
                <DialogContentText >
                    Changing procedure type will recalculate all procedure parameters. User is required to change parameters upon confirmation.
                </DialogContentText>
                <Grid item xs={12} sx={{ mb: '10px' }}>
                    <Typography variant='h6' >New procedure type:</Typography>
                </Grid>
                <ToggleButtonGroup exclusive fullWidth value={tmpLung} onChange={handleProcedureChange}>
                    <ToggleButton fullWidth value='left'>Left</ToggleButton>
                    <ToggleButton fullWidth value='right'>Right</ToggleButton>
                    <ToggleButton fullWidth value='both'>Both</ToggleButton>
                </ToggleButtonGroup>

            </DialogContent>
            <DialogActions>
                <Button onClick={doChangeProcedure} disabled={!canOk}>OK</Button>
                <Button onClick={closeCallback} autoFocus>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}


