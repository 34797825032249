import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TPerson } from '../data/Common'
import { IProfile } from '../data/Profile'
import { RunState } from '../routes/run/RunStore'
import { TechRootState } from '../routes/tech/TechStore'

const TechClientIdName = 'TraferoxTechClientId'


async function fetchProfileAsync(role: TPerson):Promise<IProfile | undefined> {
    const requestUrl = `/api/getProfile?role=${role}`
    const response = await fetch(requestUrl)
    if (response.ok) {
      const json = await response.json()
      return json
    } else {
      console.log(`Error fetching profile. Status: ${response.status}`)
      return undefined
    }
}

export const getProfileAsync = createAsyncThunk(
    'profile/fetchProfile',
    async () => {
        const response = await fetchProfileAsync('tech')
        return response
    }
)



export interface IProfileState {
    clientId: string
    profile: IProfile | undefined
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IProfileState = {
    clientId: '',
    profile: undefined,
    status: 'idle'
}


export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        acquireClientId: (state) => {
            if (state.clientId === '') {
                const clientId = localStorage.getItem(TechClientIdName)
                if (clientId === null) {
                    state.clientId = crypto.randomUUID()
                    localStorage.setItem(TechClientIdName, state.clientId)
                } else {
                    state.clientId = clientId
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfileAsync.pending, (state) => {
                state.profile = undefined
                state.status = 'loading';
            })
            .addCase(getProfileAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.profile = action.payload;
            });
    },
})

export const { acquireClientId } = profileSlice.actions

export const selectProfile = (state: TechRootState) => state.profileSlice.profile
export const selectStatus = (state: TechRootState) => state.profileSlice.status
export const selectDevices = (state: TechRootState) => state.profileSlice.profile?.devices
export const selectHospitals = (state: TechRootState | RunState) => state.profileSlice.profile?.hospitals
export const selectClientId = (state:TechRootState) => state.profileSlice.clientId
export default profileSlice.reducer


