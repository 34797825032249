import { Fragment } from "react";
import { useAppSelector } from "../AdminReduxHooks";
import { selectAll } from "./UserLogsSlice";
import { Body, Header } from "./TableUserLogs";

export default function UserLogs() {
    const logs = useAppSelector(selectAll)

    const logins = logs
    return <Fragment>
        <Header/>
        <Body userLogs={logins} />
        
    </Fragment>
}