import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchUserActionLogsAsync, fetchUserLogsAsync } from "../common/FetchApi"

export const getUserLogsAsync = createAsyncThunk(
    'logins/fetchUserLogs',
    async () => {
        const response = await fetchUserLogsAsync()
        return response
    }
  )

  export const getUserActionLogsAsync = createAsyncThunk(
    'actionLogs/fetchUserActionLogs',
    async ({rowKey, partitionKey}: {rowKey: string, partitionKey: string}) => {

        const response = await fetchUserActionLogsAsync({rowKey: rowKey, partitionKey: partitionKey})
        return response
    }
  )
  