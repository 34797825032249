import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { getAllChecked } from './finishUtils';
import { useAppDispatch, useAppSelector } from './RunReduxHooks';
import { FinishChecksSystem, selectFinishChecks, setcheck } from './sliceFinish';
import { selectProcHeader } from './sliceRunProcHeader';

export default function FTab1Lungs({ completion }: { completion: (allChecked: boolean) => void }) {
    const finishChecks = useAppSelector(selectFinishChecks)
    const procHeader = useAppSelector(selectProcHeader)
    const dispatch = useAppDispatch()
    

    useEffect(() => {
        completion(getAllChecked(finishChecks.systemChecks))
    }, [finishChecks.systemChecks, procHeader, completion])

    const handleSystemChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setcheck({ target: 'systemChecks', index: index, value: event.target.checked }))
    };


    return (
        <>
                <Grid container justifyContent='center' sx={{ mt: '0px' }}>
                    {
                        FinishChecksSystem.map((element, index) =>
                            <Grid key={Math.random()} item xs={12}>
                                <Paper elevation={2} sx={{ mt: '2px' }}>
                                    <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                                        checked={finishChecks.systemChecks[element.checkIndex!]}
                                        onChange={(e) => handleSystemChange(element.checkIndex!, e)} />}
                                        label={<Typography variant='h5'>{element.label}</Typography>} />
                                </Paper>
                            </Grid>)
                    }
                </Grid>
        </>
    )
}