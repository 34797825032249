import { useAppSelector } from "../AdminReduxHooks"
import Page from "./Page"
import { selectAll, add, update } from "./LabsSlice"


export default function Lab() {
    const orgs = useAppSelector(selectAll)

    return (<Page items={orgs} itemType={'lab'} addAction={add} updateAction={update} />)
}

