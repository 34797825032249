import { configureStore } from '@reduxjs/toolkit'
import proceduresReducer from './ProceduresSlice'
import doctorsReducer from '../../common/sliceArchivedDoctors'
import techReducer from '../../common/sliceTechs'
import preferencesReducer from '../../common/slicePreferences'


export const store = configureStore({
    reducer: {
      proceduresSlice: proceduresReducer,
      doctorsSlice: doctorsReducer,
      techSlice: techReducer,
      preferencesSlice: preferencesReducer
    }
  })
  
  export type DocRootState = ReturnType<typeof store.getState>
  export type AppDispatch = typeof store.dispatch
