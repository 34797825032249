import { createSlice } from "@reduxjs/toolkit";
import { RunState } from "./RunStore";

const initialState:  {
    lastUpdatedAt: number
} = { lastUpdatedAt: 0}

export const lastSaveTimeSlice = createSlice({
    name: 'runProcHeader',
    initialState,
    reducers: {
        updateLastUpdatedTime: (state, action) => {
            state.lastUpdatedAt = action.payload
        }
    }

})

export const { 
    updateLastUpdatedTime
} = lastSaveTimeSlice.actions

export default lastSaveTimeSlice.reducer

export const selectLastSavedTime = (state: RunState) => state.lastSavedTimeSlice.lastUpdatedAt