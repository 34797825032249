import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { IMonitorDoctor, IPerson } from "../data/Person";
import { Dispatch, SetStateAction } from "react";

export const getPersonInfoFromString = (x: string) =>  {
    const info = x.split(' ')
    return`${info[0]} ${info[1]} ${info[2]} ${info[3]}`
}

export const getPersonInfo = (x: IPerson) => `${x.honorific} ${x.firstName} ${x.lastName} (${x.eMail})`

export const getEmailsFromPeopleInfo = (people: string[]) => people.map((person: string) => person?.split(' ')[3])

export const isValidList = (l: Array<IMonitorDoctor>) => l && l[0]


export const filterRelevantInfo = (people: IMonitorDoctor [], person: string) => {
    if (!people) return null
    for (const p of people){
        if (getPersonInfo(p) === getPersonInfoFromString(person))
            return getPersonInfoFromString(person)
    }
}


export default function RecepientFC({recepients, selectedNames, handleChangeNames, role}: 
    {recepients: IMonitorDoctor [], selectedNames: string [], handleChangeNames: Dispatch<SetStateAction<string[]>>,
         role: string}){

    const FC_LABEL = `${role}-checkbox-label`

    const handleChange = (event: { target: { value: any; }; }) => {
        const {
          target: { value },
        } = event;
        handleChangeNames(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        )
      }

    const recepientsInfo = recepients.map(getPersonInfo)
    let relevantSelection: string []  = []

    const filterValues = (values: string []) => {
        let relevantValues: string []  = []
        for (const value of values){

            if (recepientsInfo.indexOf(value) > -1)
                relevantValues.push(value)
        }
        return relevantValues 
    }

    return (
        relevantSelection?
    <>
        <FormControl sx={{m: 1, width: 400}}>
            <InputLabel id={FC_LABEL}>{role}</InputLabel>
            <Select
            multiple
            value={filterValues(selectedNames)}
            onChange={handleChange}
            renderValue={
                (selection)=>{ 
                    const res = selection.join(', ')
                    if (res) return res; else return null
                }
            }
            label={role}
            >
            {recepients?.map((r) => (
                <MenuItem key={getPersonInfo(r)} value={getPersonInfo(r)}>
                <Checkbox checked={selectedNames?.indexOf(getPersonInfo(r)) > -1} />
                <ListItemText primary={getPersonInfo(r)} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    </>
    :null)
}