import { Stack, CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

export type TWaitingLabelTargetItem = 'idle' | 'loading' | 'failed' 
export type TWaitingLabelTarget = TWaitingLabelTargetItem | Array<TWaitingLabelTargetItem> 

export default function WaitingLabel(props:{title: ReactNode, target: TWaitingLabelTarget }) {
    let finalTarget:TWaitingLabelTargetItem = 'idle'
    if (typeof props.target === 'object') { // That's array, so we need to iterate
      finalTarget = (props.target as Array<TWaitingLabelTargetItem>)
                      .find(x => x === 'loading') ? 'loading' : 'idle' 
    } else {
      finalTarget = props.target
    }

    return(
    <>
      <Stack direction='row'>
        {props.title}
        <sup>{finalTarget === 'loading' ? <CircularProgress size='16px' /> : ''}</sup>
      </Stack>
    </>)
  }
  