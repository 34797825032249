import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, MenuItem, Stack, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { CloneDevice, CreateDevice } from '../../../data/Device';
import ISimpleDialogProps from '../../../utils/ISimpleDialogProps';
import MessageAlert, { TAlertMessageType } from '../../../utils/MessageAlert';
import { useAppDispatch, useAppSelector } from '../AdminReduxHooks';
import { createDeviceAsync } from '../common/FetchApi';
import { selectAll as selectAllDevices } from "../device/DevicesSlice";
import { selectAll as selectAllLabs } from "../org/LabsSlice";
import { add } from './DevicesSlice';

export default function BetterFormDialog(props: ISimpleDialogProps) {
    const title = 'Add Device'
    const [errorText, setErrorText] = useState<TAlertMessageType>(undefined)
    const labs = useAppSelector(selectAllLabs)
    const devices = useAppSelector(selectAllDevices)
    const [data, setData] = useState(CreateDevice())
    const dispatch = useAppDispatch()

    const showError = (message: string | JSX.Element) => {
        if (typeof message === 'string') {
            setErrorText('Error: ' + message)
        } else {
            setErrorText(message)
        }
        setTimeout(() => {
                setErrorText(undefined) 
            }, 15000)
    }

    const onOkClick = async () => {
        if (verifyFields()) {
            const fullyReady = copyFields()
            const ret = await createDeviceAsync(fullyReady)            
            if (ret !== '') {
                return 'API Error:' + ret
            }            
            dispatch(add(fullyReady))
            props.closeCallback(true)
        }
    }

    const onCancelClick = () => {
        props.closeCallback(false)
    }

    const copyFields = () => {
        const copyTime = data.totalOperatingTime
        const copyDate = data.commissionDate
        const updatedData = CloneDevice(data)
        updatedData.operatingTimeSinceLastService = copyTime
        updatedData.lastServiceDate = copyDate
        return updatedData
    }

    const verifyFields = () => {
        if (data.partitionKey === '') {
            showError('Please select the organization')
            return false
        }
        if (data.label === ''){
            showError('Please enter the label')
            return false
        }
        if (data.commissionDate === ''){
            showError('Please enter the commission date')
            return false
        }
        const dateRe = /^20\d{2}-\d{2}-\d{2}$/;
        if (data.commissionDate.match(dateRe) === null) {
            showError('Malformed date')
            return false
        }
        const date = new Date(data.commissionDate);
        const timestamp = date.getTime();
      
        if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
            showError('Invalid date')
            return false;
        }

        if (data.totalOperatingTime === ''){
            showError('Please enter the time in operation')
            return false
        }
        
        const timeRe = /^\d+:\d{2}$/;
        if (data.totalOperatingTime.match(timeRe) === null) {
            showError('Malformed time in operation')
            return false
        }
        
        const [, mm] = data.totalOperatingTime.split(':')
        if (parseInt(mm) > 59) {
            showError('Minutes value should be between 0 and 59')
            return false
        }

        if (devices.find(x => x.rowKey === data.rowKey) !== undefined) {
            showError(<>Error: The serial # <strong>{data.rowKey}</strong> is already present</>)
            return false
        }
        if (devices.find(x => x.label === data.label) !== undefined ) {
            showError(<>Error: The label <strong>{data.label}</strong> is already present</>)
            return false
        }

        return true
    }

    const isValidDataKey = (x: string): x is keyof typeof data => x in data;

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        const updatedData = CloneDevice(data)
        if (isValidDataKey(id)){
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            updatedData[id] = e.target.value
        }
        if (id === 'partitionKey') {
            updatedData.orgLabel = labs.find(x => x.rowKey === e.target.value)!.label
        }
        setData(updatedData)
    }


    return (
        <Dialog open={props.show} fullWidth maxWidth='md' >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <MessageAlert msg={errorText}/>
                <FormControl fullWidth>
                    <Divider textAlign="left" sx={{ mb: '10px' }}>Lab</Divider>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField select fullWidth required id='partitionKey' name='partitionKey'
                                value={data['partitionKey']}
                                onChange={onChange}
                                label='Select organization'>
                                {labs.filter(x => x.isActive).map(x =>
                                    <MenuItem key={x.rowKey} value={x.rowKey}>
                                        <Stack direction='row'>
                                            {x.label + ','}
                                            <div style={{ fontWeight: 'bold' }} >&nbsp;&nbsp;Full Name:&nbsp;</div>{x.fullName}
                                        </Stack>

                                    </MenuItem>)}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Divider textAlign="left" sx={{ mb: '10px', mt: '32px' }}>Device</Divider>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField fullWidth label='Serial# (YYMMDDNN)'
                                value={data['rowKey']} id='rowKey' onChange={onChange}
                                required />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label='Label'
                                value={data['label']} id='label' onChange={onChange}
                                required />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField  fullWidth label='Commission Date (YYYY-MM-DD)' hiddenLabel
                                value={data['commissionDate']} id='commissionDate' onChange={onChange}
                                required/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label='Time in operation since commission (HH:MM)'
                                    value={data['totalOperatingTime']} id='totalOperatingTime' onChange={onChange} 
                                    required/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label='Notes'
                                value={data['notes']} id='notes' onChange={onChange}
                                />
                        </Grid>
                    </Grid>
                </FormControl>


            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button onClick={onOkClick}>OK</Button>
            </DialogActions>
        </Dialog>)
}