import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IBasicEntityBase } from "../data/Common"

export interface IPredictionState {
    data: any | null, //IPrediction | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IPredictionState = {
    data: null,
    status: 'idle'
}

export const setPredictionAsync = createAsyncThunk(
    'report/setPrediction',

    async (identity: IBasicEntityBase) => {
        const requestUrl = `/api/generatePrediction?partitionKey=${identity.partitionKey}&rowKey=${identity.rowKey}`

        const response = await fetch(requestUrl  , {
            method: 'GET',
            //headers: { 'Content-Type': 'application/json'},
            //body: JSON.stringify(preferences)
        })

        if (response.ok) {
            const responseBody = await response.json()
            return responseBody
            // return response.ok
        } else {
            const responseBody = await response.json()
            console.log(`Error in intializing prediction data from ${identity.partitionKey}: ${identity.rowKey}. Status: ${response.status}. Body: ${responseBody}`)
            return null
        }
    }
)

export const predictionSlice = createSlice({
    name: 'prediction',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(setPredictionAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(setPredictionAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload!
            })
    }
})

export const selectPrediction = (state: { predictionSlice:  IPredictionState  }) => state.predictionSlice.data
export const selectPredictionStatus = (state: { predictionSlice: IPredictionState }) => state.predictionSlice.status

export default predictionSlice.reducer