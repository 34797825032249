import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import { Box, CircularProgress, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import getDateFromMilliseconds from '../../common/getDateFromMilliseconds'
import { IProcHeader } from '../../data/Procedure'
import { ResizableTable } from '../../utils/ResizableTable'
import { useNavigate, useOutletContext } from 'react-router-dom'

export async function getArchiveAsync() {
    const response = await fetch('/api/getArchive?role=doctor')
    return response.ok ? await response.json() : response.statusText
}

// Note: we don't use Redux here since we don't want to cache archived procedures. It would be a waste of memory.
export default function DocArchive() {
    const [archive, setArchive] = useState<IProcHeader[] | null>(null)
    const [showProgress, setShowProgress] = useState(true)
    
    useEffect(() => {
        const fetchData = async () => {
            setShowProgress(true)
            const fetchResult = await getArchiveAsync()
            if (typeof fetchResult !== 'string') {
                setArchive(fetchResult)
            }
            setShowProgress(false)
        }

        fetchData()
        return () => setArchive(null)
    }, [])

    if (showProgress) {
        return <CircularProgress size='96px' sx={{ position: 'absolute', top: '45%', left: '43%', transform: 'translate(-45%, -43%)' }} />
    } else {
        return (
            <>
                {archive === null
                    ? 'No data'
                    : <ResizableTable header={<Header />} body={<Body rows={archive} />} />}
            </>)
    }
}

const linkWidth = '36px'
const deviceWidth = '150px'
const startedAtWidth = '300px'
const durationWidth = '300px'
const evlpCaseWidth = '150px'
const lungsWidth = '100px'

export function Header() {
    return (
        <Fragment>
            <Stack direction='row'>
                <Typography variant='h6' width={linkWidth}> &nbsp; </Typography>
                <Typography variant='h6' width={evlpCaseWidth}> EVLP ID</Typography>
                <Typography variant='h6' width={lungsWidth}>Lungs</Typography>
                <Typography variant='h6' width={deviceWidth}>Perform on</Typography>
                <Typography variant='h6' width={startedAtWidth}>Started at (UTC)</Typography>
                <Typography variant='h6' width={durationWidth}>Duration</Typography>
            </Stack>
            <Divider />
        </Fragment>
    )
}

function lungsDecision(d: IProcHeader) {
    if (d.disposition === 'finished' && d.isLungsAccepted) {
        return (
            <Box sx={{color:'green', fontWeight:'bold'}}>Accepted</Box>
        )
    } else if (d.disposition === 'finished' && !(d.isLungsAccepted)) {
        return (
            <Box sx={{color:'orange', fontWeight:'bold'}}>Declined</Box>
        )
    } else { // it is logically an abandoned procedure
        return (
            <Box sx={{color:'lightgrey'}}>N/A</Box>
        )
    }
}

function Body({ rows }: { rows: IProcHeader[] }) {
    type ContextType = { reportUrl: string, updateUrl: (url: string) => void, updateTab: (num: number) => void };

    const {updateUrl, updateTab} =  useOutletContext<ContextType>()//useReportUrl()()
    const navigate = useNavigate()
    const onReportClick =  (reportUrlParam: string) => {
        updateUrl(reportUrlParam)
        updateTab(2)
        navigate('../report')
    }

    return (
        <>  
        {rows.map(d => 
            <Stack direction='row' key={d.rowKey}>
                <Typography width={linkWidth}> <IconButton sx={{mt:'-5px'}} 
                    onClick={() => {
                    onReportClick(`/api/getReportFromArchive?partitionKey=${d.partitionKey}&rowKey=${d.rowKey}`)
                    }
                    }>
                        <SummarizeOutlinedIcon fontSize='small'/>
                    </IconButton> </Typography>
                    <Typography width={evlpCaseWidth}>{d.evlpCaseNumber}</Typography>
                    <Typography width={lungsWidth}>{lungsDecision(d)}</Typography>
                <Typography width={deviceWidth}>{d.performedOn}</Typography>
                <Typography width={startedAtWidth}>{getDateFromMilliseconds(d?.startTime, 'UTC')}</Typography>
                <Typography width={durationWidth}>{
                    getDateFromMilliseconds(
                        d === null || d.currentTime === undefined || d.startTime === undefined
                            ? undefined
                            : d.currentTime - d.startTime, 'duration')}</Typography>
            </Stack>
        )}
        </>
    )
}