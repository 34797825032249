import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { BloodTypes, DonorTypes, IProcHeader, TLung, TSex } from '../../data/Procedure';
import { EVLPIndicationsDataDictionary } from '../../data/ddEVLPIndications';
import AutoScrollContainer from '../../utils/AutoScrollContainer';
import { useAppDispatch, useAppSelector } from './RunReduxHooks';
import TestSettingDialog from './TestSettingsDialog';
//import AdapterDateFns from '@mui/material/AdapterDateFns';


import {
    ISetBoolean, selectProcHeader, setBoolean,
    setEvlpCaseNumber,
    setEvlpKitNumber,
    setOtherIndications,
    setPerfusionist,
    setgender, setheightcm, setlung, setAge,
    setweightkg,
    setBloodType,
    setDonorType
} from './sliceRunProcHeader';
import NumericInput from '../../utils/NumericInput';
import { MaxAge, MinAge } from '../tech/current/AddProcDialog';
// import { selectProfile } from '../../common/ProfileSlice';

const MinHeight = 100
const MaxHeight = 230
const MinWeight = 10
const MaxWeight = 250


export const rampUp = (data: IProcHeader | null) => {
    return (
        <FormControl sx={{ width: '100%', mt: '15px' }}>
            <b>Ramp Up Parameters</b>
            <Divider />
            <Grid container spacing={1} sx={{ mt: '5px' }}>
                <Grid item xs={2}>
                    <b>Perfusion time (h:mm)</b>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>0:00</Grid>
                        <Grid item xs={2}>0:10</Grid>
                        <Grid item xs={2}>0:20</Grid>
                        <Grid item xs={2}>0:30</Grid>
                        <Grid item xs={2}>0:40</Grid>
                        <Grid item xs={2}>0:50</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <b>Flow Rate (ml/min)</b>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>{(data!.rampUpParameters!['0:00'] * 1000).toFixed(0)}</Grid>
                        <Grid item xs={2}>{(data!.rampUpParameters!['0:10'] * 1000).toFixed(0)}</Grid>
                        <Grid item xs={2}>{(data!.rampUpParameters!['0:20'] * 1000).toFixed(0)}</Grid>
                        <Grid item xs={2}>{(data!.rampUpParameters!['0:30'] * 1000).toFixed(0)}</Grid>
                        <Grid item xs={2}>{(data!.rampUpParameters!['0:40'] * 1000).toFixed(0)}</Grid>
                        <Grid item xs={2}>{(data!.rampUpParameters!['0:50'] * 1000).toFixed(0)}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FormControl>
    )
}

export default function UIClinicalData() {
    const dispatch = useAppDispatch()
    const data = useAppSelector(selectProcHeader)
    const [testOpen, setTestOpen] = useState(false)
    // const [haveCrossClampTime, setHaveCrossClampTime] = useState(true)//useState(Boolean(data?.crossClampDT))
    // const [tempCCDT, setCCDT] = useState<dayjs.Dayjs|null>(dayjs(Date.now())) 




    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setgender((event.target as HTMLInputElement).value as TSex))
    }

    const handleNumberChange = (n: number) => {
        dispatch(setAge(n))
    }

    const handleLungChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setlung((event.target as HTMLInputElement).value as TLung))
    }

    const handleHeightChange = (n: number | undefined, e:React.ChangeEvent<HTMLInputElement>) => {
        if (n) {
            dispatch(setheightcm(n))
        }
    }

    const handleWeightChange = (n: number | undefined, e:React.ChangeEvent<HTMLInputElement>) => {
        if (n) {
            dispatch(setweightkg(n))
        }
    }

    const handleBloodTypeChange = (n: any) => {
        if (n) {
            dispatch(setBloodType(n))
        }
    }

    const handleDonorTypeChange = (n: any) => {
        if (n) {
            dispatch(setDonorType(n))
        }
    }

    /*
    const onCCDTChange: DateTimePickerProps<dayjs.Dayjs>['onChange'] = (date) => {
        setCCDT(date)
        if (date) {
            const ud = { ...data }
            ud.crossClampDT = (date?.toDate()).getTime()
            //setD(ud)
        }

    };*/

    const handleIndicationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setOtherIndications((event.target as HTMLInputElement).value))
    }

    const emptyTextFieldErrorText = 'Please enter the data'
    const [evlpKitError, setEvlpKitError]
        = useState(data!.evlpKitNumber === undefined || data!.evlpKitNumber === '' ? emptyTextFieldErrorText : '')

    const [evlpCaseNumberError, setEvlpCaseNumberError]
        = useState(data!.evlpCaseNumber === '' ? emptyTextFieldErrorText : '')

    useEffect(() => {
        setEvlpKitError(data!.evlpKitNumber === undefined
            || data!.evlpKitNumber === ''
            ? emptyTextFieldErrorText
            : '')
            setEvlpCaseNumberError(data!.evlpCaseNumber === ''
                ? emptyTextFieldErrorText
                : '')
        }, [data])

    const handlEvlpKitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setEvlpKitNumber((event.target as HTMLInputElement).value))
    }

    const handleEvlpCaseNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setEvlpCaseNumber((event.target as HTMLInputElement).value))
    }

    const handlePerfusionistChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setPerfusionist((event.target as HTMLInputElement).value))
    }

    const handleBooleanChange = (e: ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        try {
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            if (e.target.type === 'checkbox') {
                const x: ISetBoolean = { item: id, value: e.target.checked }
                dispatch(setBoolean(x))
            }
        } catch (ex) {
            console.log('Error trying to assign value', ex)
        }
    }


    

    const ventilation = () => {
        return (
            <Box sx={{ mt: '15px' }}>
                <b>Ventilation parameters</b>
                <Divider />
                <Grid container rowSpacing={2} spacing={4} sx={{ mt: '0px' }}>
                    <Grid item xs={4}>
                        <FormControl>
                            <FormLabel>Steady-State Ventilation Tidal Volume (ml)</FormLabel>
                            <TextField variant='standard' value={data!.steadyStateVV_mL!.toFixed(0)} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <FormLabel>Assessment Ventilation Tidal Volume (ml)</FormLabel>
                            <TextField variant='standard' value={data!.assessmentVV_mL!.toFixed(0)} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <FormLabel>Recruitment Ventilation Tidal Volume (ml)</FormLabel>
                            <TextField variant='standard' value={data!.recruitmentVV_mL!.toFixed(0)} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <FormLabel>PEEP (cm H<sub>2</sub>O)</FormLabel>
                            <TextField variant='standard' value={data!.PEEP_cmH20!.toFixed(0)} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <FormLabel>Respiratory Rate (bpm)</FormLabel>
                            <TextField variant='standard' value={data!.respiratoryRate_bpm!.toFixed(0)} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl>
                            <FormLabel>FiO<sub>2</sub></FormLabel>
                            <TextField variant='standard' value={data!.FiO2_Percent + '%'} />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const indications = () => {
        return (
            <>
                <b>EVLP Indications</b>
                <Divider />
                <Grid container spacing={1} sx={{ mt: '2px', mb: '0px' }} rowSpacing={'0px'} >
                    <Grid item xs={4}>
                        <FormControlLabel control={<Checkbox id='isConsernsOfAspiration' checked={data!.isConsernsOfAspiration} onChange={handleBooleanChange} />}
                            label={EVLPIndicationsDataDictionary.isConsernsOfAspiration.label} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel control={<Checkbox id='isLowDonorPO2' checked={data!.isLowDonorPO2} onChange={handleBooleanChange} />}
                            label={EVLPIndicationsDataDictionary.isLowDonorPO2.label} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel control={<Checkbox id='isFromMarginalDCD' checked={data!.isFromMarginalDCD} onChange={handleBooleanChange} />}
                            label={EVLPIndicationsDataDictionary.isFromMarginalDCD.label} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel control={<Checkbox id='isHighRiskHistory' checked={data!.isHighRiskHistory} onChange={handleBooleanChange} />}
                            label={EVLPIndicationsDataDictionary.isHighRiskHistory.label} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel control={<Checkbox id='isPresenceOfEndema' checked={data!.isPresenceOfEndema} onChange={handleBooleanChange} />}
                            label={EVLPIndicationsDataDictionary.isPresenceOfEndema.label} />                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel control={<Checkbox id='isPoorCompliance' checked={data!.isPoorCompliance} onChange={handleBooleanChange} />}
                            label={EVLPIndicationsDataDictionary.isPoorCompliance.label} />
                    </Grid>
                    <Grid item xs={11}>
                        <FormControl fullWidth>
                            <FormLabel>Other indications</FormLabel>
                            <TextField
                                value={data!.otherIndications} onChange={handleIndicationsChange} />
                        </FormControl>
                    </Grid>

                </Grid>
            </>
        )
    }

    /*
    const [haveCrossClampTime, setHaveCrossClampTime] = useState(true)
    const [tempCCDT, setCCDT] = useState<dayjs.Dayjs|null>(dayjs(Date.now())) 
    const profile = null //useAppSelector(selectProfile)

    const onSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        const ud = { ...d }
        try {
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            ud[id] = e.target.value
            recalculateDerivedData(ud)
        } catch (ex) {
            console.log('Error trying to assign selected value',ex)
        }
        //setD(ud)
    }

    function calculatePtlc(sex:TSex, height_cm: number, age_years: number) {
        if (sex === 'male') {
            return  Math.round((0.08 * height_cm + 0.003 * age_years - 7.333) * 100) / 100 
        } else {
            return Math.round((0.059 * height_cm - 4.537) * 100) / 100
        }
    
    }

    const recalculateDerivedData = (ud: IProcHeader) => {
        ud.bodyMassIndex = Math.round(10 * ud.weight_Kg / (ud.height_cm * ud.height_cm / 10000)) / 10
        // From Run
        ud.idealBodyWeight_Kg = Math.round((ud.height_cm * ud.height_cm / 10000)
                                        * (ud.sex === 'male' ? 23 : 22) * 10) / 10
        ud.cardiacOutput_LPmin = Math.sqrt(((ud.height_cm * ud.idealBodyWeight_Kg) / 3600)) * 2.4
        ud.recordCreatedAt = Date.now() // to preserve sort order after adding to the list
        ud.pTLC = calculatePtlc(ud.sex, ud.height_cm, ud.age_years)
    }

    const onNumberChange = (n: number | undefined, e:React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        const ud = { ...d }
        try {
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            ud[id] = n ? n : ud[id]
            recalculateDerivedData(ud)
        } catch (ex) {
            console.log('Error trying to assign value',ex)
        }
        setD(ud)
    }

    
    const initialData: IProcHeader = {
        evlpCaseNumber: '',
        perfusionist: '',
        lastUpdatedAt: 0,
        lung: 'both',
        age_years: DEFAULT_AGE_YEARS,
        sex: DEFAULT_SEX,
        height_cm: DEFAULT_HEIGHT_CM,
        weight_Kg: DEFAULT_WEIGHT_KG,
        cardiacOutput_LPmin: 0,
        bodyMassIndex: 0,
        idealBodyWeight_Kg: 0,
        donorType: 'unspecified',
        bloodType: 'unspecified',
        crossClampDT: Date.now(),
        performedOn: '',
        initiatedBy: profile === undefined ? '' : profile!.person.eMail,
        performedBy: '',
        notify: '',
        partitionKey: profile!.org.rowKey,
        rowKey: crypto.randomUUID(),
        step: 'Procedure',
        disposition: 'init',
        startTime: 0,
        currentTime: 0,
        evlpKitNumber: '',
        isLungsAccepted: false,
        timeZone: profile!.org.timeZone,
        pTLC: calculatePtlc(DEFAULT_SEX, DEFAULT_HEIGHT_CM, DEFAULT_AGE_YEARS)

    }

    const [d, setD] = useState({ ...initialData })

    const donorInfo = () => {
        return (
            <Box sx={{ mt: '15px' }}>
                <FormControl>
                <strong>Donor information</strong>
                    <Grid container spacing={1} sx={{mt:'2px', mb:'10px'}}>
                        <Grid item xs={4}>
                            <Stack direction={'row'} alignItems="center">
                                <DateTimeField disabled={!haveCrossClampTime} fullWidth value={haveCrossClampTime?tempCCDT:null} label='Cross clamp date/time' format='YYYY-MM-DD HH:mm' onChange={onCCDTChange} />
                                <Checkbox onClick={()=>{
                                    setHaveCrossClampTime(!haveCrossClampTime)
                                }} />
                                <Typography>N/A</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth select name='donorType' label='Donor Type' value={d.donorType} onChange={onSelectionChange}>
                                {DonorTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth select name='bloodType' label='Donor Blood Type' value={d.bloodType} onChange={onSelectionChange}>
                                    {BloodTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput fullWidth id='age_years'
                                variant='outlined'
                                step={1}
                                label='Age (years)' 
                                initValue={d.age_years} 
                                onNumberChanged={(n, e) => onNumberChange(n, e)} 
                                min={MinAge}
                                max={MaxAge}/>
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput fullWidth id='height_cm'
                                variant='outlined'
                                step={1}
                                label='Height (cm)' 
                                initValue={d.height_cm} 
                                onNumberChanged={(n, e) => onNumberChange(n, e)} 
                                min={MinHeight}
                                max={MaxHeight}/>
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput fullWidth id='weight_Kg'
                                variant='outlined'
                                step={.1}
                                label='Weight (kg)' 
                                initValue={d.weight_Kg} 
                                onNumberChanged={(n, e) => onNumberChange(n, e)} 
                                min={MinWeight}
                                max={MaxWeight}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField select fullWidth name='sex' label='Sex' required value={d.sex} onChange={onSelectionChange}>
                                {SexTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth id='CO_lpm' label='Cardiac Output (lpm)' value={d.cardiacOutput_LPmin?.toFixed(2)} disabled />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth id='pTLC' label='pTLC' value={d.pTLC} disabled />
                        </Grid>
                    </Grid>
                </FormControl>
            </Box>
        )
    }
    */
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.altKey && event.ctrlKey && event.key === 't') {
            setTestOpen(true)
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

   

    return (
        <AutoScrollContainer bottomOffset={70}>
            <Grid container rowSpacing={1} spacing={1} sx={{ p: '5px' }}>
                <Grid item xs={4}>
                    <FormControl fullWidth required>
                        <FormLabel>EVLP ID</FormLabel>
                        <TextField variant='standard'
                            value={data!.evlpCaseNumber} onChange={handleEvlpCaseNumberChange}
                            error={evlpCaseNumberError !== ''} helperText={evlpCaseNumberError} />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth required>
                        <FormLabel>EVLP Kit Serial Number</FormLabel>
                        <TextField variant='standard'
                            value={data!.evlpKitNumber} onChange={handlEvlpKitChange}
                            error={evlpKitError !== ''} helperText={evlpKitError} />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <FormLabel>Perfusionist</FormLabel>
                        <TextField variant='standard'
                            value={data!.perfusionist} onChange={handlePerfusionistChange} />
                    </FormControl>
                </Grid>
                <Grid item xs={3} >
                    <FormLabel>Procedure Type</FormLabel>
                    <RadioGroup value={data!.lung} onChange={handleLungChange} row>
                        <FormControlLabel value='left' control={<Radio />} label='Left' />
                        <FormControlLabel value='right' control={<Radio />} label='Right' />
                        <FormControlLabel value='both' control={<Radio />} label='Both' />
                    </RadioGroup>
                </Grid>
                
                <Grid item xs={12}>

                    <b>Donor Information</b>
                    <Divider />

                    <Grid container spacing={1} sx={{ mt: '2px', mb: '0px' }} rowSpacing={'0px'} >

                        <Grid item xs={3} >
                            <FormLabel>Donor Sex</FormLabel>
                            <RadioGroup name='gender' value={data!.sex} onChange={handleGenderChange} row>
                                <FormControlLabel value='male' control={<Radio />} label='Male' />
                                <FormControlLabel value='female' control={<Radio />} label='Female' />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={3}>
                            <NumericInput fullWidth id='age_years'
                                variant='standard'
                                step={1}
                                label='Age (years)' 
                                initValue={data!.age_years} 
                                onNumberChanged={(n) => handleNumberChange(n)} 
                                min={MinAge}
                                max={MaxAge}/>
                        </Grid>

                        <Grid item xs={3} >
                            <FormControl fullWidth>
                                <FormLabel>Donor Height (cm)</FormLabel>
                                <NumericInput  fullWidth id='height_cm'
                                    variant='standard'
                                    step={1}
                                    initValue={data!.height_cm}
                                    onNumberChanged={(n, e) => handleHeightChange(n, e)}
                                    min={MinHeight}
                                    max={MaxHeight}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel>Donor Weight (kg)</FormLabel>
                                <NumericInput fullWidth id='weight_Kg'
                                        variant='standard'
                                        step={.1}
                                        initValue={data!.weight_Kg!} 
                                        onNumberChanged={(n, e) => handleWeightChange(n, e)} 
                                        min={MinWeight}
                                        max={MaxWeight}/>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={3}>
                            <TextField fullWidth select name='bloodType' label='Donor Blood Type' value={data!.bloodType} onChange={(e) => handleBloodTypeChange(e.target.value)}>
                                {BloodTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                            </TextField>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField fullWidth select name='donorType' label='Donor Type' value={data!.donorType} onChange={(e) => handleDonorTypeChange(e.target.value)}>
                                {DonorTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                            </TextField>
                        </Grid>
                    </Grid>


                </Grid>


                <Grid item xs={12}>
                    {indications()}
                </Grid>
                <Grid item xs={12}>
                    {rampUp(data)}
                </Grid>
                <Grid item xs={12}>
                    {ventilation()}
                </Grid>
            </Grid>
            <TestSettingDialog show={testOpen} closeCallback={() => setTestOpen(false)} key={Math.random()} />
        </AutoScrollContainer>
    )
}


