import { IEntityBase } from './Common'
import { IPerson } from './Person'

export const consNames = ['cons0', 'cons1', 'cons2', 'cons3', 'cons4', 'cons5', 'cons6', 'cons7', 'cons8', 'cons9']
export interface IOrgData extends HospitalEntity{
    label: string
    fullName: string
    contact: string
    timeZone?:string
    cons0?: string
    cons1?: string
    cons2?: string
    cons3?: string
    cons4?: string
    cons5?: string
    cons6?: string
    cons7?: string
    cons8?: string
    cons9?: string
}

export interface IOrg extends IEntityBase, IOrgData {
}

export interface ICreateOrgPayload {
    org: IOrg
    contact: IPerson
}


export interface HospitalEntity  {
    insighTxPermission?: boolean;
}

