import { createSlice } from '@reduxjs/toolkit'
import { createGetDataThunk, createInitialProcDataState } from '../../common/procSliceCommons'
import { convertToGraphData, transposeData } from '../../common/transposeData';
import { MonitorState } from './monitorStore';

export const getProcDataAsync = createGetDataThunk('monitorData/fetchProcData')
const initialState = createInitialProcDataState();

export const dataSlice = createSlice({
    name: 'monitorData',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProcDataAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getProcDataAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload!
                state.transposed = transposeData(action.payload!)
                state.chartData = convertToGraphData(action.payload!)
            })
    }
})

export const selectData = (state: MonitorState) => state.dataSlice.data
export const selectTransposed = (state: MonitorState) => state.dataSlice.transposed
export const selectChartData = (state: MonitorState) => state.dataSlice.chartData
export const selectStatus = (state: MonitorState) => state.dataSlice.status

export default dataSlice.reducer
