import { TDataField } from '../data/ProcData';

export const ReportRows: (TDataField)[] = [
    'PAPressure',
    'LAPressure',
    'PeakAWP',
    'MeanAWP',
    'PlateauAWP',
    'DynamicCompliance',
    'StaticCompliance',
    'LAGlucose',
    'LALactate',
    'PerfusateLoss',
    'DeltaPF',
    'DeltaPFRUV',
    'DeltaPFRLV',
    'DeltaPFLUV',
    'DeltaPFLLV'
]
