import { Grid, LinearProgress, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { getNextStep, getStepIndex, RampupSteps, TSetupStep, TSteadyStep, TStep } from '../../data/WorkflowSteps'
import { useAppSelector } from './RunReduxHooks'
import { selectStepCompletionPercent } from './sliceRunProcHeader'
import { selectSetupState } from './sliceSetup'

export function WSRampup() {
    const {step} = useParams()
    const stepIndex = getStepIndex(step as TStep) - getStepIndex('Start') - 1
    const displaySteps = [...RampupSteps,'0:55']
    const persentage = useAppSelector(selectStepCompletionPercent)
    const progressVariant = persentage === -1 ? 'indeterminate' : 'determinate'
    return (
        <Stack>
            <Stepper activeStep={stepIndex}>
                {displaySteps.map(item => {
                    const stepProps: { completed?: boolean } = {}
                    const labelProps: { optional?: React.ReactNode } = {}
                    return (
                        <Step key={item} {...stepProps}>
                            <StepLabel {...labelProps}><Typography variant='h6'>{item}</Typography></StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <LinearProgress sx={{mt:1, mb:2}} variant={progressVariant} value={persentage} />
        </Stack>
    )
}

export function WSSteady() {
    const {step} = useParams()
    const currentStep = step as TSteadyStep
    const nextStep = getNextStep(currentStep)
    const persentage = useAppSelector(selectStepCompletionPercent)
    const progressVariant = nextStep !== '99:30' ? 'determinate' : 'indeterminate'
    return (
        <Stack>
            <Grid container spacing={0}>
                <Grid item xs={1}>
                    <Typography variant='h6'>{currentStep}</Typography>
                </Grid>
                <Grid item xs={10}>
                    <LinearProgress sx={{mt:2, mb:2}} variant={progressVariant} value={persentage} />
                </Grid>
                <Grid item xs={1} sx={{textAlign:'right'}}>
                <Typography variant='h6'>{nextStep}</Typography>
                </Grid>
            </Grid>
        </Stack>
    )
}

export function WSFinished() {
    return null
}

export function WSSetup() {
    const {step} = useParams()
    const setupState = useAppSelector(selectSetupState)
    const stepIndex = getStepIndex(step as TSetupStep)
    return (
        <Stepper activeStep={stepIndex}>
            {Object.entries(setupState).map(([key, section]) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: { optional?: React.ReactNode } = {}
                return (
                    <Step key={key} {...stepProps}>
                        <StepLabel {...labelProps}><Typography variant='h6'>{section.label}</Typography></StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )
}