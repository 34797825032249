import { useOutletContext } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../TechReduxHooks";
import { Box, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import MessageBox from "../../../utils/MessageBox";
import { getArchivedDoctorsAsync, selectDoctors } from "./sliceArchivedDoctors";
import { IMonitorDoctor, IPreferences } from "../../../data/Person";
import { selectTechs, getTechsAsync} from '../../../common/sliceTechs'
import RecepientFC, { filterRelevantInfo, getEmailsFromPeopleInfo, getPersonInfo, isValidList } from "../../../common/RecipientFormControl";
import { getUserEmail, selectPreferences, setPreferencesAsync, setSend } from "../../../common/slicePreferences";

type ContextType = { reportUrl: string, updateUrl: (url: string) => void, updateTab: (num: number) => void };

export default function ArchivedReportTech(){

    const {reportUrl} =  useOutletContext<ContextType>()
    const partitionKey = reportUrl.split('?')[1].split('&')[0].split('=')[1]
    const rowKey = reportUrl.split('?')[1].split('&')[1].split('=')[1]

    const dispatch = useAppDispatch()
    const [showSent, setShowSent] = useState(false)

    const [preferences, setPreferences] = useState( useAppSelector(selectPreferences))

    const techs: IMonitorDoctor[] = useAppSelector(selectTechs)
    const doctors: IMonitorDoctor[] | null = useAppSelector(selectDoctors)

    let docs: IMonitorDoctor[] = []
    let cons: IMonitorDoctor[] = []

    if (doctors){
        docs = doctors!.filter(x => !(x.isConsultant))
        cons = doctors!.filter(x => x.isConsultant) 
    }

    let [docsName, setDocsName] = useState<string[]>([]);
    const [consName, setConsName] = useState<string[]>([]);
    const [techsName, setTechsName] = useState<string[]>([]);

    useEffect(() => {
            dispatch(getArchivedDoctorsAsync({partitionKey: partitionKey, rowKey: rowKey}))
            dispatch(getTechsAsync( partitionKey ))

        }, [partitionKey, rowKey, dispatch])

    useEffect(() => {
        const docs = doctors!.filter(x => !(x.isConsultant))
        const cons = doctors!.filter(x => x.isConsultant)

        let docs_info, cons_info, techs_info

        if (preferences){
            if (preferences.docs){
                docs_info =  preferences.docs.map((doc: string) => filterRelevantInfo(docs, doc))
                if (isValidList(docs_info))
                    setDocsName(docs_info)
            }
            if(preferences.cons){
                cons_info =  preferences.cons.map((con: string) => filterRelevantInfo(cons, con))
                if (isValidList(cons_info))
                    setConsName(cons_info)
            }
            if(preferences.techs.length){
                techs_info =  preferences.techs.map((tech: string) => filterRelevantInfo(techs, tech))
                if (isValidList(techs_info))
                    setTechsName(techs_info)
            }
        }

        if (techs){
            if (!preferences || !(isValidList(docs_info) || isValidList(cons_info) || isValidList(techs_info))) {
                const techs_info = techs.map((tech) => getPersonInfo(tech))
                if (techs_info && techs_info[0]){
                    setTechsName(techs_info)
                }
            }
        }
    }, [preferences, doctors, techs])


    const executeSend = async () => {

        let sendAddresses = ''
        const allPeople = getEmailsFromPeopleInfo(docsName.concat(consName, techsName))
      
        allPeople?.forEach((x) => {    
                sendAddresses += x.slice(1,-1) 
                sendAddresses += ','
            }
        )
        sendAddresses = sendAddresses.slice(0, -1)

        const sendApi = `/api/sendReportFromArchive?partitionKey=${partitionKey}&rowKey=${rowKey}&email=${sendAddresses}`
        await fetch(sendApi)
        setShowSent(true)
        
        const newPreferences: IPreferences = {docs: docsName, cons: consName, techs: techsName}
        setPreferences(newPreferences)
        dispatch(setSend(newPreferences))

        const table = 'tech'
        
        getUserEmail().then((eMail) => {
            dispatch(setPreferencesAsync({table, eMail, preferences: newPreferences}))
        })

    }

    return (
        <>
        {doctors && techs?
            <>
                <Box border={4} 
                    borderLeft={0}
                    borderRight={0}
                    borderColor= "silver">

                    <Typography variant='h5'> Recipients </Typography>

                    <RecepientFC recepients={docs} 
                                selectedNames={docsName} 
                                handleChangeNames={setDocsName}
                                role='Surgeons'/> 
                    
                    <RecepientFC recepients={cons} 
                                selectedNames={consName} 
                                handleChangeNames={setConsName}
                                role='Consultants'/> 

                    <RecepientFC recepients={techs} 
                                selectedNames={techsName} 
                                handleChangeNames={setTechsName}
                                role='Perfusionists'/> 

                    <br/>

                    <Button disabled={!(docsName.length + consName.length + techsName.length)} onClick={executeSend}>Send report to the selected recipients</Button>
                        <MessageBox open={showSent}
                            buttonsType='MB_OK'
                            message='Report sent!'
                            callback={() => setShowSent(false)}
                            severity='info' />

                    <iframe
                        style={{ border: 10, borderColor: 'solid red', color: 'green' }}
                        id='frame'
                        title='Report'
                        width='100%'
                        height={800}
                        src={reportUrl}
                        />
                </Box>
            </>

        :null}
        </>
    )
}