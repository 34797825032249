import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { ReactElement, ReactNode, useState } from 'react'

export type TAdditionalNumericInputProps = {
    initValue?:number
    unit?: ReactElement | string
    step: number
    min?: number
    max?: number
    minRecomended?: number
    maxRecomended?: number
    errorOnEmptyInit?:boolean
    onNumberChanged?: (newValue: number, event: React.ChangeEvent<HTMLInputElement>) => void
}

export type TNumericInputProps = TextFieldProps & TAdditionalNumericInputProps

type TFieldCondition = {
    error: boolean
    helperText?: ReactNode
    startAdormnet?: ReactNode
}


export default function NumericInput(props: TNumericInputProps) {
    const [inputBuffer, setInputBuffer] = useState(props.initValue ? (props.initValue as number).toString() : '')
    const [fieldCondition, setFieldCondition] = useState<TFieldCondition>(
        props.errorOnEmptyInit && inputBuffer === '' 
            ? {error: true, helperText: 'Please enter the data', startAdormnet: <ErrorOutlineOutlinedIcon fontSize='small' color='error' /> } 
            : { error: false })
    
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputBuffer(event.target.value)
        const fieldValue = Number.parseFloat(event.target.value)
        if (Number.isNaN(fieldValue)) {
            setFieldCondition({
                error: true,
                helperText: `Invalid input.`,
                startAdormnet: <ErrorOutlineOutlinedIcon fontSize='small' color='error' />
            })
            return;
        } else {
            setFieldCondition({ error: false })
            if (props.min !== undefined && fieldValue < props.min) {
                setFieldCondition({
                    error: true,
                    helperText: <>{`The value is less than allowed minimum ${props.min} `} {props.unit}.</>,
                    startAdormnet: <WarningAmberOutlinedIcon fontSize='small' color='error' />
                })
            } else if (props.max !== undefined && fieldValue > props.max) {
                setFieldCondition({
                    error: true,
                    helperText: <>{`The value is greater than allowed maximum ${props.max} `} {props.unit}.</>,
                    startAdormnet: <ErrorOutlineOutlinedIcon fontSize='small' color='error' />
                })
            }  else if (props.minRecomended !== undefined && fieldValue < props.minRecomended) {
                setFieldCondition({
                    error: false,
                    helperText: props.maxRecomended 
                        ? <>{`Recommended value is between ${props.minRecomended} and ${props.maxRecomended} `} {props.unit}.</>
                        : <>{`Recommended value is greater than ${props.minRecomended} `} {props.unit}.</>,
                    startAdormnet: <WarningAmberOutlinedIcon fontSize='small' color='warning' />
                })
            } else if (props.maxRecomended !== undefined && fieldValue > props.maxRecomended) {
                setFieldCondition({
                    error: false,
                    helperText: props.minRecomended
                        ? <>{`Recommended value is between ${props.minRecomended} and ${props.maxRecomended} `} {props.unit}.</>
                        : <>{`Recommended value is less than ${props.maxRecomended} `} {props.unit}. </>,
                    startAdormnet: <WarningAmberOutlinedIcon fontSize='small' color='warning' />
                })
            }

            //Floating numbers aren't presise. We're dealing only with steps .1 and .01
            const multiplier =
                props.step >= 1
                    ? 1
                    : props.step * 10 >= 1
                        ? 10
                        : 100
            if ((Math.floor(((multiplier * ((fieldValue as number) ?? props.step)) / (multiplier * props.step))) * (multiplier * props.step)) !== multiplier * (fieldValue as number)) {
                setFieldCondition({
                    error: true, helperText: `Should be divisible by ${props.step}.`,
                    startAdormnet: <WarningAmberOutlinedIcon fontSize='small' color='warning' />
                })
            }

            if (props.onNumberChanged) {
                props.onNumberChanged(fieldValue, event)
            }
        }
    }

    const adjustedProps = {...props}
    delete adjustedProps.minRecomended
    delete adjustedProps.maxRecomended
    delete adjustedProps.onNumberChanged
    delete adjustedProps.initValue


    return (
        <TextField type="number"
            variant='standard'
            {...adjustedProps}
            value={inputBuffer}
            onChange={handleChange}
            InputProps={
                {
                    endAdornment: <InputAdornment position="end">{props.unit}</InputAdornment>,
                    startAdornment: fieldCondition.startAdormnet
                }
            }
            error={fieldCondition.error}
            helperText={fieldCondition.helperText}
            inputProps={{
                style: { textAlign: 'right' },
                maxLength: 130,
                step: props.step,
                min: props.min,
                max: props.max,
            }} />
    )
}