import { Fragment, useState } from 'react'
import { TOrg } from '../../../data/Common'
import { IOrg } from '../../../data/Org'
import FormDialog from '../../../utils/FormDialog'
import { ResizableTable } from '../../../utils/ResizableTable'
import { useAppDispatch } from '../AdminReduxHooks'
import { ICommonPageProps } from '../common/CommonProps'
import { createOrganizationAsync } from '../common/FetchApi'
import NavBar from '../common/NavBar'
import { getDoctorsAsync, getTechsAsync } from '../person/PersonThunks'
import AddForm from './AddForm'
import { CreateOrgPayloadFromData, NewCreateOrgData } from './CreateOrgData'
import { Body, Header } from './Table'

export interface IPageProps extends ICommonPageProps<TOrg, IOrg> {} 

export default function Page(props: IPageProps) {
    const [showDialog, setShowDialog] = useState(false)
    const [newOrgData, setNewOrgData] = useState(NewCreateOrgData(props.itemType))
    const dispatch = useAppDispatch()

    const dialogTitle = props.itemType === 'lab' 
        ? 'Add Lab'
        : 'Add Hospital'

    
    const onCreateFieldUpdate = (id: string, value: string | boolean ) => {
        const updatedData = {...newOrgData}
        // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
        updatedData[id as keyof CreateOrgData] = value
        setNewOrgData(updatedData)
      }

      const onDialogFinished = async (isOk: boolean) => {
        if (isOk) {
            const isLabelPresent = props.items.findIndex(x => x.label === newOrgData.label) !== -1
            if (isLabelPresent) {
                return `Error: The label ${newOrgData.label} is already present in the list.`
            }
            
            const createOrgPayload = CreateOrgPayloadFromData(newOrgData)
            const ret = await createOrganizationAsync(createOrgPayload)            
            if (ret !== '') {
                return 'API Error:' + ret
            }
            
            dispatch(props.addAction(createOrgPayload.org))
            dispatch(props.itemType === 'lab' ? getTechsAsync() : getDoctorsAsync() )
            setShowDialog(false)
            return ''
        } else {
            setShowDialog(false)
            return ''
        }
    }

    return (
        <Fragment>
            <ResizableTable 
                header={<Header type={props.itemType}/>}
                body= {<Body organizations={props.items} updateAction={props.updateAction} org={props.itemType}/>}
            />
            <NavBar onAddClicked={() => setShowDialog(true)}  /> 
            <FormDialog show={showDialog} title={dialogTitle} closeCallback={onDialogFinished} 
                form={<AddForm data={newOrgData} type={props.itemType} onDataItemChanged={onCreateFieldUpdate}/>} /> 
        </Fragment>)
}
