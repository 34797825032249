import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createGetDataThunk, createInitialProcDataState } from '../../common/procSliceCommons'
import { PefusateLevelFields, TEntryField, TMemProcData, TStepData } from '../../data/ProcData'
import { getPreviousAssessmentStep, isAssessmentStep, isRecruitmentStep, TTimelineStep } from '../../data/WorkflowSteps'
import { RunState } from './RunStore'

export const getProcDataAsync = createGetDataThunk('procTimeline/fetchProcData')
const initialState = createInitialProcDataState();


export interface ISetFieldPayload {
    step: TTimelineStep
    fieldName: TEntryField
    value: number
}

export interface ISetFlagPayload {
    step: TTimelineStep
    fieldName: TEntryField
    value: boolean
}




// to Numeric Perfusate Field
function toNPF(value:  string | number | boolean | undefined) {
    return (value ?? 0) as number
}

function PerfusateLostCalculator(data:TMemProcData, step: TTimelineStep, field: TEntryField ) {
    let retval = undefined
    if (step !=='0:00' 
        && isAssessmentStep(step) 
        && (PefusateLevelFields as any as string[]).includes(field)) {
        if (step === '0:55') {
            retval = toNPF(data['0:00'].PerfusateLevelInitial) 
                        + toNPF(data['0:55'].PerfusateAddedPre)
                        - toNPF(data['0:55'].PerfusateLevelPre)
        } else {
            const prevStep = getPreviousAssessmentStep(step)
            const assessmentLost = toNPF(data[prevStep].PerfusateLevelPre)
                                - toNPF(data[prevStep].PerfusateLevelPost)
            const hourLost = toNPF(data[prevStep].PerfusateLevelPostExchange)
                            + toNPF(data[step].PerfusateAddedPre)
                            - toNPF(data[step].PerfusateLevelPre )
            retval = assessmentLost + hourLost
        }
    }
    return retval
}

function DeltaPFCalculator(stepData:TStepData, field: TEntryField ) {
    let retval = undefined
    if (field === 'LAPO2' || field === 'PAPO2') {
        if (stepData.LAPO2 !== undefined
            && stepData.PAPO2 !== undefined) {
                retval = (stepData.LAPO2 as number) - (stepData.PAPO2 as number)
        }
    }
    return retval
}

function PVResistanceCalculator(stepData:TStepData, field: TEntryField ) {
    let retval = undefined
    if (field === 'PAPressure' || field === 'LAPressure' || field === 'PerfusateFlow') {
        if (stepData.PAPressure !== undefined
            && stepData.LAPressure !== undefined
            && stepData.PerfusateFlow !== undefined) {
                retval = (((stepData.PAPressure as number) - (stepData.LAPressure as number)) * 80) 
                                    / (stepData.PerfusateFlow as number / 1000)
        }
    }
    return retval
}


function DeltaPFPulmonaryVeinCalculator(dependedField:TEntryField, stepData:TStepData, field: TEntryField ) {
    let retval = undefined
    if (field === dependedField || field === 'PAPO2') {
        if (stepData[dependedField] !== undefined
            && stepData.PAPO2 !== undefined) {
                retval = (stepData[dependedField]! as number) - (stepData.PAPO2 as number)
        }
    }
    return retval
}


export const timelineSlice = createSlice({
    name: 'timeline',
    initialState: initialState,
    reducers: {
        setfield: (state, action: PayloadAction<ISetFieldPayload>) => {
            state.data[action.payload.step][action.payload.fieldName] = action.payload.value

            state.data[action.payload.step].PVResistance = 
                PVResistanceCalculator(state.data[action.payload.step], action.payload.fieldName) 
                    ?? state.data[action.payload.step].PVResistance

            state.data[action.payload.step].PerfusateLoss = PerfusateLostCalculator(
                state.data, 
                action.payload.step, 
                action.payload.fieldName)
                    ?? state.data[action.payload.step].PerfusateLoss

            state.data[action.payload.step].DeltaPF = 
                DeltaPFCalculator(state.data[action.payload.step], action.payload.fieldName)
                    ?? state.data[action.payload.step].DeltaPF
            
            state.data[action.payload.step].DeltaPFRUV 
                = DeltaPFPulmonaryVeinCalculator('RUVPO2',state.data[action.payload.step], action.payload.fieldName) 
                    ?? state.data[action.payload.step].DeltaPFRUV

            state.data[action.payload.step].DeltaPFRLV 
                = DeltaPFPulmonaryVeinCalculator('RLVPO2',state.data[action.payload.step], action.payload.fieldName ) 
                    ?? state.data[action.payload.step].DeltaPFRLV

            state.data[action.payload.step].DeltaPFLUV 
                = DeltaPFPulmonaryVeinCalculator('LUVPO2',state.data[action.payload.step], action.payload.fieldName )
                    ?? state.data[action.payload.step].DeltaPFLUV

            state.data[action.payload.step].DeltaPFLLV 
                = DeltaPFPulmonaryVeinCalculator('LLVPO2',state.data[action.payload.step], action.payload.fieldName )
                    ?? state.data[action.payload.step].DeltaPFLLV 
            

        },
        setflag: (state, action: PayloadAction<ISetFlagPayload>) => {
            state.data[action.payload.step][action.payload.fieldName] = action.payload.value 
            if (isRecruitmentStep(action.payload.step)) { // check if all mandatory flags are set
                if (state.data[action.payload.step]['IsTidalVolumeIncreased']  
                    && state.data[action.payload.step]['IsHoldsPerformed']
                    && state.data[action.payload.step]['IsTidalVolumeRestored']) {
                        state.data[action.payload.step].IsMandatoryDataEntered = true;
                    } else {
                        state.data[action.payload.step].IsMandatoryDataEntered = false;
                    }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProcDataAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getProcDataAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload!
            })
        }
  

})



export const selectData = (state: RunState) => state.timelineSlice.data
export const { setfield, setflag } = timelineSlice.actions

export default timelineSlice.reducer

