export function replaceHeader(newTitle: string, newFaviconHref: string) {
    document.title = newTitle + ` v${process.env.REACT_APP_VERSION}`
    let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = newFaviconHref    

}