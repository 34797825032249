import { IEntityBase } from './Common'

export interface IServiceRecordData {
    operatingTimeSincePreviousService: string // hh:mm:ss
    servicedBy: string
    notes: string
}

export interface IServiceRecord extends IEntityBase, IServiceRecordData {  
}

export interface IDevicePersistedData {
    label: string
    orgLabel: string
    notes: string
    commissionDate: string
    totalOperatingTime: string //hh:mm:ss
    lastServiceDate: string
    operatingTimeSinceLastService: string //hh:mm:ss
    isOverdueForService: boolean
}

export interface IPersistedDevice extends IEntityBase, IDevicePersistedData {
} 


export interface IDevice extends  IPersistedDevice {
    serviceRecords: IServiceRecord[]
}


export function CreateDevice(): IDevice {
    return {
        partitionKey: '',
        rowKey: '',
        isActive: true,
        label: '',
        orgLabel: '',
        commissionDate: '',
        totalOperatingTime: '',
        lastServiceDate: '',
        operatingTimeSinceLastService: '',
        notes: '',
        isOverdueForService: false,
        serviceRecords:[]
    }
}

export function CloneDevice(source: IDevice): IDevice {
    const retval = {...source}
    retval.serviceRecords = [...source.serviceRecords]
    return retval
}

export function FromPesistence(source: IDevicePersistedData) {
    const emptyRecords: IServiceRecord[] = []
    const retval = {...source, serviceRecord: emptyRecords }
    retval as unknown as IDevice 
}

export function CreateServiceRecord(): IServiceRecord {
    return {
        partitionKey: '',
        rowKey: '', 
        servicedBy: '',
        operatingTimeSincePreviousService: '',
        notes: ''
    }
}

export function CloneServiceRecord(source: IServiceRecord): IServiceRecord {
    return {
        partitionKey: source.partitionKey,
        rowKey: source.rowKey,
        servicedBy: source.servicedBy,
        operatingTimeSincePreviousService: source.operatingTimeSincePreviousService,
        notes: source.notes 
    }
}

export function AttachServiceRecord(device: IDevice, record: IServiceRecord) {
    record.partitionKey = device.rowKey
    record.operatingTimeSincePreviousService = device.operatingTimeSinceLastService
    device.operatingTimeSinceLastService = '00:00'
    device.lastServiceDate = record.rowKey
    device.serviceRecords.push(record)
}

