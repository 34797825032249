import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { TFlagField } from '../../data/ProcData'
import { TTimelineStep } from '../../data/WorkflowSteps'
import { dataDictionary } from '../../data/dd'
import { useAppDispatch, useAppSelector } from './RunReduxHooks'
import { selectData, setflag } from './sliceTimeline'

export interface ICheckboxInputProps {
    flagName: TFlagField,
    step: TTimelineStep,
    altLabel?: ReactNode,
}

export default function DictionaryFlag(props: ICheckboxInputProps) {
    const field = props.flagName
    const fieldValue = useAppSelector(selectData)[props.step][field]  
    const dispatch = useAppDispatch()
    const fd = dataDictionary[field]

    return (
        <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
            checked={(fieldValue as boolean | undefined) ?? false} 
            onChange={e => dispatch(setflag({fieldName: field, value: e.target.checked, step: props.step}))} />} 
            label={<Typography variant='h5'>{props.altLabel ?? fd.label}</Typography>} />  
    )
}
