import { Menu, Stack, Box, Typography, Divider, IconButton } from "@mui/material"
import { useState } from "react";
import HelpIcon from '@mui/icons-material/Help';

export default function HelpBox() {

    const [anchorHelp, setAnchorHelp] = useState<null | HTMLElement>(null)
    const openHelp = Boolean(anchorHelp)

    const handleClickHelp = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorHelp(event.currentTarget);
    }

    const handlCloseHelp = () => {
        setAnchorHelp(null)
    }
    return (
        <>
        
        <IconButton size='small' onClick={handleClickHelp} >
            <HelpIcon/>
        </IconButton>
                        
        <Menu
        anchorEl={anchorHelp}
        open={openHelp}
        onClose={handlCloseHelp}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}>
        
        <Stack sx={{ p: '10px' }}> 
            <Box style={{ display: 'flex' }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    Traferox EVLP Wizard Version
                </Typography>
                <Typography> 
                    {":  "+process.env.REACT_APP_VERSION}
                </Typography>
            </Box>
            <Divider/>
            <Typography>    Any serious incident that has occurred in relation to the software </Typography> 
            <Typography>    should be reported to Traferox. To report such incident, or for any </Typography> 
            <Typography>    questions/concerns regarding the use or contents of the software, </Typography> 
            <Typography>    please email support@traferox.com. </Typography>
            
        </Stack>
        
        </Menu>
        </>
    )
  }


