import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, Typography, DialogActions, Button } from "@mui/material"
import { useState } from "react"
import Numpad from "../../utils/Numpad"

const confirmationCode = (Math.random() * 1000).toFixed(0)

export default function EndProcedureConfirm(props:{confirmCallBack: (isConfirmed: boolean) => void}) {
    const [errorMessage, setErrorMessage] = useState('')
    
    const numpadFinished = (result: string) => {
        if (result === confirmationCode) {
            props.confirmCallBack(true)
        } else {
            setErrorMessage('Codes mismatch. Please try again')
        }
    }

    return (
        <Dialog open={true} fullWidth maxWidth='sm' >
            <DialogTitle>Finish procedure?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action can not be undone. Please enter code <span style={{fontWeight: 'bold'}}>{confirmationCode}</span> to confirm
                </DialogContentText>
                <Grid container  spacing={0}  direction="column"  alignItems="center"  justifyContent="center" mt='20px'>
                    <Numpad resultcallback={numpadFinished}  />
                    <Typography mt='10px' variant='h6' color='red' textAlign='left' >{errorMessage}</Typography>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.confirmCallBack(false)} autoFocus>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
