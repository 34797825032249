import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { EditableServiceSettings } from "./ServiceTypes";
import { updateServicesSettingAsync } from "../../common/FetchApi";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { getServiceSettingsAsync } from "./ServiceSettingsThunk";
import { useAppDispatch } from "../../AdminReduxHooks";

const serviceWidith = '300px'

export function Header() {
    return (
    <Fragment>
        <Stack direction='row'>
            <Typography variant='h6' width={serviceWidith}> Service Settings</Typography>
        </Stack>
        <Divider/>
    </Fragment>
    )
}

export function Body(props: { settings: EditableServiceSettings | null }) {
  const [editedSettings, setEditedSettings] = useState({
    notificationRecipientEmails: props.settings?.notificationRecipientEmails || '',
    numberOfDaysBeforeNotification: props.settings?.numberOfDaysBeforeNotification || 0,
    repeatEvery_days: props.settings?.repeatEvery_days || 0,
  })

  const [notification, setNotification] = useState({
    open: false,
    message: '',
  })

  const dispatch = useAppDispatch()


  const handleUpdateClick = () => {
    updateServicesSettingAsync(editedSettings);
    setNotification({ open: true, message: 'Service Settings Updated Successfully!' })
  }

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false })
  }

  useEffect(() => {
      dispatch(getServiceSettingsAsync())
  }, [notification, dispatch])

  return (
    <Fragment>
      <br />
      <Stack direction={'row'}>
        {props.settings ? (
          <Stack>
            <TextField
              label="E-mails to Notify"
              sx={{ paddingBottom: '10px' }}
              value={editedSettings.notificationRecipientEmails}
              onChange={(e) =>
                setEditedSettings({ ...editedSettings, notificationRecipientEmails: e.target.value.replace(' ', '') })
              }
              multiline
            />
            <TextField
              label="Days Before Notification"
              sx={{ paddingBottom: '10px' }}
              value={editedSettings.numberOfDaysBeforeNotification}
              onChange={(e) => setEditedSettings({ ...editedSettings, numberOfDaysBeforeNotification: Number(e.target.value) })}
            />
            <TextField
              label="Repeat Every _ day(s)"
              sx={{ paddingBottom: '10px' }}
              value={editedSettings.repeatEvery_days}
              onChange={(e) => setEditedSettings({ ...editedSettings, repeatEvery_days: Number(e.target.value) })}
            />

            <Button variant="contained" onClick={handleUpdateClick}>
              Update
            </Button>

          </Stack>
        ) : (
          <>Error in retrieving service settings</>
        )}
      </Stack>

      <Snackbar open={notification.open} autoHideDuration={5000} onClose={handleCloseNotification}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
>
              <Alert onClose={handleCloseNotification} severity={"success"}>
                {notification.message}
              </Alert>
            </Snackbar>
    </Fragment>
  )
}