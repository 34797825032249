import { TDataField, TMemProcData, TStepData } from '../data/ProcData'
import { AllAssessmentSteps, getAssessmentPointLabel, TTimelineStep } from '../data/WorkflowSteps'


export type TDataCell = number | undefined
export type TTransposedData  = {
    readonly [k in TDataField]: TDataCell[]
}

function getActualDataLenght(source: TMemProcData) {
    let hasSomeData = false
    let maxDataLength = AllAssessmentSteps.length
    for (let i = maxDataLength - 1; i>= 0; i--) {
        if (Object.keys(source[AllAssessmentSteps[i]]).length !== 0) {
            hasSomeData = true
            maxDataLength = i + 1
            break;
        }
    }
    const ret = hasSomeData ? maxDataLength : 0
    return ret
}

export interface TTrasposedDataContainer {
    dataLength: number
    data: TTransposedData
}

export function transposeData(source: TMemProcData): TTrasposedDataContainer {
    const retval:TTrasposedDataContainer = {
        dataLength: 0,
        data: {
            IsMandatoryDataEntered: [],
            IsDataEntryFinished: [],
            StepEnteredAt: [],

            PerfusateLevelInitial: [],
            PerfusateAddedPre: [],
            PerfusateLevelPre: [],
            PerfusateLevelPost: [],
            PerfusateAddedExchange: [],
            PerfusateRemovedExchange: [],
            PerfusateLevelPostExchange:[],
            
            PerfusateFlow: [],
            PerfusateTemp: [],
            PumpSpeed: [],
            PAPressure: [],
            LAPressure: [],
            EVLPGasFlow: [],
            PeakAWP: [],
            MeanAWP: [],
            PlateauAWP: [],
            DynamicCompliance: [],
            StaticCompliance: [],
            LAGlucose: [],
            LALactate: [],
            LApH: [],
            LAPO2: [],
            LAPCO2: [],
            PApH: [],
            PAPO2: [],
            PAPCO2: [],
            ReservoirVolume: [],
            RUVPO2: [],
            RLVPO2: [],
            LUVPO2: [],
            LLVPO2: [],
            IsVentilationSettingsInAssessment: [],
            IsXrayPerformed: [],
            IsBronchoscopyPeformed: [],
            IsDeflationTestPerformed: [],
            IsPhotoTaken: [],
            IsTidalVolumeIncreased: [],
            IsHoldsPerformed: [],
            IspEVLPgasFlowAdjusted: [],
            IsNormalVentParamsRestored: [],
            IsTidalVolumeRestored: [],
            PVResistance: [],
            PerfusateLoss: [],
            DeltaPF: [],
            DeltaPFRUV: [],
            DeltaPFRLV: [],
            DeltaPFLUV: [],
            DeltaPFLLV: []
        }
        
    }

    Object.entries(retval.data).forEach(pair => pair[1]=[])
    retval.dataLength = getActualDataLenght(source)
    for(let i = 0; i < retval.dataLength; i++ ) {
        Object.entries(retval.data).forEach(([key, dataArray]) => {
            dataArray.push(source[AllAssessmentSteps[i] as TTimelineStep][key as TDataField] as number)
        })
    }
    return retval;
}

export type TLabeledValuesRecord = {label?: string} & TStepData
export type TimelineValuesArray = Array<TLabeledValuesRecord>


export function convertToGraphData(source: TMemProcData):TimelineValuesArray {
    const retval: TimelineValuesArray = []
    const maxData = getActualDataLenght(source)
    for (let i = 0; i < maxData; i++) {
        retval.push({ label:getAssessmentPointLabel(AllAssessmentSteps[i]), ...source[AllAssessmentSteps[i]] })
    }
    return retval
}