import { Alert, AlertColor, AlertTitle } from '@mui/material'

export type TAlertMessageType = string | JSX.Element | undefined

export interface IMessageAlertProps {
    msg: TAlertMessageType
    title?: string
    severity?: AlertColor
}

export default function MessageAlert({msg, title = '', severity = 'error'}:IMessageAlertProps) {
    if (msg === undefined || msg === '') {
        return null
    } else {
        return(
            <Alert severity="error">
                <AlertTitle>{title}</AlertTitle>
                {msg}
            </Alert>            
        )
        
    }
}