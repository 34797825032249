import { Fragment, useState } from 'react'
import { ResizableTable } from '../../../utils/ResizableTable'
import { useAppSelector } from '../AdminReduxHooks'
import NavBar from '../common/NavBar'
import AddDialog from './AddDialog'
import { selectAll } from './DevicesSlice'
import { selectAll as  selectAllServiceSetting} from './service/ServiceSettingsSlice'
import { Body as MedicalBody, Header as MedicalHeader } from './TableMedical'
import { Body as ServiceBody, Header as ServiceHeader } from './TableService'
import ViewSelector, { TSelectedView } from './ViewSelector'
import { Body as ServiceSettingBody, Header as ServiceSettingHeader} from './service/TableServiceSettings'


export default function Device() {
    const [showDialog, setShowDialog] = useState(false)
    const devices = useAppSelector(selectAll)
    const [selectedView, setSelectedView] = useState<TSelectedView>('medical')
    const serviceSettings = useAppSelector(selectAllServiceSetting)


    const onViewSelectionChanged = (selectedView: TSelectedView) => {
        setSelectedView(selectedView)
    }

    const onDialogFinished = (isOk: boolean) => {
        setShowDialog(false)
    }

    return (
        <Fragment>
            <ViewSelector 
                initialSelection={selectedView} 
                selectionCallback={onViewSelectionChanged}/>
            {selectedView === 'service setting'?
            <ResizableTable 
            header={<ServiceSettingHeader/>}
            body={<ServiceSettingBody settings={serviceSettings}/>}
            />
            :   
            <>
                <ResizableTable 
                    header={selectedView === 'medical' 
                    ? <MedicalHeader/> 
                    : <ServiceHeader/>}
                    
                    body= { selectedView === 'medical' 
                    ? <MedicalBody devices={devices}/> 
                    : <ServiceBody devices={devices}/>}
                />
                <NavBar onAddClicked={() => setShowDialog(true)}  /> 
                <AddDialog key={Math.random()} show={showDialog} closeCallback={onDialogFinished}  />
            </>    
            }
        </Fragment>
    )
}
