import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import BackIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import StartIcon from '@mui/icons-material/Start';
import { BottomNavigation, BottomNavigationAction, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TLung } from '../../data/Procedure';
import { TTimelineStep, isAssessmentStep } from '../../data/WorkflowSteps';
import NL from '../../utils/NavigationLabel';
import ChangeProcedureDialog from './ChangeProcedureDialog';
import EndProcedureConfirm from './EndProcedureConfirm';
import { useAppDispatch, useAppSelector } from './RunReduxHooks';
import UIInfo from './UIInfo';
import { getBypassWaitingForNext } from './allTime';
import { LungBothIcon, LungLeftIcon, LungRightIcon } from './icons';
import { advance, finishProcedureAsync, finishProcedureOfflineAsync, goBack, selectCanAdvance, selectCanGoBack, selectCanStartProcedure, selectProcHeader, selectStep, startProcedure } from './sliceRunProcHeader';
import { selectData, setflag } from './sliceTimeline';
import React from 'react';

export function BMSetup( {setCanPlayAudio} : {setCanPlayAudio: (b: boolean) => void}) {
    const canAdvance = useAppSelector(selectCanAdvance)
    const canStartProcedure = useAppSelector(selectCanStartProcedure)
    const canGoBack = useAppSelector(selectCanGoBack)
    const dispatch = useAppDispatch()
    const currentWorkflowStep = useAppSelector(selectStep)
    const [infoOpen, setInfoOpen] = useState(false)

     const infoSX = currentWorkflowStep === 'Procedure'
         ?  {visibility: 'hidden'}
         :  {}

    const infoIcon = <InfoIcon/>
    
    const infoLabel = NL('Info')

    const nextClicked = () => {
        if (canStartProcedure) {
            dispatch(startProcedure())
            setCanPlayAudio(true)
        } else if  (canAdvance ) {
            dispatch(advance())
        }
    }

    const nextLabel = canStartProcedure 
        ? NL('Start Perfusion') 
        : canAdvance 
            ? NL('Next')
            : null

    const nextIcon = canStartProcedure 
        ? <StartIcon />
        : canAdvance 
            ? <NextIcon />
            : null

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation sx={{ verticalAlign: 'center' }} showLabels >
                <BottomNavigationAction sx={!canGoBack ? { visibility: 'hidden' } : {}} label={NL('Back')} icon={<BackIcon />} onClick={() => dispatch(goBack())} />
                <BottomNavigationAction sx={{ visibility: 'hidden' }} />
                <BottomNavigationAction sx={infoSX} label={infoLabel} icon={infoIcon} onClick={() => {setInfoOpen(true)}} />
                <BottomNavigationAction sx={{ visibility: 'hidden' }} />
                <BottomNavigationAction sx={!(canAdvance || canStartProcedure ) ? { visibility: 'hidden' } : {}} 
                    label={nextLabel} icon={nextIcon} onClick={nextClicked} />
            </BottomNavigation>
            <UIInfo show={infoOpen} tabbedView={false} closeCallback={() => setInfoOpen(false)} key={Math.random()} />
        </Paper>)
}

function RenderLungIcon({ lung }: { lung: TLung }) {
    switch (lung) {
        case 'right': return (<LungRightIcon />)
        case 'left': return (<LungLeftIcon />)
        case 'both': return (<LungBothIcon />)
    }
    return null
}

React.memo(BMRampup)
export function BMRampup() {
    const [openConfirmProcedureEndDialog, setOpenConfirmProcedureEndDialog] = useState(false)
    const currentWorkflowStep = useAppSelector(selectStep)
    const dispatch = useAppDispatch()
    const header = useAppSelector(selectProcHeader)
    const lung = header!.lung
    const [changeProcedureOpen, setChangeProcedureOpen] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)

    const nextClicked = () => {
        dispatch(advance())
    }

    const confirmCallback = (isConfirmed: boolean) => {
        setOpenConfirmProcedureEndDialog(false)
        if (isConfirmed) {
                if (navigator.onLine){
                    dispatch(finishProcedureAsync({ partitionKey: header!.partitionKey, rowKey: header!.rowKey }))
                }else{
                    dispatch(finishProcedureOfflineAsync({ partitionKey: header!.partitionKey, rowKey: header!.rowKey }))
                }
        }
    }

    const testAdvanceSX = currentWorkflowStep === '0:20' // not Waiting for 32 C
        ? { visibility: 'hidden' }
        : {}

    const advanceSX = currentWorkflowStep === '0:20' // Is waiting for 32 C
        ? {}
        : { visibility: 'hidden' }


    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation sx={{ verticalAlign: 'center' }} showLabels >
                <BottomNavigationAction icon={<RenderLungIcon lung={lung} />} label={NL(lung)} onClick={() => setChangeProcedureOpen(true)} />
                {getBypassWaitingForNext() 
                    ?<BottomNavigationAction sx={testAdvanceSX} label={NL('Next')} icon={<NextIcon />} onClick={() => dispatch(advance())} />
                    :<BottomNavigationAction sx={{visibility:'hidden'}} />
                }
                <BottomNavigationAction label={NL('Info')} icon={<InfoIcon />} onClick={() => setInfoOpen(true)} />
                <BottomNavigationAction sx={advanceSX} label={NL('Continue (32°C)')} icon={<StartIcon />} onClick={nextClicked} />
                <BottomNavigationAction label={NL('Stop procedure')}
                    icon={<HighlightOffIcon sx={{ color: 'red' }} />}
                    onClick={() => setOpenConfirmProcedureEndDialog(true)} />
            </BottomNavigation>
            <ChangeProcedureDialog key={Math.random()} open={changeProcedureOpen} closeCallback={() => setChangeProcedureOpen(false)} />
            {openConfirmProcedureEndDialog
                ? <EndProcedureConfirm key={Math.random()} confirmCallBack={confirmCallback} />
                : null}
            <UIInfo show={infoOpen} tabbedView={false} closeCallback={() => setInfoOpen(false)} key={Math.random()}/>
        </Paper>)
}

React.memo(BMSteady)
export function BMSteady() {
    const [openConfirmProcedureEndDialog, setOpenConfirmProcedureEndDialog] = useState(false)
    const currentWorkflowStep = useAppSelector(selectStep)!
    const isMandatoryStepDataEntered = useAppSelector(selectData)[currentWorkflowStep as TTimelineStep].IsMandatoryDataEntered ?? false
    const isStepDataEntryFinished = useAppSelector(selectData)[currentWorkflowStep as TTimelineStep].IsDataEntryFinished ?? false
    const dispatch = useAppDispatch()
    const header = useAppSelector(selectProcHeader)
    const lung = header!.lung
    const [changeProcedureOpen, setChangeProcedureOpen] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)

    let continueSX: any = { visibility: 'hidden' }
    if (isAssessmentStep(currentWorkflowStep)) { // Assessment
        if (!isMandatoryStepDataEntered && !isStepDataEntryFinished) { // Just entered state
        } else if (!isMandatoryStepDataEntered && isStepDataEntryFinished) { // impossible
        } else if (isMandatoryStepDataEntered && !isStepDataEntryFinished) { // entered data, ready to advance to 'idle'
            continueSX = {}
        } else if (isMandatoryStepDataEntered && isStepDataEntryFinished) { // should be hidden
        }
    } else { // recruitment
        continueSX = isMandatoryStepDataEntered && (!isStepDataEntryFinished)
            ? {}
            : { visibility: 'hidden' }
    }


    const nextClicked = () => {
        dispatch(advance())
    }

    const continueClicked = () => {
        dispatch(setflag({ fieldName: 'IsDataEntryFinished', value: true, step: currentWorkflowStep! as TTimelineStep }))
    }


    const confirmCallback = (isConfirmed: boolean) => {
        setOpenConfirmProcedureEndDialog(false)
        if (isConfirmed) {
            if (navigator.onLine){
                dispatch(finishProcedureAsync({ partitionKey: header!.partitionKey, rowKey: header!.rowKey }))
            } else {
                dispatch(finishProcedureOfflineAsync({ partitionKey: header!.partitionKey, rowKey: header!.rowKey }))
            }
        }
    }

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation sx={{ verticalAlign: 'center' }} showLabels >
                <BottomNavigationAction icon={<RenderLungIcon lung={lung} />} label={NL(lung)} onClick={() => setChangeProcedureOpen(true)} />
                <BottomNavigationAction sx={{  visibility:'hidden' }} label='Next' icon={<NextIcon />} onClick={nextClicked} />
                <BottomNavigationAction label={NL('Info/Data')} icon={<InfoIcon />} onClick={() => {setInfoOpen(true)}} />
                <BottomNavigationAction sx={continueSX} label='Continue' icon={<StartIcon />} onClick={continueClicked} />
                <BottomNavigationAction label={NL('Stop procedure')} icon={<HighlightOffIcon sx={{ color: 'red' }} />} onClick={() => setOpenConfirmProcedureEndDialog(true)} />
            </BottomNavigation>
            <ChangeProcedureDialog key={Math.random()} open={changeProcedureOpen} closeCallback={() => setChangeProcedureOpen(false)} />
            {openConfirmProcedureEndDialog
                ? <EndProcedureConfirm key={Math.random()} confirmCallBack={confirmCallback} />
                : null
            }
            <UIInfo show={infoOpen} tabbedView={true} closeCallback={() => setInfoOpen(false)} key={Math.random()}/>
        </Paper>)
}

React.memo(BMFinished)
export function BMFinished({isNextAvailable}: {isNextAvailable: boolean}) {
    const [infoOpen, setInfoOpen] = useState(false)
    const [homeDialogOpen, setHomeDialogOpen] = useState(false)
    const [goHome, setGoHome] = useState(navigator.onLine)

    const handleCloseHomeDialog = () => {
        setHomeDialogOpen(false)
        setGoHome(true)
      }

      const handleHomeClick = () => {
        setHomeDialogOpen(true)
        if (goHome){
            localStorage.clear()
            window.history.replaceState({}, document.title, "/");
        }
      }

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation sx={{ verticalAlign: 'center' }} showLabels >
                <BottomNavigationAction sx={{ visibility: 'hidden' }} />
                <BottomNavigationAction sx={{ visibility: 'hidden' }} />
                <BottomNavigationAction label={NL('Info/Data')} icon={<InfoIcon />}  onClick={() => {setInfoOpen(true)}}/>
                <BottomNavigationAction sx={{ visibility: 'hidden' }} />
                <BottomNavigationAction sx={isNextAvailable ? {} : { visibility: 'hidden' }}
                    label={NL('Home')}
                    icon={<HomeIcon />}
                    component={Link}
                    to={goHome?'/tech/current':''} 
                    onClick={handleHomeClick}
                    />
            </BottomNavigation>
            <UIInfo show={infoOpen} tabbedView={true} closeCallback={() => setInfoOpen(false)} key={Math.random()} />

            <Dialog open={homeDialogOpen} onClose={handleCloseHomeDialog}>
                <DialogTitle>DATA NOT SAVED</DialogTitle>
                <DialogContent>
                <>
                    <DialogContentText id="alert-dialog-warning" style={{fontWeight: 'bold'}}>
                        {navigator.onLine?
                        <>You now have internet to upload your offline data. Please click the home button again to continue. </>
                        :
                        <>There is no internet connection. Closing this window will result in a loss of procedure data. Keep window open until internet connection is established.</>
                        }
                    </DialogContentText>
                    <br/>
                </>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseHomeDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </Paper>)
}
