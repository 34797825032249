import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IMonitorDoctor } from "../data/Person"
import { DocRootState } from "../routes/doctor/docStore"

export interface IMonitorDoctorsState {
    data: IMonitorDoctor[] | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IMonitorDoctorsState = {
    data: null,
    status: 'idle'
}

export const getArchivedDoctorsAsync = createAsyncThunk(
    'archivedDoctors/getDoctors',
    async (id) => {
        const requestUrl = `/api/getDoctorsByDoctorId?partitionKey=${id}`
        const response = await fetch(requestUrl)
        if (response.ok) {
        
            const json: IMonitorDoctor[] = await response.json()
            return json
        } else {
            console.log(`Error fetching archived doctors data from ${id}. Status: ${response.status}`)
            return null
        }
    }
)

export interface ISetCheck {
    id: string,
    value: boolean
}

export const archivedDoctorsSlice = createSlice({
    name: 'archivedDoctors',
    initialState: initialState,
    reducers: {
        setSend: (state, action: PayloadAction<ISetCheck>) => {
            let index = state.data!.findIndex(x => x.rowKey === action.payload.id)
            state.data![index].isSelectedForReport = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getArchivedDoctorsAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getArchivedDoctorsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload!
            })
    }
})

export const selectDoctors = (state: DocRootState) => {
    const data = state.doctorsSlice.data?.filter(x => x.isActive)
    if (data === undefined)
        return []
    return data
}
export const selectCanSend = (state: DocRootState) => {
    if (state.doctorsSlice.data) {
        return state.doctorsSlice.data!.reduce((accumulator, currentValue) => accumulator || currentValue.isSelectedForReport!, false)
    } else {
        return false
    }
}
export const { setSend } = archivedDoctorsSlice.actions
export default archivedDoctorsSlice.reducer