import { CircularProgress, Divider, FormControlLabel, IconButton, Stack, Switch, Typography } from '@mui/material'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getWindowDimensions } from '../../utils/AutoScrollContainer'
import MessageBox from '../../utils/MessageBox'
import { useAppDispatch, useAppSelector } from './monitorReduxHooks'
import { selectDoctors } from '../../common/sliceMonitorDoctors'

import { selectTechs } from '../../common/sliceTechs'
import { IMonitorDoctor, IPreferences } from '../../data/Person'
import RecepientFC, { filterRelevantInfo, getEmailsFromPeopleInfo, getPersonInfo, isValidList } from '../../common/RecipientFormControl'
import { getUserEmail, getUserRoles, selectPreferences, setPreferencesAsync, setSend } from '../../common/slicePreferences'
import { selectPrediction } from '../../common/slicePrediction'
import SendIcon from '@mui/icons-material/Send';
import getInsighTxHTML from '../../common/InsighTx'

export default function TabReport() {
    const d = useAppSelector(selectDoctors)
    return (
        <>
            {d
                ? <ReportUI />
                : <CircularProgress size='36px' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
        </>
    )
}

function ReportUI() {
    const d = useAppSelector(selectPrediction)
    const [includeInsighTx, setIncludeInsighTx] = useState(true)

    let resulstMessage, listItems, missing

    let newBody 
    if (d.data === 'false') {
        newBody = `
        <h1 style="color: #0074cc;"> INSIGHTx </h1>

    <div>
        <div style="display: flex; justify-content: flex-end; width: 100%;">
        </div>

        <div style="flex: 1; text-align: center; padding: 10px;">
            
            <span style="color: #0074cc;"> This hospital does not have permission to use InsighTx </span>
        </div>
    </div>
        `
    } else if (d.data){
        const data = JSON.parse(d.data)

        missing = data.missing//'Cytokines' // get rid of later
        missing.push('Cytokines')

        let factors: string [] = data['top5'] // change word formatting.....
        //['Last observed Cstat', 'Last observed Delta PO<sub>2</sub>', 'Max observed Delta PO<sub>2</sub>', 'Lowest observed Delta PO<sub>2</sub>', 'Last observed Cdyn']

        listItems = factors.map(factor => `<li>${factor}</li>`);
        
        resulstMessage = data.outcome
        if (resulstMessage){
            resulstMessage = ' extubated within 72 hours after transplant' // get rid of later
        }
        newBody = getInsighTxHTML(resulstMessage, listItems, missing)
    } else {

        newBody = getInsighTxHTML('', [], [])
    }





    return (
        <>
            <Recepients insighTx={newBody} includeInsighTx={includeInsighTx} setIncludeInsighTx={setIncludeInsighTx}/>
            <Divider>Report preview</Divider>
            <ReportPreview insighTx={newBody} includeInsighTx={includeInsighTx}/>
        </>
    )
}

function Recepients({ insighTx, includeInsighTx, setIncludeInsighTx }: { insighTx: string, includeInsighTx: boolean, setIncludeInsighTx: (b:boolean)=>void }) {
    const [showSent, setShowSent] = useState(false)
    const { partitionKey, rowKey } = useParams()

    const doctors = useAppSelector(selectDoctors)
    const docs = doctors!.filter(x => !(x.isConsultant))
    const cons = doctors!.filter(x => x.isConsultant)
    const techs: IMonitorDoctor[] = useAppSelector(selectTechs)
    const dispatch = useAppDispatch()

    const [preferences, setPreferences] = useState( useAppSelector(selectPreferences))
    const [docsName, setDocsName] = useState<string[]>([])
    const [consName, setConsName] = useState<string[]>([])
    const [techsName, setTechsName] = useState<string[]>([])

    useEffect(() => {
        const docs = doctors!.filter(x => !(x.isConsultant))
        const cons = doctors!.filter(x => x.isConsultant)

        let docs_info, cons_info, techs_info

        if (preferences){
            if (preferences.docs){
                docs_info =  preferences.docs.map((doc: string) => filterRelevantInfo(docs, doc))
                if (isValidList(docs_info))
                    setDocsName(docs_info)
            }
            if(preferences.cons){
                cons_info =  preferences.cons.map((con: string) => filterRelevantInfo(cons, con))
                if (isValidList(cons_info))
                    setConsName(cons_info)
            }
            if(preferences.techs.length){
                techs_info =  preferences.techs.map((tech: string) => filterRelevantInfo(techs, tech))
                if (isValidList(techs_info))
                    setTechsName(techs_info)
            }
        }

        if (techs){
            if (!preferences || !(isValidList(docs_info) || isValidList(cons_info) || isValidList(techs_info))) {
                const techs_info = techs.map((tech) => getPersonInfo(tech))
                if (techs_info && techs_info[0]){
                    setTechsName(techs_info)
                }
            }
        }
    }, [preferences, doctors, techs])





    const executeSend = async () => {
        let sendAddresses = ''
        const allPeople = getEmailsFromPeopleInfo(docsName.concat(consName, techsName))
        allPeople?.forEach((x) => {    
                sendAddresses += x.slice(1,-1) 
                sendAddresses += ','
            }
        )
        sendAddresses = sendAddresses.slice(0, -1)
        const sendApi = `/api/sendReport?partitionKey=${partitionKey}&rowKey=${rowKey}&email=${sendAddresses}&insighTx=${includeInsighTx}`
        let roles = await getUserRoles()

        await fetch(sendApi,{method: 'POST', headers: {'Content-Type': 'application/json',
            },
            body: `${insighTx}}`
        })
        setShowSent(true)
        
        const newPreferences: IPreferences = {docs: docsName, cons: consName, techs: techsName}
        setPreferences(newPreferences)
        dispatch(setSend(newPreferences))

        let table: 'doctor' | 'tech' = 'doctor'
        if (roles.includes('tech')){
            table = 'tech'
        }

        getUserEmail().then((eMail) => {
            dispatch(setPreferencesAsync({table, eMail, preferences: newPreferences}))
            })

    }

    return (
        <>
            <Typography variant='h5'>
                Recipients 
            </Typography>

            <Stack direction={'row'}>
                <RecepientFC recepients={docs} 
                            selectedNames={docsName} 
                            handleChangeNames={setDocsName}
                            role='Surgeons'/> 

                <RecepientFC recepients={cons} 
                            selectedNames={consName} 
                            handleChangeNames={setConsName}
                            role='Consultants'/> 

                <RecepientFC recepients={techs} 
                            selectedNames={techsName} 
                            handleChangeNames={setTechsName}
                            role='Perfusionists'/> 

                <IconButton size="small" disabled={!(docsName.length + consName.length + techsName.length)} onClick={executeSend}>
                        <SendIcon/>
                </IconButton>
            </Stack>
            
            <br/>

            <FormControlLabel 
                id='InsighTx switch'
                disabled={!(docsName.length + consName.length + techsName.length)} 
                control={<Switch checked={includeInsighTx} onChange={()=>setIncludeInsighTx(!includeInsighTx)} />} 
                label="Include InsighTx in Report" />

            <MessageBox open={showSent}
                buttonsType='MB_OK'
                message='Report sent!'
                callback={() => setShowSent(false)}
                severity='info' />

        </>
    )
}

function ReportPreview({ insighTx, includeInsighTx }: { insighTx: string, includeInsighTx: boolean }) {
    const { partitionKey, rowKey } = useParams()
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const parentRef = useRef<HTMLDivElement>(null)
    const [bodyHeight, setBodyHeight] = useState(0)
    const bottomOffset = 20
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions.height])

    useLayoutEffect(() => {
        if (parentRef?.current?.offsetTop) {
            setBodyHeight(windowDimensions.height)
        }
    }, [windowDimensions.height, bottomOffset, insighTx])


    
    return (
        <Stack ref={parentRef}>
            <iframe
                style={{ border: 0, minHeight:bodyHeight }}
                id='frame'
                title='Report'
                width='fit-content'
                src={`/api/getReport?partitionKey=${partitionKey}&rowKey=${rowKey}`}
            />
            {(insighTx && includeInsighTx)? 
            <>
                <div dangerouslySetInnerHTML={{ __html: insighTx }} />
            </>
                :null
            }
        </Stack>
    )
}