import { Grid } from '@mui/material'
import { Fragment } from 'react'
import { DataChart } from '../../common/DataChart'
import { useAppSelector } from './monitorReduxHooks'
import { selectChartData } from './sliceMonitorData'

export default function TabGraphical() {
    const chartData = useAppSelector(selectChartData)

    if (chartData === undefined) {
        return (<></>)
    } else {

        return (
            <Fragment>
                <Grid container spacing={'1px'}>
                    <Grid item xs={6}>
                        <DataChart height={200} width={600} elements={['PAPressure', 'LAPressure']} data={chartData} />
                    </Grid>
                    <Grid item xs={6}>
                        <DataChart height={200} width={600} elements={['DeltaPF', 'DeltaPFRUV', 'DeltaPFLUV', 'DeltaPFRLV', 'DeltaPFLLV']} data={chartData} />
                    </Grid>
                    <Grid item xs={6}>
                        <DataChart height={200} width={600} elements={['PeakAWP', 'PlateauAWP', 'MeanAWP']} data={chartData} />
                    </Grid>
                    <Grid item xs={6}>
                        <DataChart height={200} width={600} elements={['DynamicCompliance', 'StaticCompliance']} data={chartData} />
                    </Grid>

                    <Grid item xs={4}>
                        <DataChart height={150} width={400} elements={['LAGlucose', 'LALactate']} data={chartData} />
                    </Grid>
                    <Grid item xs={4}>
                        <DataChart height={150} width={400} elements={['LApH', 'PApH']} data={chartData} />
                    </Grid>
                    <Grid item xs={4}>
                        <DataChart height={150} width={400} elements={['PerfusateLoss']} data={chartData} />
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}