import { IOrg } from '../../../data/Org'
import type { AdminRootState } from '../AdminStore'
import { createOrgSliceHelper, IOrganizationsState } from './OrgSliceHelpers'
import { getHospitalsAsync } from './OrgThunks'

const initialState: IOrganizationsState = {
  all: new Array<IOrg>(),
  status: 'idle'
}


export const orgsSlice = createOrgSliceHelper('hospital', initialState, getHospitalsAsync)

export const { add, update } = orgsSlice.actions


export const selectAll = (state: AdminRootState) => state.hospitals.all
export const selectStatus = (state:  AdminRootState) => state.labs.status

export default orgsSlice.reducer

