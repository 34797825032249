import { TStep, TTimelineStep } from './WorkflowSteps'

// Perfusate level fields
export const PefusateLevelFields = [
    'PerfusateLevelInitial', // level at '0:00'
    'PerfusateAddedPre',  // added before assessment
    'PerfusateLevelPre', // at the beginning of assessment
    'PerfusateLevelPost', // level after assessment
    'PerfusateAddedExchange', // added during exchange
    'PerfusateRemovedExchange', // removed during exchange
    'PerfusateLevelPostExchange' // level after exchange
] as const
export type TPerfusateLevelFields = typeof PefusateLevelFields[number]

export const PefsuateFlowFields = [
    'PerfusateFlow' , 
    'PerfusateTemp' , 
    'PumpSpeed',
] as const
export type TPefsuateFlowFields = typeof PefsuateFlowFields[number]

export type TPefusateField = TPerfusateLevelFields | TPefsuateFlowFields

//Pressure/flow
const PressureFlowFields = ['PAPressure', 'LAPressure', 'EVLPGasFlow'] as const
export type TPressureFlowField = typeof PressureFlowFields[number]

// Ventilation
export const VentilationFields = [
    'PeakAWP' , 
    'MeanAWP' , 
    'PlateauAWP' , 
    'DynamicCompliance' , 
    'StaticCompliance'] as const
export type TVentilationField = typeof VentilationFields[number]

// Arterial Blood Gas Analysis
export const ABGAFields = [
    'LAGlucose' , 
    'LALactate' , 
    'LApH' , 
    'LAPO2' , 
    'LAPCO2' , 
    'PApH' , 
    'PAPO2' , 
    'PAPCO2' , 
    'ReservoirVolume'] as const
export type TABGAField = typeof ABGAFields[number]

// Pulmonary Vein Gas Analysis
const PVGAFields = ['RUVPO2'  , 'RLVPO2'  , 'LUVPO2'  , 'LLVPO2'] as const
export type TPVGAField = typeof PVGAFields[number]

// Calculated fields
export const CalculatedFields = [
    'PVResistance',
    'PerfusateLoss' , 
    'DeltaPF' , 
    'DeltaPFRUV' , 
    'DeltaPFRLV' , 
    'DeltaPFLUV' , 
    'DeltaPFLLV'] as const
export type TCalculatedField = typeof CalculatedFields[number]

const FlagFields = [
    'IsVentilationSettingsInAssessment', 
    'IsXrayPerformed',
     'IsBronchoscopyPeformed',
     'IsDeflationTestPerformed', 
     'IsPhotoTaken',
     'IsTidalVolumeIncreased',
     'IsHoldsPerformed', 
     'IspEVLPgasFlowAdjusted',
     'IsNormalVentParamsRestored',
     'IsTidalVolumeRestored',
] as const
export type TFlagField = typeof FlagFields[number]

export const UtilityFields = ['IsMandatoryDataEntered', 'IsDataEntryFinished', 'StepEnteredAt' ] as const
export type TUtilityFields = typeof UtilityFields[number]


export type TEntryField =   TPefusateField 
                          | TPressureFlowField 
                          | TVentilationField 
                          | TABGAField 
                          | TPVGAField 
                          | TFlagField
                          | TUtilityFields


export type TDataField = TEntryField | TCalculatedField 
//type TUserActionLogs = {userActionLogs: string}

export type TStepData = {
    [k in TDataField | TFlagField ]?: number | string | boolean
}

// This is used for persisting the data in Azure
export type TProcDataHeader = {
    partitionKey: string
    rowKey: TStep
}

export type TPersistedProcData = TProcDataHeader & TStepData //& TUserActionLogs

// This is used in redux and UI
export type TMemProcData = { 
    [k in TTimelineStep]: TStepData 
}