import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveIcon from '@mui/icons-material/Save';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { TWorkflowState } from '../../data/WorkflowSteps';
import { useAppSelector } from './RunReduxHooks';
import { RunState } from './RunStore';
import { selectElaspedTimeHHMMSS, selectStatus } from './sliceRunProcHeader';
import WifiStatus from '../../common/WifiStatus';

const HeaderStart = 'Traferox EVLP Wizard.'

export default function RunHeader({ workflowState, setCanPlayAudio }: { workflowState: TWorkflowState, setCanPlayAudio: (b: boolean) => void} ) {
    switch (workflowState) {
        case 'setup': return (<RHSetup />)
        case 'rampup': return (<RHTimeline stateDescription='State: Ramp up.' setCanPlayAudio={setCanPlayAudio} />)
        case 'steady': return (<RHTimeline stateDescription='State: Steady state.' setCanPlayAudio={setCanPlayAudio} />)
        default: return null
    }
}

function RHSetup() {
    return (
        <Grid container spacing={1} sx={{mb:'10px'}}>
            <Grid item xs={12}>
                <Typography variant='h5'>{`${HeaderStart} State: Setup.`}</Typography>
            </Grid>
            <Box sx={{ position: 'absolute', top: 0, right: 8 }}>
                <WifiStatus/>
            </Box>
        </Grid>)
}

function SavingProgress() {
    const status = useAppSelector(selectStatus)
    switch (status) {
        case 'idle': return null
        case 'loading': return (
                <>
                    <SaveIcon sx={{opacity:.5}}/>
                    <CircularProgress size='small'/> 
                </>
            )
        case 'failed': return (<ErrorOutlineIcon/>)
    }

}

function RHTimeline({stateDescription, setCanPlayAudio}:{stateDescription:string, setCanPlayAudio: (b: boolean) => void}) {
    const timeElasped = useAppSelector(selectElaspedTimeHHMMSS)
    const currentStepIndex = useSelector((state: RunState) => state.procHeaderSlice.data?.step)

    useLayoutEffect(() => {
        setCanPlayAudio(true)
    }, [currentStepIndex, setCanPlayAudio])

    return (
        <Grid container spacing={1} sx={{mb:'10px'}}>
            <Grid item xs={11}>
                <Typography variant='h5'>{`${HeaderStart} ${stateDescription}`}</Typography>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent='flex-end' >
                <Stack direction='row'>
                    <WifiStatus/>
                    <SavingProgress/>
                    <Typography  variant='h5'>{`${timeElasped.hh}:${timeElasped.mm}:${timeElasped.ss}`}</Typography>
                </Stack>
            </Grid>
        </Grid>)
}


export function RHFinished() {
    const timeElasped = useAppSelector(selectElaspedTimeHHMMSS)
    return (
        <Grid container spacing={1} sx={{mb:'10px'}}>
            <Grid item xs={11}>
                <Typography variant='h5'>{`${HeaderStart} Procedure finished`}</Typography>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent='flex-end' >
                    <WifiStatus/>
                    <Typography  variant='h5'>{`${timeElasped.hh}:${timeElasped.mm}:${timeElasped.ss}`}</Typography>
            </Grid>
        </Grid>)
}
