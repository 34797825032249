import IPrincipalData from '../data/IPrincipalData';
import { updatePersonLogsAsync } from '../routes/admin/common/FetchApi';

const appRoles = ['admin', 'tech', 'doctor'] as const
export type TAppRoles = typeof appRoles[number]
export type TDefaultRoles = 'anonymous' | 'authenticated'
export type TUserRoles = TDefaultRoles | TAppRoles

export async function getEluaFlagAsync(role:TAppRoles) {
    const response  = await fetch(`/api/getEluaFlag?role=${role}`)
    const data = await response.json()
    return data as boolean
}  

export async function getUserInfoAsync()  {
    const response = await fetch('/.auth/me');
    const payload = await response.json();
    const { clientPrincipal } = payload;
    return clientPrincipal as IPrincipalData;
}
 
async function logUserSession(role: string){
    const user = await getUserInfoAsync()
    const email = user.userDetails
    updatePersonLogsAsync(email, role)
}

export function roleToPath(role: TAppRoles) {
    logUserSession(role)

    if (role === 'tech' || role === 'doctor') {
        return `/${role}/current`
    } else {
        return `/${role}`
    }
}

export async function userInRole(principal: IPrincipalData | undefined) {
    let retval: TAppRoles[] = []
    const urlTepmplate = `/api/getUserActiveStatus?eMail=${principal?.userDetails}&`
    for(let role of appRoles) {
        if (principal?.userRoles.includes(role)) {

            if (role === 'admin'){
                retval.push(role as TAppRoles)
                continue
            }

            const requestUrl = urlTepmplate + `role=${role}`
            const response = await fetch(requestUrl)
            const {isActive} = await response.json()
            if (response.ok && isActive){
                retval.push(role as TAppRoles)
            }
        }
    }
    return retval
  }
