import { Button, Card, Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IPrincipalData from './data/IPrincipalData';
import { TAppRoles, getUserInfoAsync, roleToPath, userInRole } from './utils/PrinicpalUtils';


export default function App() {
  const [principal, setPrincipal] = useState<IPrincipalData>()
  const navigate = useNavigate()
  const [roles, setRoles] = useState<TAppRoles[]>([])
  
  const retrievePrincipal = async () => {
    setPrincipal(await getUserInfoAsync())
  }

  
  useEffect(() => {
    const fetchActive = async () => {
      const ac = new AbortController()
      retrievePrincipal()
        const role = await userInRole(principal)
        if (role.length === 0) {
          // do nothing, this is a login screen
        } else if (role.length === 1) {
          navigate(roleToPath(role[0]))
        } else {
          setRoles(role)
        }
        return () => ac.abort()
    }
    fetchActive()
  }, [principal?.userId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      { roles.length === 0 
        ? <> 
            <Link 
               href='https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https://nextbase.traferox.net/' 
              sx={{position:'absolute', top:0, right:8}}>Log out current account</Link>

            <Button sx={{m: 0, position: 'absolute', top: '50%', left: '50%', msTransform:'translate(-50%, -50%)', transform:'translate(-50%, -50%)'}} 
                  variant='contained' 
                  href='/.auth/login/aad'>Please log in</Button> 
            {/* <NumTest/> */}
          </>
        : <Card sx={{m: 0, p:1, position: 'absolute', top: '50%', left: '50%', msTransform:'translate(-50%, -50%)', transform:'translate(-50%, -50%)'}}>
            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                Please select the role  </Typography>            
            <Stack>
                {roles.map(r => <Link key={r} href={roleToPath(r)}><Typography sx={{fontSize:16}}>{r}</Typography></Link>)}
            </Stack>
            <Typography sx={{ mt:'10px', fontSize: 18 }} color="text.secondary" gutterBottom>
                or</Typography><Link href='/.auth/logout'>log out</Link>
          </Card>
      }
    </>
  );
}


