import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDevice } from "../../../data/Device"
import { AdminRootState } from '../AdminStore'
import { getDevicesAsync } from './DevicesThunks'

export interface IDevicesState {
    all: IDevice[]
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IDevicesState = {
    all: [],
    status: 'idle'
}


export const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<IDevice>) => {
            state.all.push(action.payload)
        },
        update: (state, action: PayloadAction<IDevice>) => {
            const index = state.all.findIndex(x => x.rowKey === action.payload.rowKey)
            if (index !== -1) {
                state.all[index] = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDevicesAsync.pending, (state) => {
                state.all.splice(0)
                state.status = 'loading';
            })
            .addCase(getDevicesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.all = action.payload;
            });
    },
})

export const { add, update } = devicesSlice.actions

export const selectAll = (state: AdminRootState) => state.devices.all
export const selectStatus = (state: AdminRootState) => state.devices.status
export default devicesSlice.reducer


