import { Checkbox, Divider, FormControl, FormControlLabel, Grid, TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { ICreateOrgData } from './CreateOrgData'

export interface IAddFormProps {
    data: ICreateOrgData
    type: 'lab' | 'hospital'
    onDataItemChanged: (id: string, value: string | boolean) => void
}

export default function AddForm(props: IAddFormProps) {
    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //props.onDataItemChanged(e.target.id, e.target.value)
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        props.onDataItemChanged(id, id === 'insighTxPermission' ? (e.target as HTMLInputElement).checked :  e.target.value)
    }


    return (
        <FormControl fullWidth>
            <Divider textAlign="left" sx={{mb:'10px'}}>Organization</Divider>
            {
                props.type === 'hospital'?
                <Grid item xs={2}>
                    <FormControlLabel sx={{mt:'10px'}} control={<Checkbox id='insighTxPermission' 
                        onChange={onChange} checked={props.data.insighTxPermission || false}  />} 
                        label="InsighTx Permission" />
                </Grid>
            : null
            }
            <Grid container spacing={1}>    
                <Grid item xs={4}>
                    <TextField fullWidth label='Label' 
                        value={props.data['label']} id='label' onChange={onChange} 
                        required /> 
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth label='Full Name' 
                        value={props.data['fullName']} id='fullName' onChange={onChange} 
                        required/>
                </Grid>
            </Grid>
            <Divider textAlign="left" sx={{mb:'10px', mt:'32px'}}>Contact</Divider>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <TextField fullWidth label='Honorific' 
                        value={props.data['honorific']} id='honorific' onChange={onChange} 
                        required/>
                </Grid>
                <Grid item xs={5}>
                    <TextField fullWidth label='First Name' 
                        value={props.data['firstName']} id='firstName' onChange={onChange} 
                        required/> 
                </Grid>
                <Grid item xs={5}>
                    <TextField fullWidth label='Last Name' 
                        value={props.data['lastName']} id='lastName' onChange={onChange} 
                        required/>
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label='eMail' 
                        value={props.data['eMail']} id='eMail' onChange={onChange} 
                        required/> 
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label='Phone Number' 
                        value={props.data['phoneNumber']} id='phoneNumber' onChange={onChange} 
                        required/>
                </Grid>
            </Grid>
        </FormControl>        
    )
}