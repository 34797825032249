import { Grid, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TEntryField } from '../../data/ProcData'
import { TSteadyStep } from '../../data/WorkflowSteps'
import DF from './DictionaryNumberField'
import EntryItem from './RampupEntryItem'
import { useAppSelector } from './RunReduxHooks'
import aGetFormCompletion from './aGetFormCompletion'
import { selectData } from './sliceTimeline'

export const fieldList:TEntryField[] = [
    'PerfusateLevelPost', 'PerfusateRemovedExchange', 'PerfusateAddedExchange', 'PerfusateLevelPostExchange']

export default function ATab6Perfusate({completion} : {completion: (allChecked: boolean) => void}) {
    const { step } = useParams()
    const stepData = useAppSelector(selectData)

    useEffect(() => {
        completion(aGetFormCompletion(stepData, step as TSteadyStep, fieldList))
    }, [stepData, step, completion])

    const GRIDSPACING = 2
    return (
        <>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={6}>
                        <DF step={step} field='PerfusateLevelPost' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        &nbsp;
                    </Grid>
                    <Grid item xs={12}>
                        {step === '0:55'?
                            <Typography variant='h6'>Drain 500 mL or to the 500 mL mark on the reservoir, then add 500 mL of fresh perfusate</Typography>
                            :
                            <Typography variant='h6'>Drain 250 mL or to the 500 mL mark on the reservoir, then add 250 mL of fresh perfusate</Typography>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='PerfusateRemovedExchange' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='PerfusateAddedExchange' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='PerfusateLevelPostExchange' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
        </>
    )

}