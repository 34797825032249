import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TRampupStep } from '../../data/WorkflowSteps';
import DF from './DictionaryNumberField';
import EntryItem from './RampupEntryItem';
import { useAppSelector } from './RunReduxHooks';
import { selectRampupParameters } from './sliceRunProcHeader';
import UIRampupVentParameters from './UIRampupVentParameters';
import AutoScrollContainer from '../../utils/AutoScrollContainer';
import UIRampupBGAOnPASampleSelector from './UIRampupBGAOnPASampleSelector';

export default function UIRampupStep0t4050() {
    const { step } = useParams()
    const stepFlowRate = useAppSelector(selectRampupParameters)![step as TRampupStep] * 1000
    return (
        <AutoScrollContainer bottomOffset={70}>
            <EntryItem>
                <Typography variant='h5'>Ventilation parameters</Typography>
                <UIRampupVentParameters />
            </EntryItem>
            <EntryItem>
                <Typography variant='h5'>Enter current perfusate temperature</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DF fullWidth field={'PerfusateTemp'} step={step} /> <br />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem>
                <Typography variant='h5'>Set the perfusion flow to <strong>{stepFlowRate}</strong> ml/min <strong>and</strong> maintain LA pressure at 3-5 mm Hg</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <DF fullWidth field={'PerfusateFlow'} step={step} />
                    </Grid>
                    <Grid item xs={4}>
                        <DF fullWidth field={'PumpSpeed'} step={step} />
                    </Grid>
                    <Grid item xs={4}>
                        <DF fullWidth field={'LAPressure'} step={step} />
                    </Grid>
                </Grid>
            </EntryItem>
            <UIRampupBGAOnPASampleSelector />
        </AutoScrollContainer>
    )
}


