import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { TTimelineStep } from '../../data/WorkflowSteps';
import EntryItem from './RampupEntryItem';
import { useAppSelector } from './RunReduxHooks';
import { selectProcHeader, selectStep } from './sliceRunProcHeader';
import { selectData } from './sliceTimeline';
import UIRampupVentParameters from './UIRampupVentParameters';
import AutoScrollContainer from '../../utils/AutoScrollContainer';



export default function UISteadyHost({ui}:{ui:ReactNode}) {
    const step = useAppSelector(selectStep)
    const stepDataEntryFinished = useAppSelector(selectData)[step as TTimelineStep].IsDataEntryFinished ?? false
    const procHeader = useAppSelector(selectProcHeader)!
    const displayFlow = Math.round(procHeader.evlpMaxFlow_LPmin! * 10) * 100

    return (
        <AutoScrollContainer bottomOffset={70}>
            { stepDataEntryFinished
                ? <Box sx={{m:2}}>
                    <UIRampupVentParameters/>
                    <br/>
                    <EntryItem><Typography variant='h5'>Maintain LA pressure between <strong>3 and 5</strong> mm Hg</Typography></EntryItem>
                    <EntryItem><Typography variant='h5'>Maintain reservoir volume above <strong>500</strong> ml</Typography></EntryItem>
                    <EntryItem><Typography variant='h5'>Maintain perfusate flow at <strong>{displayFlow}</strong> ml/min</Typography></EntryItem>
                  </Box>
                : ui
            }
        </AutoScrollContainer>
    )
}