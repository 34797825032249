import { Grid, MenuItem, Stack, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { consNames, IOrg } from '../../../data/Org';
import MessageBox from '../../../utils/MessageBox';
import { ResizableTable } from '../../../utils/ResizableTable';
import { useAppDispatch, useAppSelector } from '../AdminReduxHooks';
import { updateOrganizationAsync } from '../common/FetchApi';
import NavBar from '../common/NavBar';
import { selectAll, update } from '../org/HospitalsSlice';
import AddDialog from './AddDialog';
import { Body, Header } from './Table';


export default function Consultants() {
    const allHospitals = useAppSelector(selectAll)
    const [selectedHospital, setSelectedHospital] = useState('')
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [showErrorDuplicate, setShowErrorDuplicate] = useState(false)
    const [showErrorTooMany, setShowErrorTooMany] = useState(false)
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        if ((allHospitals !== undefined) 
                && (allHospitals.length !== 0) 
                && (allHospitals[0] !== undefined)) {
            setSelectedHospital(allHospitals[0].rowKey)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSelectedHospital(e.target.value)
    }

    const onAddCloseRequested = (isOk: boolean, consultantId?: string) => {
        setShowAddDialog(false)
        if (isOk && consultantId !== '' && consultantId !== undefined) {
            const theHospital = allHospitals.find(x => x.rowKey === selectedHospital)
            const checkResult = canAddConsultant(theHospital!, consultantId)
            switch (checkResult) {
                case 'Can':
                    const updatedHospital = addConsultant(theHospital!, consultantId)
                    dispatch(update(updatedHospital))
                    updateOrganizationAsync(updatedHospital)
                    break
                case 'Too many': setShowErrorTooMany(true); break
                case 'Duplicate': setShowErrorDuplicate(true); break
            }
        }
    }

    return (
        <>
           <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField select fullWidth  
                        value={selectedHospital}  
                        onChange={onChange} 
                        label='Select Hostpital'>
                            {allHospitals.map(x => 
                                <MenuItem key={x.rowKey} value={x.rowKey}>
                                    <Stack direction='row'>
                                        <div style={{fontWeight:'bold'}} >Label:&nbsp;</div>{x.label}&nbsp;&nbsp; &nbsp; &nbsp;  
                                        <div style={{fontWeight:'bold'}} >Full Name:&nbsp;</div>{x.fullName} 
                                    </Stack>
                                </MenuItem>)}
                    </TextField> 
                </Grid>
            </Grid>
            <ResizableTable 
                header={<Header/>}
                body= {<Body target={selectedHospital} />}
            />
            <NavBar onAddClicked={() => setShowAddDialog(true)}  /> 
            <AddDialog key={Math.random()} closeCallback={onAddCloseRequested} show={showAddDialog} targetOrgId={selectedHospital} />
            <MessageBox open={showErrorDuplicate}
                    buttonsType='MB_OK'
                    message='This doctor is already assigned as a consultant for the hospital'
                    callback={(ret) => setShowErrorDuplicate(false)}
                    severity='error' />
            <MessageBox open={showErrorTooMany}
                    buttonsType='MB_OK'
                    message={`The maximum number of consultants ${consNames.length} has been already reached`}
                    callback={(ret) => setShowErrorTooMany(false)}
                    severity='error' />
        </>
    )
}

type TCanAddCheckResult = 'Can' | 'Too many' | 'Duplicate'
function canAddConsultant(hospital:IOrg, consultantId: string): TCanAddCheckResult {
    let count = 0
    for(const x of consNames) {
        if (hospital[x as keyof IOrg] !== undefined) {
            count++
            if (hospital[x as keyof IOrg] === consultantId) {
                return 'Duplicate'
            }
        }
    }
    return count === consNames.length ? 'Too many' : 'Can'
}

function addConsultant(hospital:IOrg, consultantId: string) {
    const retval: IOrg = {...hospital}

    for(const x of consNames) {
        if (retval[x as keyof IOrg] === undefined) {
            (retval as any)[x]  = consultantId
            break
        }
    }
    return retval 
}

