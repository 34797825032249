import AlbumIcon from '@mui/icons-material/Album';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';

export type TSelectedView = 'current' | 'archive'
export interface IViewSelectorProps {
    initialSelection: TSelectedView
    selectionCallback: (selected: TSelectedView) => void
}

export default function ViewSelector(props: IViewSelectorProps) {
  const [selection, setSelection] = useState(props.initialSelection)

  const handleSelection = (
    event: React.MouseEvent<HTMLElement>,
    newSelection: TSelectedView,
  ) => {
    if (newSelection !== null) {
      setSelection(newSelection)
      props.selectionCallback(newSelection)
    }
  }

  return (
    <Box sx={{display:'flex', height:'0px', mt:'-10px'}}>
    <ToggleButtonGroup sx={{ml:'auto'}}
      value={selection}
      exclusive
      onChange={handleSelection}
    >
      <ToggleButton value={'current'} size='medium'>
        <RunningWithErrorsIcon />
      </ToggleButton>
      <ToggleButton value={'archive'} size='medium'>
        <AlbumIcon />
      </ToggleButton>
    </ToggleButtonGroup>
    </Box>
  )
}