import { combineReducers, configureStore } from '@reduxjs/toolkit'
import procHeaderReducer from './sliceRunProcHeader'
import lastSaveTimeReducer from './sliceLastSavedTimeStore'
import timelineReducer from './sliceTimeline'
import setupStepsReducer from './sliceSetup'
import finishChecksReducer from './sliceFinish'
import profileReducer from '../../common/ProfileSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'runStore',
  storage,
}

const runReducer = combineReducers({
  profileSlice: profileReducer,
  procHeaderSlice: procHeaderReducer,
  lastSavedTimeSlice: lastSaveTimeReducer,
  setupStepsSlice: setupStepsReducer,
  timelineSlice: timelineReducer,
  finishChecks: finishChecksReducer,
})

const persistedReducer = persistReducer(persistConfig, runReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
    }),
  })
 

export type RunState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store);

export const clearStorage = async () => {
  sessionStorage.clear()
  await persistor.purge()
}

