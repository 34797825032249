import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { TAppRoles } from '../utils/PrinicpalUtils'

function isMobileSafari (){
    return navigator.userAgent.match(/(AppleWebKit|Safari)/)
   
}
  

export default function EulaUnsignedDialog({ eulaSigned, role }: { eulaSigned: boolean, role: TAppRoles }) {
    const [open, setOpen] = useState(!eulaSigned)
    const navigate = useNavigate()
    const onAgreeClick = async () => {
        await fetch(`/api/setEluaFlag?role=${role}`)
        setOpen(false)
    }

    const onCancelClick = () => {
        navigate('/.auth/logout')
    }

    return (
        <Dialog open={open} fullWidth maxWidth='md'>
            <DialogTitle>
                Please read and agree to Traferox End User License Agreement
            </DialogTitle> 
            {isMobileSafari()
                ? pieceOfShitBrowserContent()
                : goodBrowserContent()}
            <DialogActions>
                <Button onClick={onAgreeClick}>I agree</Button>
                <Button onClick={onCancelClick}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

function pieceOfShitBrowserContent() {
    return (
        <DialogContent>
            <Link to='/TraferoxEula.pdf' target="_blank" rel="noopener noreferrer">Open EULA in a new window</Link>
        </DialogContent>

    )
} 

function goodBrowserContent() {
    return (
        <DialogContent style={{ overflow: "hidden" }}>
        <iframe
            src='/TraferoxEula.pdf?sidebar=0'
            title="PDF Viewer"
            width="100%"
            height="500px"
            style={{
                border: "none",
            }}
        />
        </DialogContent>
    )
}
