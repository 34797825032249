import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { AttachServiceRecord, CloneDevice, CloneServiceRecord, CreateServiceRecord, IDevice, IServiceRecord } from '../../../data/Device';
import ISimpleDialogProps from '../../../utils/ISimpleDialogProps';
import MessageAlert, { TAlertMessageType } from '../../../utils/MessageAlert';
import { useAppDispatch } from '../AdminReduxHooks';
import { createServiceAsync } from '../common/FetchApi';
import { update } from './DevicesSlice';



export interface IEditDialogProps extends ISimpleDialogProps {
    item: IDevice
}

export default function EditDialog(props: IEditDialogProps) {
    const [errorText, setErrorText] = useState<TAlertMessageType>(undefined)
    const [data, setData] = useState(CreateServiceRecord())
    const dispatch = useAppDispatch()

    const isValidDataKey = (x: string): x is keyof typeof data => x in data;

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        const updatedData = CloneServiceRecord(data)
        if (isValidDataKey(id)){
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            updatedData[id] = (id === 'isActive' ? (e.target as HTMLInputElement).checked : e.target.value)
        }
        setData(updatedData)
    }


    const showError = (message: TAlertMessageType) => {
        setErrorText(message)
        setTimeout(() => setErrorText(undefined), 15000)
    }

    const onOkClick = async () => {
        const updatedDevice = CloneDevice(props.item)
        updatedDevice.isOverdueForService = false
        AttachServiceRecord(updatedDevice, data)
        await createServiceAsync(updatedDevice, data)
        dispatch(update(updatedDevice))
        props.closeCallback(true)
    }

    const onCancelClick = () => {
        showError(undefined)
        props.closeCallback(false)
        
    }


    return (
        <Dialog open={props.show} fullWidth maxWidth='lg' >
            <DialogTitle>Service Log for <u><i>{props.item.label}</i></u>, S/N <u><i>{props.item.rowKey}</i></u></DialogTitle>
            <DialogContent>
                <Divider textAlign="left" sx={{ mb: '10px'}}>History</Divider>
                <MessageAlert msg={errorText} />
                <RecordsTable height={200} serviceRecords={props.item.serviceRecords}/>
                <Divider textAlign="left" sx={{ mb: '10px' }}>New service</Divider>
                <FormControl fullWidth>
                <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextField fullWidth label='Service Date (YYYY-MM-DD)'
                                value={data['rowKey']} id='rowKey' onChange={onChange}
                                required />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField fullWidth label='Serviced By'
                                value={data['servicedBy']} id='servicedBy' onChange={onChange}
                                required />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label='Notes' hiddenLabel
                                value={data['notes']} id='notes' onChange={onChange}
                                 />
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button onClick={onOkClick}>OK</Button>
            </DialogActions>
        </Dialog>)
}

function RecordsTable({height, serviceRecords} : { height: number, serviceRecords:IServiceRecord[]}) {
    const headerHeight = 32
    const bodyHeight = height - headerHeight - 10

    const dateWidth = 100
    const otWidth = 250
    const servicedByWidth = 200
    const notewWidth=300
    return (
        <Box height={height} >
            <Stack direction='row'>
                <Typography variant='subtitle1' width={dateWidth}><b>Date</b></Typography>
                <Typography variant='subtitle1' width={otWidth}><b>In Operation (HHH:MM)</b></Typography>
                <Typography variant='subtitle1' width={servicedByWidth}><b>Serviced By</b></Typography>
                <Typography variant='subtitle1' width={notewWidth}><b>Notes</b></Typography>
            </Stack>
        <Divider/>
            <Box sx={{overflowY:'auto'}} height={bodyHeight}>
                {serviceRecords.map((x) => 
                        <Stack key={x.rowKey} direction='row'>
                            <Typography width={dateWidth}>{x.rowKey}</Typography> 
                            <Typography width={otWidth}>{x.operatingTimeSincePreviousService}</Typography> 
                            <Typography width={servicedByWidth}>{x.servicedBy}</Typography> 
                            <Typography width={notewWidth}>{x.notes}</Typography> 
                        </Stack>
                    )
                }
            </Box>

        </Box>
    )
}

