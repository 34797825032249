import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProcHeader, IStartProcParams } from '../../../data/Procedure'
import { TechRootState } from '../TechStore'
import { doStartProcedureAsync } from './fetchApi'


async function fetchProceduresAsync(): Promise<Array<IProcHeader>> {
    const requestUrl = `/api/getProcedures?role=tech`
    const response = await fetch(requestUrl)
    if (response.ok) {
        const json = await response.json()
        return json
    } else {
        console.log(`Error fetching procedures. Status: ${response.status}`)
        return new Array<IProcHeader>()
    }
}

export const getProceduresAsync = createAsyncThunk(
    'procedures/fetchProcedures',
    async () => {
        const response = await fetchProceduresAsync()
        const procs = localStorage.getItem('procedures')
        if (response.length){
            localStorage.setItem('procedures', JSON.stringify(response))
        }
        if (procs && !response.length){
            return JSON.parse(procs)
        }

        return response
    }
)

export const getProceduresOffineAsync = createAsyncThunk(
    'procedures/fetchProceduresOffline',
    async () => {
        const procs = localStorage.getItem('procedures')
        if (procs){
            return JSON.parse(procs)
        }
        return null
    }
)

export const startProcedureAsync = createAsyncThunk(
    'procedures/startProcedure',
    async (startParams: IStartProcParams) => {
        await doStartProcedureAsync(startParams)
    }
)


export interface IProceduresState {
    all: IProcHeader[]
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IProceduresState = {
    all: [],
    status: 'idle'
}


export const proceduresSlice = createSlice({
    name: 'procedures',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<IProcHeader>) => {
            state.all.push(action.payload)
        },
        update: (state, action: PayloadAction<IProcHeader>) => {
            const index = state.all.findIndex(x => x.rowKey === action.payload.rowKey)
            if (index !== -1) {
                state.all[index] = action.payload
            }
        },
        remove: (state, action: PayloadAction<string>) => {
            const index = state.all.findIndex(x => x.rowKey === action.payload)
            if (index !== -1) {
                state.all.splice(index, 1)
            }

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProceduresAsync.pending, (state) => {
                state.all.splice(0)
                state.status = 'loading'
            })
            .addCase(getProceduresAsync.fulfilled, (state, action) => {
                state.status = 'idle'
                state.all = action.payload
            })
            .addCase(getProceduresOffineAsync.pending, (state) => {
                state.all.splice(0)
                state.status = 'loading'
            })
            .addCase(getProceduresOffineAsync.fulfilled, (state, action) => {
                state.status = 'idle'
                state.all = action.payload
            })
            .addCase(startProcedureAsync.fulfilled, (state, action) => {
                state.status = 'idle'
            });
    },
})

export const { add, update, remove } = proceduresSlice.actions

export const selectAll = (state: TechRootState) => state.proceduresSlice.all
export const selectStatus = (state: TechRootState) => state.proceduresSlice.status
export default proceduresSlice.reducer
