import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { CloneDevice, IDevice } from '../../../data/Device';
import ISimpleDialogProps from '../../../utils/ISimpleDialogProps';
import MessageAlert, { TAlertMessageType } from '../../../utils/MessageAlert';
import { useAppDispatch, useAppSelector } from '../AdminReduxHooks';
import { updateDeviceAsync } from '../common/FetchApi';
import { selectAll as selectAllLabs } from "../org/LabsSlice";
import OrgInfoDisplay from '../person/OrgInfoDisplay';
import { update } from './DevicesSlice';



export interface IEditDialogProps extends ISimpleDialogProps {
    item: IDevice
}

export default function EditDialog(props: IEditDialogProps) {
    const title = 'Edit Device'
    const [errorText, setErrorText] = useState<TAlertMessageType>(undefined)
    const lab = useAppSelector(selectAllLabs).find(x => x.rowKey === props.item.partitionKey)!
    const [data, setData] = useState(CloneDevice(props.item))
    const dispatch = useAppDispatch()

    const isValidDataKey = (x: string): x is keyof typeof data => x in data;

    const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        const updatedData = CloneDevice(data)
        if (isValidDataKey(id)){
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            updatedData[id] = (id === 'isActive' ? (e.target as HTMLInputElement).checked : e.target.value)
        }
        setData(updatedData)
    }


    const showError = (message: TAlertMessageType) => {
        setErrorText(message)
        setTimeout(() => setErrorText(undefined), 15000)
    }

    const onOkClick = async () => {
        await updateDeviceAsync(data) 
        dispatch(update(data))
        props.closeCallback(true)
    }

    const onCancelClick = () => {
        showError(<>That's an example of <strong>an ERROR</strong></>)
        props.closeCallback(false)
    }


    return (
        <Dialog open={props.show} fullWidth maxWidth='md' >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <MessageAlert msg={errorText} />
                <FormControl fullWidth>
                    <Divider textAlign="left" sx={{ mb: '10px'}}>Device</Divider>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <FormControlLabel sx={{mt:'10px'}} 
                                control={<Checkbox id='isActive' onChange={onChange} 
                                checked={data['isActive']}  />} label="Active" />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label='Serial# (YYMMDDNN)'
                                value={data['rowKey']} id='rowKey' onChange={onChange}
                                disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label='Label'
                                value={data['label']} id='label' onChange={onChange}
                                disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label='Commission Date (YYYY-MM-DD)' hiddenLabel
                                value={data['commissionDate']} id='commissionDate' onChange={onChange}
                                disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label='Time in operation since commission (HH:MM)'
                                value={data['totalOperatingTime']} id='totalOperatingTime' onChange={onChange}
                                disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label='Notes'
                                value={data['notes']} id='notes' onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                    <Divider textAlign="left" sx={{ mb: '10px', mt: '32px' }}>Belongs to organization</Divider>
                    <OrgInfoDisplay org={lab} />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button onClick={onOkClick}>OK</Button>
            </DialogActions>
        </Dialog>)
}