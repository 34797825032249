import { useAppSelector } from "../AdminReduxHooks"
import Page from "./Page"
import { selectAll, add, update } from "./DoctorsSlice"
import { selectAll as selectAllLabs } from "../org/HospitalsSlice"


export default function Doctor() {
    const techs = useAppSelector(selectAll)
    const orgs = useAppSelector(selectAllLabs)

    return (<Page items={techs} itemType={'doctor'} addAction={add} updateAction={update} orgs={orgs} />)
}
