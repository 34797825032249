import { Grid, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { IOrg } from '../../../data/Org';

export default function OrgInfoDisplay({org}:{org: IOrg}) {
    return (
        <Grid container spacing={1}>
        <Grid item xs={2}>
            <FormControlLabel sx={{mt:'10px'}} control={<Checkbox checked={org['isActive']} disabled />} label='Active' />
        </Grid>
        <Grid item xs={3}>
            <TextField fullWidth label='Label' 
                value={org['label']} disabled /> 
        </Grid>
        <Grid item xs={7}>
            <TextField fullWidth label='Full Name' 
                value={org['fullName']} disabled/>
        </Grid>
    </Grid>
    )
    
}