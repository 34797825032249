import { Box, IconButton, Link as MUILink, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { replaceHeader } from '../../utils/replaceHeader';
import WaitingLabel from '../../utils/WaitingLabel';
import { useAppDispatch, useAppSelector } from './doctorReduxHooks';
import { getProceduresAsync, selectStatus as selectProceduresStatus } from './ProceduresSlice';
import { getEluaFlagAsync } from '../../utils/PrinicpalUtils';
import EulaUnsignedDialog from '../../common/EluaUnsignedDialog';
import { getArchivedDoctorsAsync } from '../../common/sliceArchivedDoctors';
import { Close } from '@mui/icons-material';
import { getPreferencesAsync, getUserEmail } from '../../common/slicePreferences';
import HelpBox from '../../common/HelpBox';

export default function Doctor() {
    const [tabValue, setTabValue] = useState(0)
    const [reportUrl, setReportUrl] = useState('')
    const [eulaSigned, setEulaSigned] = useState<boolean | undefined>(undefined)
    const dispatch = useAppDispatch()
    const procStatus = useAppSelector(selectProceduresStatus)

    const updateUrl = (url: string):void=>{setReportUrl(url)}
    const updateTab = (num: number):void=>{setTabValue(num)}
    const navigate = useNavigate()

    useEffect(() => {
        replaceHeader('Surgeon', `${window.location.origin}/doctorIcon.ico`)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const flag = await getEluaFlagAsync('doctor');
                setEulaSigned(flag);
            } catch (error) {
                console.error('Error fetching flag:', error);
                setEulaSigned(false); // Set a default value or handle the error case
            }
            
        };
        fetchData();
    }, [])

    useEffect(() => {
        dispatch(getProceduresAsync())
        dispatch(getArchivedDoctorsAsync())

        const table = 'doctor'
        getUserEmail().then((eMail) => dispatch(getPreferencesAsync({table, eMail})))
    }, [dispatch])

    const handleTabValueChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }

    const closeReport = () => {
        navigate('/doctor/current')
        navigate(0)
        setReportUrl('')
    }

    if (eulaSigned === undefined) {
        return (<></>)
    } else {
        return (
            <>
                <Tabs value={tabValue} onChange={handleTabValueChange} sx={{ mb: '5px' }}>
                    <Tab value={0} label={<WaitingLabel title='Current' target={[procStatus]} />} to={'current'} component={Link} />
                    <Tab value={1} label={<WaitingLabel title='Archive' target={[]} />} to={'archive'} component={Link} />
                    { reportUrl !== ''
                      ? <Tab value={2} label={<WaitingLabel title={                        
                            <span>
                                Report 
                                <IconButton size="small" onClick={closeReport}> <Close/>  </IconButton>
                            </span>
                        } 
                            target={[]} />} to={`report`} component={Link}  iconPosition="end" />
                      : null
                    }
                </Tabs>

                <Box sx={{ position: 'absolute', top: 0, right: 8 }}>
                    <HelpBox/>
                    <MUILink href='/.auth/logout'>Log out</MUILink>
                </Box>

                <Outlet context={{reportUrl, updateUrl, updateTab}}/>

                <EulaUnsignedDialog eulaSigned={eulaSigned} role={'doctor'} />
            </>
        )
    }
}

