import { Typography } from '@mui/material';
import ActionsList, { IActionListItem } from './ActionsList';
import { useAppSelector } from './RunReduxHooks';
import { selectProcHeader } from './sliceRunProcHeader';

export default function RecruitmentX_30() {
    const procHeader = useAppSelector(selectProcHeader)
    const actions:IActionListItem[] = [
        {field:'IsBronchoscopyPeformed', altLabel:'Perform Bronchoscopy (optional)'},
        {field:'IsTidalVolumeIncreased', altLabel: <>Increase lung tidal volume over several breaths to <b>{procHeader!.recruitmentVV_mL!.toFixed(0)}</b> ml</>},
        {field: 'IsHoldsPerformed'},
        {field:'IsTidalVolumeRestored', altLabel:<>Reset the tidal volume to <b>{procHeader!.steadyStateVV_mL!.toFixed(0)}</b> ml</>}
    ]
    return (
        <>
        <Typography variant='h6' sx={{textAlign:'center'}}>Perform lung recruitment maneuver</Typography>
        <ActionsList actions={actions}/>
        </>
    )
}