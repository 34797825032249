import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { IProcHeader } from '../../data/Procedure';
import { TStep } from '../../data/WorkflowSteps';
import { useAppDispatch } from '../tech/TechReduxHooks';
import { useAppSelector } from './monitorReduxHooks';
import { getNotesAsync } from './silceNotes';
import { selectProcHeader } from './sliceMonitorProcHeader';



export default function NotesEditDialog({ show, notesBefore, stepHour, onFinish }:
    {show: boolean, notesBefore:string , stepHour: TStep, onFinish: () => void }) {
    const [isUploading, setIsUploading] = useState(false)
    const procHeader = useAppSelector(selectProcHeader)
    const dispatch = useAppDispatch()
    const [notes, setNotes] = useState(notesBefore)
    const onNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(e.target.value)
    }
    

    const onOk = async () => {
            setIsUploading(true)
            await uploadNotes(notes, stepHour, procHeader)
            setIsUploading(false)
            onFinish()
            dispatch(getNotesAsync({partitionKey:procHeader!.partitionKey, rowKey: procHeader!.rowKey}))
    }

    return (
        <Dialog open={show} maxWidth='lg'>
            <DialogTitle>
                Add/Edit notes
            </DialogTitle>
            <TextField rows='15' sx={{ml:'15px', mr:'15px', mt:'5px', mb:'15px', height:'400px', width:'800px'}} 
                    multiline value={notes} onChange={onNotesChange} label='Notes'/>
            <DialogActions>
                <Button disabled={isUploading} onClick={() => onFinish()}>Cancel</Button>
                <Button disabled={isUploading} onClick={() => onOk()}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

async function uploadNotes(content: string, stepHour: TStep, procHeader: IProcHeader | null) {
    if (procHeader === null) {
        return
    }
    const formData = new FormData();
    formData.append(
        'notes',
        content
    );
    /*const response = */ await fetch( // TODO: deal with the errors here
        `/api/addReplaceNotes?device=${procHeader?.performedOn}&proc=${procHeader?.rowKey}&time=${stepHour}`
        , {
            method: 'POST',
            body: formData
        })
}