import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TFlagField } from '../../data/ProcData';
import { TTimelineStep } from '../../data/WorkflowSteps';
import DF from './DictionaryFlagField';
import EntryItem from './RampupEntryItem';
import { useAppSelector } from './RunReduxHooks';
import { selectData } from './sliceTimeline';

export interface IActionListItem {
    field:TFlagField,
    altLabel?: ReactNode 
    secondLine?: ReactNode
}

export interface IActionListProps {
    actions: IActionListItem[]
    completionCallback?: (isComplete: boolean) => void
    mandatoryFields?: TFlagField[] 
}

export default function ActionsList(props: PropsWithChildren<IActionListProps>) {
    const { step } = useParams() 
    const stepData = useAppSelector(selectData)[step as TTimelineStep]

    
    useEffect(() => {
        if (props.mandatoryFields && props.completionCallback) {
            const allChecked =  props.mandatoryFields.reduce(
                (accumulator, current) => accumulator && (stepData[current] as boolean ?? false), true)
            props.completionCallback(allChecked)
        }
    }, [stepData, props] )

    
    return (
        <>
            {props.actions.map(x => 
                <EntryItem key={x.field}>
                    <DF  flagName={x.field} step={step! as TTimelineStep} altLabel = {x.altLabel}/>
                    {x.secondLine !== undefined ? x.secondLine : null}
                </EntryItem>
            )}
            {props.children}
        </>
    )    
}