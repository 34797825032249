import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { selectProfile } from '../../../common/ProfileSlice';
import { useAppSelector } from '../TechReduxHooks'

export default function About(){
    const profile = useAppSelector(selectProfile)
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} >
                <Card >
                    <CardHeader title='Organization' titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent>
                        {profile?.org.fullName}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card >
                    <CardHeader title='Staff' titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent>
                        {profile?.staff.map(x =>
                            <Grid container spacing={1} key={x.rowKey}>
                                <Grid item xs={1}>{x.honorific}</Grid>
                                <Grid item xs={3}>{x.firstName}</Grid>
                                <Grid item xs={3}>{x.lastName}</Grid>
                                <Grid item xs={5}>{x.eMail}</Grid>
                            </Grid>)}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card >
                    <CardHeader title='Devices'  titleTypographyProps={{fontWeight:'bold'}}/>
                    <CardContent>
                            {profile?.devices.map(x => 
                                <Grid container spacing={1} key={x.rowKey}>
                                    <Grid item xs={3}>{x.rowKey}</Grid>
                                    <Grid item xs={3}>{x.label}</Grid>
                                </Grid>)}
                    </CardContent>
                </Card>
            </Grid>


        </Grid>
    )
}