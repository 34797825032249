import { Paper } from "@mui/material";
import { ReactNode, useState, useRef, useEffect, useLayoutEffect } from "react";

export interface IResizableTableProps {
    header: ReactNode, 
    body: ReactNode,
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    };
}

export function ResizableTable(props: IResizableTableProps) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const parentRef = useRef<HTMLDivElement>(null)
    const [bodyHeight, setBodyHeight] = useState(0)
    const TabBarHeight =  120 

    useEffect(() =>{
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
      
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);        
    }, [windowDimensions.height])

    useLayoutEffect(() =>{
        if (parentRef?.current?.offsetTop) {
            setBodyHeight(windowDimensions.height - parentRef.current!.offsetTop! - TabBarHeight)
        }

    }, [windowDimensions.height] )

    return(
    <Paper ref={parentRef} elevation={3} sx={{padding:'10px'}}>
        {props.header}
        <div style={{height:bodyHeight, overflowY:'auto'}}>
            {props.body}
        </div>
    </Paper>)
}
