import { Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AutoScrollContainer from '../../utils/AutoScrollContainer'
import { BMFinished } from './BottomMenu'
import { getAllChecked, isLungsFinished } from './finishUtils'
import FTab1Lungs from './FTab1Lungs'
import FTab2System from './FTab2System'
import { RHFinished } from './RunHeader'
import { useAppSelector } from './RunReduxHooks'
import { selectFinishChecks } from './sliceFinish'
import { selectProcHeader } from './sliceRunProcHeader'
import { TabIcon, TabPanel } from './utilsTabView'

export default function UIFinish() {

    const [tabValue, setTabValue] = useState(0)
    const finishChecks = useAppSelector(selectFinishChecks)
    const procHeader = useAppSelector(selectProcHeader)
    const handleTabValueChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }


    const [allLungs, setAllLungs] = useState(isLungsFinished(procHeader?.isLungsAccepted, finishChecks.lungsChecks))
    const [allSystem, setAllSystem] = useState(getAllChecked(finishChecks.systemChecks))
    const [isNextAvailable, setIsNextAvailable] = useState(allLungs && allSystem)
    const [declined, setDeclined] = useState(false)
    const [accepted, setAccepted] = useState(false)
    const [count, setCount] = useState(0)

    useEffect(() => {
        setIsNextAvailable((allLungs && count === 3) || declined)
    }, [allLungs, allSystem, declined, count])

    return (
        <>
            <RHFinished />
            <Tabs value={tabValue} onChange={handleTabValueChange} style={{ height: '60px' }}>
                <Tab label={<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Outcome</Typography>} icon={TabIcon((allLungs && count === 3) || declined)} iconPosition='end' />
                <Tab label={<Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>System</Typography>} icon={TabIcon(allSystem)} iconPosition='end' disabled={!(allLungs && count === 3) && !declined}/>
            </Tabs>

            <TabPanel value={tabValue} index={0}>
                <FTab1Lungs completion={(isComplete) => {
                    setAllLungs(isComplete)}} 
                    declined={declined}
                    setDeclined={setDeclined}
                    accepted={accepted}
                    setAccepted={setAccepted}
                    count={count}
                    setCount={setCount}
                    />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <AutoScrollContainer bottomOffset={70}>
                    <FTab2System completion={(isComplete) => setAllSystem(isComplete)} />
                </AutoScrollContainer>
            </TabPanel>
            <BMFinished isNextAvailable={isNextAvailable}/>
        </>
    )
}


