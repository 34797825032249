const SECONDS_IN_MINUTE_NAME = 'SECONDS_IN_MINUTE'
const BYPASS_WAITING_FOR_NEXT_NAME = 'BYPASS_WAITING_FOR_NEXT' 

export function getBypassWaitingForNext() {
    const BYPASS_WAITING_FOR_NEXT = false
    const fromStorage = sessionStorage.getItem(BYPASS_WAITING_FOR_NEXT_NAME)
    if (fromStorage) {
        return fromStorage === 'true' ? true : false
    } else { 
        return BYPASS_WAITING_FOR_NEXT
    }
}

export function setBypassWaitingForNext(newValue: boolean) {
    sessionStorage.setItem(BYPASS_WAITING_FOR_NEXT_NAME, newValue ? 'true' : 'false')
}

export function clearBypassWaitingForNext() {
    if (sessionStorage.getItem(BYPASS_WAITING_FOR_NEXT_NAME)) {
        sessionStorage.removeItem(BYPASS_WAITING_FOR_NEXT_NAME)
    }
}


export function getSecondsInMinute() {
    const SECONDS_IN_MINUTE = 60
    const fromStorage = sessionStorage.getItem(SECONDS_IN_MINUTE_NAME)
    if (fromStorage) {
        return parseInt(fromStorage)
    } else { 
        return SECONDS_IN_MINUTE
    }
}

export function setSecondsInMinute(newValue: number) {
    if (newValue < 5 || newValue > 60) {
        throw new RangeError(`The value ${newValue} couln't be used as warp factor. Allowed values range is 5..60`)
    }
    sessionStorage.setItem(SECONDS_IN_MINUTE_NAME, newValue.toFixed(0))
}

export function clearSecondsInMinute() {
    if (sessionStorage.getItem(SECONDS_IN_MINUTE_NAME)) {
        sessionStorage.removeItem(SECONDS_IN_MINUTE_NAME)
    }
}

export function getTimeFromStartMin(startTime: number, currentTime: number) {
    return (Math.floor((currentTime - startTime) / (1000 * getSecondsInMinute())))
}


export function getStepRemainingTimePercent(startTime: number, currentTime: number, currentStepStartMin: number, nextStepStartMin: number) {
    const currentStepStartTimeSec = currentStepStartMin * getSecondsInMinute()
    const nextStepStartSec = nextStepStartMin * getSecondsInMinute()
    const currentTimeSec = getTimeFromStartMin(startTime, currentTime) * getSecondsInMinute()
    const totalStepDurationSec = nextStepStartSec - currentStepStartTimeSec
    const currentStepDurationSec = currentTimeSec - currentStepStartTimeSec
    return (currentStepDurationSec / totalStepDurationSec) * 100
}

export interface ITimeElasped {
    hh: string
    mm: string
    ss: string
}

function numberToStrLen2(x: number) {
    return x.toString().length === 2 ? x.toString() : '0' + x.toString()
}


export function getTimeFromStartHHMMSS(startTime: number, currentTime: number): ITimeElasped {
    const seconds = Math.floor((currentTime - startTime) / 1000)
    const minutes = Math.floor(seconds / getSecondsInMinute())
    const hours = Math.floor(minutes / 60)
    const hh = hours
    const mm = minutes - hh * 60
    const ss = seconds - (hh * 60 * getSecondsInMinute()) - mm * getSecondsInMinute()
    return {
        hh: numberToStrLen2(hh),
        mm: numberToStrLen2(mm),
        ss: numberToStrLen2(ss)
    }
}


export function getWarpedMilliseconds(minutes: number) {
    return 1000 * getSecondsInMinute() * minutes
}
