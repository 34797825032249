import CheckIcon from '@mui/icons-material/Check'
import { Divider, Stack, Typography } from '@mui/material'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { Fragment } from 'react'
import { IOrg } from '../../../data/Org'
import EditEntityButton from './EditEntityButton'
import CloseIcon from '@mui/icons-material/Close';

const editWidth = '36px'
const labelWidth = '100px'
const fullNameWidth = '350px'
const contactWidth = '250px'
const isActiveWidth = '16px'
const InsighTxPerm = '250px'
const permRightMargin = '170px'
const permLeftMargin = '80px'


export function Header(org: { type: "lab" | "hospital"}) {
    return (
        <Fragment>
        <Stack direction='row'>
            <Typography variant="h6" width={editWidth}> </Typography>
            <Typography variant='h6' width={labelWidth}>Label</Typography>
            <Typography variant="h6" width={fullNameWidth}>Full Name</Typography>
            <Typography variant="h6" width={contactWidth}>Contact eMail</Typography>
            {org.type === 'hospital'? 
                <Typography variant="h6" width={InsighTxPerm}>InsighTx Permission?</Typography>
                : null
            }
            <Typography variant="h6" width={isActiveWidth}>Active?</Typography>
        </Stack>
        <Divider/>
        </Fragment>
    )
}

export function Body(props: {organizations: Array<IOrg>, updateAction: ActionCreatorWithPayload<IOrg, string>, org:  "lab" | "hospital"}) {
    return (
        <Fragment>
         { props.organizations.map((x) => 
            <Stack direction='row' key={x.rowKey}>
                <EditEntityButton width={editWidth} item={x} items={props.organizations} updateAction={props.updateAction}/>
                <Typography width={labelWidth}>{x.label}</Typography>
                <Typography width={fullNameWidth}>{x.fullName}</Typography>
                <Typography width={contactWidth}>{x.contact}</Typography>
                {props.org === 'hospital'?
                    <>
                        {x.insighTxPermission? 
                            <CheckIcon style={{marginRight: permRightMargin, marginLeft: permLeftMargin}} fontSize='small'/>
                            : <CloseIcon style={{marginRight: permRightMargin, marginLeft: permLeftMargin, opacity: '0'}} fontSize='small'/>
                        }
                    </> 
                    : null
                }
                {x.isActive ? <CheckIcon width={isActiveWidth} fontSize='small'/> : null}
            </Stack>)} 
        </Fragment>
    )
}
