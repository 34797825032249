import { Box, Divider, FormControl, Grid, Stack, Typography } from '@mui/material';
import { EVLPIndicationsDataDictionary, TEVLPIndicationsDataDictionary } from '../data/ddEVLPIndications';
import { IOrg } from '../data/Org';
import { IProcHeader, TProcDisposition } from '../data/Procedure';
import getDateFromMilliseconds from './getDateFromMilliseconds';
import { TStep, isAssessmentStep, isRampUpStep, isRecruitmentStep, isSetupStep } from '../data/WorkflowSteps';
import gD from '../../src/common/getDateFromMilliseconds';
import dayjs from 'dayjs';



export default function ProcInfoView({ d, hospitals }: { d: IProcHeader, hospitals?: IOrg[] | undefined }) {
    const startedLocal = (d: IProcHeader) => {
        if (d.startTime === 0) {
            return (null)
        } else {
            return (
                <><strong>Started at (Local):</strong> {getDateFromMilliseconds(d?.startTime, 'local')}</>
            )
        }
    }

    const lastUpdatedAt = (d: IProcHeader) => {
        if (d.startTime === 0) {
            return (null)
        } else {
            return (
                <><strong>Last updated at:</strong>  {getDateFromMilliseconds(d?.currentTime, 'local')}</>
            )
        }
    }

    const currentDuration = (d: IProcHeader) => {
        if (d.startTime === 0) {
            return (null)
        } else {
            return (
                <><strong>Current duration:</strong> {getDateFromMilliseconds(
                    d === null || d.currentTime === undefined || d.startTime === undefined
                        ? undefined
                        : d.currentTime - d.startTime, 'duration')}</>

            )
        }
    }

    const getStateBgcolor = (dispositon: TProcDisposition) => {
        switch (dispositon) {
            case 'init': return 'white'
            case 'ongoing': return 'lightblue'
            case 'idle': return 'lightgrey'
            case 'finished': return 'lightgreen'
            case 'abandoned': return 'orange'
        }
    }

    const getStateClarification = (step: TStep) => {
        if (isSetupStep(step)) return ` (setup; ${step})`
        if (isRampUpStep(step)) return ` (ramp up; ${step})`
        if (isAssessmentStep(step)) return ` (assessment; ${step})`
        if (isRecruitmentStep(step)) return ` (recruitment; ${step})`
        return  ` ${step}`
    }

    let CIT1 = ''
    if (d.crossClampDT === undefined || d.crossClampDT == null){
        CIT1 = 'N/A'
    }
    else if (d.startTime !== 0 && d.crossClampDT) {
        CIT1 = `${gD(d.startTime - d.crossClampDT, 'duration')}`
    }


    return (
        <FormControl fullWidth>
            <Grid container justifyContent="space-between">
                <Grid item xs={8}>
                    <strong>Procedure information</strong>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <b>State:&nbsp;&nbsp;</b><Box sx={{ background: getStateBgcolor(d.disposition) }}>
                        &nbsp;{d.disposition + (d.disposition === 'ongoing' ? getStateClarification(d.step) : '')}&nbsp;</Box>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={1} sx={{ mt: '3px' }} >
                <Grid item xs={4} >
                    <b>EVLP ID: </b> {d.evlpCaseNumber}
                </Grid>
                <Grid item xs={4} >
                    <b>Perform on: </b>{d.performedOn}
                </Grid>
                <Grid item xs={4} >
                    {hospitals
                        ? <><b>Notify: </b>{hospitals?.find(x => x.rowKey === d.notify)?.fullName}</>
                        : null
                    }
                </Grid>
            </Grid>
            {d.disposition !== 'init'
                ? <Grid container spacing={1} sx={{ mb: '10px', mt: '3px' }}>
                    <Grid item xs={4} >
                        <b>Perfusionist: </b> {d.perfusionist}
                    </Grid>
                    <Grid item xs={4}>
                        <b>EVLP kit #: </b> {d.evlpKitNumber}
                    </Grid>
                    <Grid item xs={4}>&nbsp;</Grid>
                    <Grid item xs={4}>{startedLocal(d)}</Grid>
                    <Grid item xs={4}>{lastUpdatedAt(d)}</Grid>
                    <Grid item xs={4}>{currentDuration(d)}</Grid>
                </Grid>
                : null
            }
            <b>Donor information</b>
            <Divider />
            <Grid container spacing={1} sx={{ mb: '10px', mt: '3px' }}>
                <Grid item xs={4}>
                    <b>Cross Clamp D/T: </b> {
                        d.crossClampDT?
                            dayjs(new Date(d.crossClampDT)).format('YYYY-MM-DD HH:mm')
                            : <>N/A</>
                        }
                </Grid>
                <Grid item xs={4}>
                    <b>Donor Type: </b>{d.donorType}
                </Grid>
                <Grid item xs={4}>
                    <b>Donor Blood Type: </b>{d.bloodType}
                </Grid>
                <Grid item xs={4}>
                    <b>Age(years): </b>{d.age_years}
                </Grid>
                <Grid item xs={4}>
                    <b>Height (cm): </b>{d.height_cm}
                </Grid>
                <Grid item xs={4}>
                    <b>Weight (kg): </b>{d.weight_Kg}
                </Grid>
                <Grid item xs={4}>
                    <b>Sex: </b>{d.sex}
                </Grid>
                <Grid item xs={4}>
                    <b>pTLC: </b>{d.pTLC}
                </Grid>
                <Grid item xs={4}>
                    {CIT1 !== ''
                        ? <><b>CIT1: </b>{CIT1}</>
                        : <><b>CIT1: </b>N/A</>
                    }
                </Grid>
            </Grid>
            <EVLPIndications d={d} />
        </FormControl>

    )
}


export function EVLPIndications({ d }: { d: IProcHeader }) {
    const shouldShow = Object.keys(EVLPIndicationsDataDictionary).reduce(
        (accumulator, currentValue) => accumulator || d[currentValue as keyof IProcHeader] !== undefined, false)

    if (!shouldShow) {
        return null
    }
    return (
        <>
            <b>EVLP Indications</b>
            <Divider />
            <Grid container spacing={1} sx={{ mt: '3px' }} rowSpacing={'10px'}>
                {Object.keys(EVLPIndicationsDataDictionary).map(x =>
                    x !== 'otherIndications' && d[x as keyof IProcHeader]
                        ? <Grid item key={x} xs={2}>{EVLPIndicationsDataDictionary[x as keyof TEVLPIndicationsDataDictionary].label}</Grid>
                        : null)}
                <Grid item xs={12}>
                    {d.otherIndications
                        ? <Grid item xs={12} key={Math.random()}>
                            <Stack direction='column'>
                                <b>Other indications:</b>
                                <Typography border={1} height='30px' p='3px'>{d.otherIndications}</Typography>
                            </Stack>
                        </Grid>
                        : null
                    }
                </Grid>
            </Grid>
        </>
    )
}
