import { Button, Card, CardActions } from '@mui/material'
import ProcInfoView from '../../common/ProcInfoView'
import { IProcHeader, TProcDisposition } from '../../data/Procedure'

export default function ProcCard(
    { d, onFirstButton, sx }:
        { d: IProcHeader, onFirstButton: (i: IProcHeader) => void, sx?: any }) {



    return (
        <Card variant='elevation' elevation={3} sx={{ ...sx, p: 1, m: 1 }}>
            <ProcInfoView d={d}/>
            <CardActions>
                {d.disposition !== 'init'
                    ? <Button size="small" onClick={() => onFirstButton(d)}>{getFirstButtonLabel(d.disposition)}</Button>
                    : null
                }
            </CardActions>
        </Card>

    )
}

function getFirstButtonLabel(disposition: TProcDisposition) {
    switch (disposition) {
        case 'init': return '';
        case 'ongoing': return 'Monitor';
        case 'idle': return 'Monitor idle'
        case 'finished': return 'Review';
    }
}