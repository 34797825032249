import { Divider, Stack, Typography } from '@mui/material'
import { Fragment, ReactElement } from 'react'
import { CalculatedFields, TCalculatedField, TDataField } from '../data/ProcData'
import { AllAssessmentSteps, TTimelineStep, getAssessmentPointLabel } from '../data/WorkflowSteps'
import { dataDictionary } from '../data/dd'
import { ReportRows } from './reportRows'
import { TDataCell, TTransposedData, TTrasposedDataContainer } from './transposeData'

const LegendWidth = '25%'
const CellWidth = '50px'

export type TTabulatedViewFieldClickedCallback = (step: TTimelineStep, field: TDataField) => void



export default function TabulatedDataView({ data, editFieldCallback }: 
                { data: TTrasposedDataContainer, editFieldCallback?: TTabulatedViewFieldClickedCallback }) {


    return (
        <>
            {renderHeader(data.dataLength)}
            {ReportRows.map(x => RenderRow(data.data, x, data.dataLength, editFieldCallback))}
        </>
    )

}



function RenderRow(data: TTransposedData, x: TDataField, dataLength: number,
    editFieldCallback?: TTabulatedViewFieldClickedCallback): ReactElement {
    function cellContent(x:TDataField, n: TDataCell, index: number, allCells: import("./transposeData").TDataCell[]): import("react").ReactNode {

        if (n === undefined || n === null) {
            return (<>-&nbsp;</>)
        } else {
            return (<>{`${n}`}</>)
        }
    }

    return (
        <Fragment key={Math.random()}>
            <Stack key={Math.random()} sx={{ width: '100%', minHeight: '28px' }} direction='row'>
                <Typography sx={{ fontWeight: 'bold', width: LegendWidth, maxWidth: LegendWidth, minWidth: LegendWidth, overflow: 'wrap', textAlign:'right' }}>
                    {dataDictionary[x].label} ({dataDictionary[x].unit})
                </Typography>
                <Stack direction='row' spacing={'1px'}>
                    {data[x].map(
                        (n, index, allCells) => <Typography key={AllAssessmentSteps[index]}
                            sx={
                                editFieldCallback && !CalculatedFields.includes(x as TCalculatedField)
                                ? { cursor: 'pointer', background: 'lightgrey', width: CellWidth, minWidth: CellWidth, textAlign: 'right' }
                                : { background: 'lightgrey', width: CellWidth, minWidth: CellWidth, textAlign: 'right' }}
                            onClick={() => editFieldCallback ? editFieldCallback(AllAssessmentSteps[index], x) : null}>
                            {cellContent(x, n, index, allCells)}
                        </Typography>)}
                </Stack>
            </Stack>
            <Divider sx={{ m: '0px' }} />
        </Fragment>
    )
}


function renderHeader(maxLength: number): ReactElement {
    const columns = AllAssessmentSteps.slice(0, maxLength)
    return (
        <Fragment key={Math.random()}>
            <Stack key={Math.random()} sx={{ width: '100%' }} direction='row'>
                <Typography sx={{ fontWeight: 'bold', width: LegendWidth, maxWidth: LegendWidth, minWidth: LegendWidth, overflow: 'wrap' }}>&nbsp;</Typography>
                <Stack direction='row'>
                    {columns.map(x => 
                        <Typography key={Math.random()} sx={{ width: CellWidth, minWidth: CellWidth, textAlign: 'right', fontWeight: 'bold' }} >
                            {getAssessmentPointLabel(x)}
                        </Typography>)}
                </Stack>
            </Stack>
            <Divider sx={{ m: '0px' }} />
        </Fragment>
    )
}
