import { TDataDictionary } from './ddBase';


/******************************************************************************* 
*
* NOTE: This dictionary is duplicated in dataDictionaryStringified.ts
*     if updates are necessary for the object below, they need to be made
*     in the file mentioned above as well
*
********************************************************************************/
export const dataDictionary: TDataDictionary = {
    // Perfusate
    PerfusateFlow: { label: 'Perfusate flow', unit: 'ml/min', step: 100, min: 0, max: 3000 },
    PerfusateLevelInitial: { label: 'Perfusate level', unit: 'ml', step: 10, min: 250, max: 2500 },
    PerfusateLevelPre: { label: 'Perfusate level', unit: 'ml', step: 10, min: 250, max: 2500 },
    PerfusateAddedPre: { label: 'Perfusate added', unit: 'ml', step: 10, min: 0, max: 1000 },
    PerfusateLevelPost: { label: 'Perfusate level (post-assessment)', unit: 'ml', step: 10, min: 250, max: 2500 },
    PerfusateAddedExchange: { label: 'Perfusate added', unit: 'ml', step: 10, min: 0, max: 1000 },
    PerfusateRemovedExchange: { label: 'Perfusate removed', unit: 'ml', step: 10, min: 0, max: 500 },
    PerfusateLevelPostExchange: { label: 'Perfusate level (post-exchange)', unit: 'ml', step: 10, min: 250, max: 2500 },
    PerfusateTemp: { label: 'Perfusate temperature', unit: '°C', step: .1, min: 10, max: 40 },

    // Pressure/EVLP gas Flow
    LAPressure: { label: 'LA Pressure', unit: 'mm Hg', step: 1, min: -10, max: 20, minRecomended: 3, maxRecomended: 5 },
    PAPressure: { label: 'PA Pressure', unit: 'mm Hg', step: 1, min: 0, max: 30, isUpRed: true },
    PumpSpeed: { label: 'Pump Speed', unit: 'RPM', step: 100, min: 0, max: 6000 },
    EVLPGasFlow: { label: 'EVLP Gas Flow', unit: 'l/min', step: .1, min: 0, max: 2.5 },

    // Ventilation
    PeakAWP: { label: 'Peak Airway Pressure', unit: <>cm H<sub>2</sub>O</>, step: 1, min: 5, max: 35, isUpRed: true },
    MeanAWP: { label: 'Mean Airway Pressure', unit: <>cm H<sub>2</sub>O</>, step: 1, min: 5, max: 15, isUpRed: true },
    PlateauAWP: { label: 'Plateau Airway Pressure', unit: <>cm H<sub>2</sub>O</>, step: 1, min: 5, max: 35, isUpRed: true },
    DynamicCompliance: { label: 'Dynamic Lung Compliance', unit: <>ml/cm H<sub>2</sub>O</>, step: 1, min: 10, max: 250, isUpRed: false },
    StaticCompliance: { label: 'Static Lung Compliance', unit: <>ml/cm H<sub>2</sub>O</>, step: 1, min: 10, max: 250, isUpRed: false },

    //ABG
    LAGlucose: { label: 'LA Glucose', unit: 'mmol/l', step: 0.1, min: 0, max: 15 },
    LALactate: { label: 'LA Lactate', unit: 'mmol/l', step: 0.1, min: 0, max: 20 },
    LApH: { label: 'LA pH', unit: '', step: .01, min: 5, max: 7.5 },
    LAPO2: { label: <>LA PO<sub>2</sub></>, unit: 'mm Hg', step: 1, min: 0, max: 750 },
    LAPCO2: { label: <>LA PCO<sub>2</sub></>, unit: 'mm Hg', step: 1, min: 10, max: 50 },
    PApH: { label: 'PA pH', unit: '', step: .01, min: 5, max: 7.5 },
    PAPO2: { label: <>PA PO<sub>2</sub></>, unit: 'mm Hg', step: 1, min: 0, max: 150 },
    PAPCO2: { label: <>PA PCO<sub>2</sub></>, unit: 'mm Hg', step: 1, min: 10, max: 50 },
    ReservoirVolume: { label: 'Reservoir Volume', unit: 'ml', step: 100, min: 200, max: 1500 },

    // PVGA
    RUVPO2: { label: <>Right Upper Vein PO<sub>2</sub></>, unit: 'mm Hg', step: 1, min: 0, max: 750, isUpRed: false },
    RLVPO2: { label: <>Right Lower Vein PO<sub>2</sub></>, unit: 'mm Hg', step: 1, min: 0, max: 750, isUpRed: false },
    LUVPO2: { label: <>Left Upper Vein PO<sub>2</sub></>, unit: 'mm Hg', step: 1, min: 0, max: 750, isUpRed: false },
    LLVPO2: { label: <>Left Lower Vein PO<sub>2</sub></>, unit: 'mm Hg', step: 1, min: 0, max: 750, isUpRed: false },

    // Flag fields
    IsVentilationSettingsInAssessment: { label: 'Switch ventilation parameters to assessment parameters', step: 1 },
    IsXrayPerformed: { label: 'Perform X-ray  (optional)', step: 1 },
    IsBronchoscopyPeformed: { label: 'Perform Bronchoscopy (optional)', step: 1 },
    IsDeflationTestPerformed: { label: 'Perform lung deflation test', step: 1 },
    IsPhotoTaken: { label: 'Take photos of lung', step: 1 },
    IsTidalVolumeIncreased: { label: '', step: 1 },
    IsHoldsPerformed: { label: 'Perform 3 inspiratory holds for 10 seconds each', step: 1 },
    IspEVLPgasFlowAdjusted: { label: '', step: 1 },
    IsNormalVentParamsRestored: { label: 'Restore normal ventilation parameters', step: 1 },
    IsTidalVolumeRestored: { label: '', step: 1 },

    // Calculated fields
    PVResistance: { label: 'Pulmonary Vascular Resistance', unit: <>dynes / s . cm<sup>5</sup></>, step: 1 },
    PerfusateLoss: { label: 'Perfusate loss', unit: 'ml', step: 10, isUpRed: true },
    DeltaPF: { label: <>ΔPO<sub>2</sub> / FiO<sub>2</sub></>, unit: 'mm Hg', step: 1, isUpRed: false },
    DeltaPFRUV: { label: <>RUV ΔPO<sub>2</sub> / FiO<sub>2</sub></>, unit: 'mm Hg', step: 1, isUpRed: false },
    DeltaPFRLV: { label: <>RLV ΔPO<sub>2</sub> / FiO<sub>2</sub></>, unit: 'mm Hg', step: 1, isUpRed: false },
    DeltaPFLUV: { label: <>LUV ΔPO<sub>2</sub> / FiO<sub>2</sub></>, unit: 'mm Hg', step: 1, isUpRed: false },
    DeltaPFLLV: { label: <>LLV ΔPO<sub>2</sub> / FiO<sub>2</sub></>, unit: 'mm Hg', step: 1, isUpRed: false },
    
    // Utility fields
    IsMandatoryDataEntered: {label: '', step: 1},
    IsDataEntryFinished: { label: '', step: 1 },
    StepEnteredAt: { label: '', step: 1 },
}





                        