import { Box, Button, Dialog, DialogActions, Divider, FormControl, Grid, Stack, Tab, Tabs, Typography } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { selectHospitals } from '../../common/ProfileSlice'
import TabulatedDataView from '../../common/TabulatedDataView'
import { transposeData } from '../../common/transposeData'
import { CalculatedFields, TCalculatedField, TDataField, TEntryField } from '../../data/ProcData'
import { TStep, TTimelineStep, isAssessmentStep, isRampUpStep, isRecruitmentStep, isSetupStep } from '../../data/WorkflowSteps'
import { DictionaryFormNumberField } from './DictionaryNumberField'
import { useAppDispatch, useAppSelector } from './RunReduxHooks'
import { getSecondsInMinute } from './allTime'
import { selectCurrentTime, selectProcHeader, selectStartTime, setHeader, updateCurrentTime } from './sliceRunProcHeader'
import { selectData, setfield } from './sliceTimeline'
import { IProcHeader, TProcDisposition } from '../../data/Procedure'
import UIRampupVentParameters from './UIRampupVentParameters'
import { rampUp } from './UIClinicalData'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DonorDataTabContent from './DonorDataTab'
import getDateFromMilliseconds from '../../common/getDateFromMilliseconds'

import { updateActionLogsAsync } from '../admin/common/FetchApi'
import { selectLastSavedTime } from './sliceLastSavedTimeStore'

export interface IUIInfoProps {
    show: boolean
    closeCallback: () => void
    tabbedView?: boolean
}



export default function UIInfo({ show, closeCallback, tabbedView }: IUIInfoProps) {
    const warp = getSecondsInMinute()
    const dispatch = useAppDispatch()
    const warpPhrase = warp !== 60 
                        ? <Typography sx={{color:'red'}}>Speeding up. Seconds in minute {warp}</Typography>
                        : null

    const readonlyHeader = useAppSelector(selectProcHeader)
    const lastSavedTime = useAppSelector(selectLastSavedTime)

    const [d, setD] = useState({...readonlyHeader!, lastUpdatedAt: lastSavedTime})
    const setD2 = (d: IProcHeader) => {
        let newData = {...d}
        dispatch(updateCurrentTime(Date.now()))
        setD(newData)
    } 


    return (
        <Dialog maxWidth='xl' fullWidth open={show} >
            <DialogTitle>Traferox EVLP Wizard v{`${process.env.REACT_APP_VERSION}`} {warpPhrase} </DialogTitle>
            <Box sx={{pl:3, pr:3, mt:'-10px'}}>
                {tabbedView 
                    ?
                    <ProcHeaderOnly d={d} setD={setD} showData={true}/>
                    : <ProcHeaderOnly d={d} setD={setD} showData={false}/>
                }
            </Box>
              <DialogActions>
                {
                    isSetupStep(d.step)?
                    <Button variant="contained" color="primary" onClick={() => {
                        closeCallback()
                        dispatch(setHeader(d))
                        setD2(d)
                        }}
                    
                    > Save</Button>
                    : null
                }
                 

                 <Button variant="outlined" color="primary" onClick={() => {closeCallback()} }>Close</Button>

              </DialogActions>
        </Dialog>
    )
}


function ProcHeaderOnly({d, setD, showData}: {d: IProcHeader, setD: (d: IProcHeader)=>void , showData: boolean}) {
    const hospitals = useAppSelector(selectHospitals)
    const realStartTime = useAppSelector(selectStartTime)
    const realCurrentTime = useAppSelector(selectCurrentTime)

    d!.startTime = realStartTime
    d!.currentTime = realCurrentTime

    const getStateBgcolor = (dispositon: TProcDisposition) => {
        switch (dispositon) {
            case 'init': return 'white'
            case 'ongoing': return 'lightblue'
            case 'idle': return 'lightgrey'
            case 'finished': return 'lightgreen'
            case 'abandoned': return 'orange'
        }
    }

    const getStateClarification = (step: TStep) => {
        if (isSetupStep(step)) return ` (setup; ${step})`
        if (isRampUpStep(step)) return ` (ramp up; ${step})`
        if (isAssessmentStep(step)) return ` (assessment; ${step})`
        if (isRecruitmentStep(step)) return ` (recruitment; ${step})`
        return  ` ${step}`
    }

    const tabs = [
        { label: 'Procedure Information',   id: 'proc_info' },
        { label: 'Donor Information',       id: 'donor_info' },
        { label: 'Perfusion',               id: 'perfusion' },
        { label: 'Ventilation',             id: 'ventilation' },
    ]               

    const [activeTab, setActiveTab] = useState(showData?4:0);

    const handleTabClick = (event: any, tab: number) => {
        setActiveTab(tab);
    };

    const tabContents = [
        <Grid key={0}>
          
            <Grid container justifyContent="space-between">
                <Grid item xs={8}>
                    <strong>Procedure information</strong>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <b>State:&nbsp;&nbsp;</b><Box sx={{ background: getStateBgcolor(d.disposition) }}>
                        &nbsp;{d.disposition + (d.disposition === 'ongoing' ? getStateClarification(d.step) : '')}&nbsp;</Box>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={1} sx={{ mt: '3px' }} >
                <Grid item xs={4} >
                    <b>EVLP ID: </b> {d.evlpCaseNumber}
                </Grid>
                <Grid item xs={4} >
                    <b>Perform on: </b>{d.performedOn}
                </Grid>
                <Grid item xs={4} >
                    <b>EVLP Kit Serial Number: </b>{d.evlpKitNumber}
                </Grid>
                <Grid item xs={4} >
                    <b>Perfusionist: </b>{d.perfusionist}
                </Grid>
                <Grid item xs={4} >
                    {hospitals
                        ? <><b>Notify: </b>{hospitals?.find(x => x.rowKey === d.notify)?.fullName}</>
                        : null
                    }
                </Grid>
            </Grid>

            {!isSetupStep(d.step)?
                <Grid container spacing={1} sx={{ mt: '3px' }} >
                    <Grid item xs={4}>{<><b>Started at (Local):</b> {getDateFromMilliseconds(d?.startTime, 'local')}</>}</Grid>
                    <Grid item xs={4}>{<><b>Last updated at:</b>  {getDateFromMilliseconds(d?.lastUpdatedAt, 'local')}</>}</Grid>
                    <Grid item xs={4}>{<><b>Current duration:</b> {getDateFromMilliseconds(
                            d === null || d.currentTime === undefined || d.startTime === undefined
                                ? undefined
                                : d.currentTime - d.startTime, 'duration')}</>}</Grid>
                </Grid>
                :null
            }
        </Grid>,
        <Grid key={1}>
            <DonorDataTabContent d={d} setD={setD}/>
        </Grid>,
        <Grid key={2}>
            {rampUp(d)}
        </Grid>,
        <Grid key={3}>
          <UIRampupVentParameters/>
        </Grid>,
        <Grid key={4} hidden={!showData}>
            <TabbedDataView/>
        </Grid>
      ];


    return(
        <LocalizationProvider dateAdapter={AdapterDayjs}> 
        <Box sx={{mt:1}}>

        <FormControl fullWidth>

        <Tabs
            value={activeTab}
            onChange={handleTabClick}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="form tabs"
        >

        {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
        ))}

        {showData? <Tab label="Data"/> : null}

      </Tabs>            

        <Box sx={{ p: 3 }}>
            {tabContents[activeTab]}
        </Box>

        </FormControl> 

        </Box>
        </LocalizationProvider>
    )
}

function TabbedDataView() {
    const data = useAppSelector(selectData)
    const t = transposeData(data)
    const readonlyHeader = useAppSelector(selectProcHeader)

    const [fieldToEdit, setFieldToEdit] = useState<{step: TTimelineStep, field: TDataField, value: number | undefined} | null>(null)
    const dispatch = useAppDispatch()
    

    const editField = (step: TTimelineStep, field: TDataField) => {
        if (fieldToEdit === null ) {
            if (!CalculatedFields.includes(field as TCalculatedField)) {
                const value = data[step][field] as number | undefined
                setFieldToEdit({step, field, value})
            }
        }
    }

    const onFinishEditClick = () => {
        if (fieldToEdit!.value) {
            dispatch(setfield({
                fieldName: fieldToEdit!.field as TEntryField, 
                step: fieldToEdit!.step, 
                value: fieldToEdit!.value}))
            const proc_id = JSON.stringify({partitionKey: readonlyHeader?.partitionKey, rowKey: readonlyHeader?.rowKey})
            const email = readonlyHeader?.performedBy
            updateActionLogsAsync(email?email:'', proc_id, JSON.stringify(fieldToEdit))
        }
        setFieldToEdit(null)
    }
    const onFieldChanged = (field: TEntryField, newValue: number | undefined) => {
        const cpy = {...fieldToEdit!}
        cpy.value = newValue!
        setFieldToEdit(cpy)
    }

    return (
        <>
            <TabulatedDataView data={t} editFieldCallback={editField} />
            {fieldToEdit
                ? <Stack direction='row' mt='15px' height={'30px'} sx={{justifyContent:'center'}} >
                    <DictionaryFormNumberField sx={{width:'500px'}}
                        initialValue={fieldToEdit.value as number | undefined} 
                        field={fieldToEdit.field as TEntryField}
                        onDictionaryFieldChange = {onFieldChanged} />
                    <Box width={'100px'}/>
                    <Button sx={{mt:'30px'}} onClick={() => setFieldToEdit(null)}>Cancel</Button>
                    <Button sx={{mt:'30px'}} onClick={onFinishEditClick}>OK</Button>
                    <Divider/>
                  </Stack>
                  : null
            }

        </>
    )
}

