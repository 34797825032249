import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { IPerson } from '../../../data/Person';
import { useAppSelector } from '../AdminReduxHooks';
import { selectAll as selectAllHospitals } from '../org/HospitalsSlice';
import { selectAll as selectAllDoctors } from '../person/DoctorsSlice';

export interface IAddDialogProps {
    show: boolean
    targetOrgId: string
    closeCallback: (isOk: boolean, consultantId?: string) => void
}


export default function AddDialog(props: IAddDialogProps) {
    const allHospitals = useAppSelector(selectAllHospitals)
    const allAvailable = allHospitals.filter(x => x.rowKey !== props.targetOrgId)
    const allDoctors = useAppSelector(selectAllDoctors)
    const theHospital = allHospitals.find(x => x.rowKey === props.targetOrgId)
    const [selectedHospital, setSelectedHospital] = useState('')
    const [selectedDoctor, setSelectedDoctor] = useState('')
    const [doctors, setDoctors] = useState<IPerson[]>(allDoctors.filter(x => x.partitionKey === selectedHospital))


    const onHospitalChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSelectedHospital(e.target.value)
        setDoctors(allDoctors.filter(x => x.partitionKey === e.target.value))
        setSelectedDoctor('')
    }
    const onDoctorChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSelectedDoctor(e.target.value)
    }

    return (
        <Dialog open={props.show} fullWidth maxWidth='md' >
            <DialogTitle>Add Consultant for {theHospital?.fullName} </DialogTitle>
            <DialogContent>
                <TextField sx={{ mt: 1 }} select fullWidth
                    value={selectedHospital}
                    onChange={onHospitalChange}
                    label='Select Hostpital'>
                    {allAvailable.map(x =>
                        <MenuItem key={x.rowKey} value={x.rowKey}>
                            <Stack direction='row'>
                                <div style={{ fontWeight: 'bold' }} >Label:&nbsp;</div>{x.label}&nbsp;&nbsp; &nbsp; &nbsp;
                                <div style={{ fontWeight: 'bold' }} >Full Name:&nbsp;</div>{x.fullName}
                            </Stack>
                        </MenuItem>)}
                </TextField>
                <br/>
            <TextField sx={{mt:1}} select fullWidth  
                value={selectedDoctor}  
                onChange={onDoctorChange} 
                label='Select Doctor'>
                    {doctors.map(x => 
                        <MenuItem key={x.rowKey} value={x.rowKey}>
                                    <Stack direction='row'>
                                        {`${x.honorific} ${x.firstName} ${x.lastName},`}
                                        <div style={{fontWeight:'bold'}} >&nbsp;&nbsp;eMail:&nbsp;</div>{x.eMail} 
                                        <div style={{fontWeight:'bold'}} >,&nbsp;&nbsp;phone #:&nbsp;</div>{x.phoneNumber} 
                                    </Stack>
                        </MenuItem>)}
            </TextField> 

            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.closeCallback(false)}>Cancel</Button>
                <Button onClick={() => props.closeCallback(true, selectedDoctor)}>OK</Button>
            </DialogActions>
        </Dialog>)
}

