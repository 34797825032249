import { Fragment, useState } from 'react'
import { IOrg} from '../../../data/Org'
import { IPerson } from "../../../data/Person"
import { ResizableTable } from '../../../utils/ResizableTable'
import { useAppDispatch } from '../AdminReduxHooks'
import FormDialog from '../../../utils/FormDialog'
import { ICommonPageProps } from '../common/CommonProps'
import NavBar from '../common/NavBar'
import AddForm from './AddForm'
import { Body, Header } from './Table'
import { createPersonAsync } from '../common/FetchApi'
import { TPerson } from '../../../data/Common'

export interface IPageProps extends ICommonPageProps<TPerson,IPerson>{
    orgs: Array<IOrg>
}

function CreatePerson(type: TPerson): IPerson {
    return {
        partitionKey: '',
        rowKey: crypto.randomUUID(),
        typeTag: type,
        isActive: true,
        honorific: '',
        firstName: '',
        lastName: '',
        eMail: '',
        phoneNumber: ''
    }
}

export default function Page(props: IPageProps) {
    const [showDialog, setShowDialog] = useState(false)
    const [newData, setNewData] = useState(CreatePerson(props.itemType))
    const dispatch = useAppDispatch()

    const dialogTitle = props.itemType === 'tech' 
        ? 'Add Tech'
        : 'Add Surgeon'

        
    const onCreateFieldUpdate = (id: string, value: string ) => {
        const updatedData = {...newData}
        // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
        updatedData[id as keyof CreateOrgData] = value
        setNewData(updatedData)
      }

      const onDialogFinished = async (isOk: boolean) => {
        if (isOk) {
            const eMailIndex = props.items.findIndex(x => x.eMail === newData.eMail) 
            if (eMailIndex !== -1) {
                return `Error: Entered eMail belongs to already entered person ${props.items[eMailIndex].firstName} ${props.items[eMailIndex].lastName}.`
            }
        
            const isOrgSelected = props.orgs.findIndex(x => x.rowKey === newData.partitionKey) !== -1
            if (!isOrgSelected) {
                return `Error: The parent organization should be selected.`
            }
            
            const ret = await createPersonAsync(newData)            
            if (ret !== '') {
                return 'API Error:' + ret
            }
            newData.orgLabel = props.orgs.find(x => x.rowKey === newData.partitionKey)?.label
            dispatch(props.addAction(newData))
            setShowDialog(false)
            return ''
        } else {
            setShowDialog(false)
            return ''
        }
    }

    return (
        <Fragment>
            <ResizableTable 
                header={<Header/>}
                body= {<Body persons={props.items} updateAction={props.updateAction} orgs={props.orgs}/>}
            />
            <NavBar onAddClicked={() => setShowDialog(true)}  /> 
            <FormDialog show={showDialog} title={dialogTitle} closeCallback={onDialogFinished} 
                form={<AddForm data={newData} onDataItemChanged={onCreateFieldUpdate} orgs={props.orgs} />} /> 
        </Fragment>)
}
