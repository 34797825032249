import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/material'
import { Fragment, useState } from 'react'
import { IPerson } from "../../../data/Person"
import { IOrg } from '../../../data/Org'
import { useAppDispatch } from '../AdminReduxHooks'
import { updatePersonAsync } from '../common/FetchApi'
import FormDialog from '../../../utils/FormDialog'
import { ICommonEditEntityButtonProps } from '../common/CommonProps'
import EditForm from './EditForm'

export interface IEditEntityButtonProps extends ICommonEditEntityButtonProps<IPerson> {
    orgs: Array<IOrg>
} 

export default function EditEntityButton(props: IEditEntityButtonProps) {
    const [showDialog, setShowDialog] = useState(false)
    const [editData, setEditData] = useState({...props.item})
    const dispatch = useAppDispatch()
   

    const onFieldUpdate = (id: string, value: string | boolean ) => {
        const updatedData = {...editData}
        // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
        updatedData[id as keyof CreateOrgData] = value
        setEditData(updatedData)
      }


    const onDialogFinished = async (isOk: boolean) => {
        if (isOk) {
            const eMailIndex = props.items.findIndex(x => x.eMail === editData.eMail && x.rowKey !== editData.rowKey) 
            if (eMailIndex !== -1) {
                return `Error: Entered eMail belongs to a different person ${props.items[eMailIndex].firstName} ${props.items[eMailIndex].lastName}.`
            }

            const ret = await updatePersonAsync(editData)            
            if (ret !== '') {
                return 'API Error:' + ret
            }

            dispatch(props.updateAction(editData))
            setShowDialog(false)
            return ''
        } else {
            setShowDialog(false)
            return ''
        }
    }

    const dialogTitle = props.item.typeTag === 'tech' 
        ? 'Edit Tech'
        : 'Edit Surgeon'

    return (
        <Fragment>
            <IconButton sx={{mt:'-5px'}} onClick={() =>setShowDialog(true)} >
                <EditIcon width={props.width} fontSize='small'/>
            </IconButton>
            <FormDialog show={showDialog} title={dialogTitle} closeCallback={onDialogFinished} 
                    form={<EditForm data={editData} onDataItemChanged={onFieldUpdate} 
                    org={props.orgs.find(x => x.rowKey === props.item.partitionKey)!}/>} /> 
        </Fragment>
    )    
}

