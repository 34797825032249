import { createSlice } from "@reduxjs/toolkit"
import { AdminRootState } from "../AdminStore"
import { getUserLogsAsync } from "./UserLogsThunk"
import { Logins } from "../../../utils/UserSessionLogs"

export interface IUserLogState {
    all: Logins[]
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IUserLogState = {
    all: [],
    status: 'idle'
}

export const userLogsSlice = createSlice({
    name: 'logins',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserLogsAsync.pending, (state) => {
                state.all.splice(0)
                state.status = 'loading';
            })
            .addCase(getUserLogsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.all = action.payload;
            });
    },
})



export const selectAll = (state: AdminRootState) => state.userLogs.all
export const selectStatus = (state:  AdminRootState) => state.userLogs.status

export default userLogsSlice.reducer