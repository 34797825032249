import CheckIcon from '@mui/icons-material/Check'
import { Divider, Stack, Typography } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Fragment } from 'react';
import { IDevice } from '../../../data/Device';
import EditEntityButton from './EditEntityButton';
import ServiceEntityButton from './ServiceEntityButton';

const editWidth = '36px'
const serviceIconWidth = '36px'
const labelWidth = '150px'
const serialWidth = '150px'
const orgLabelWidth = '150px'
const lastServiceWidth = '150px'
const timeHeaderWidth = '200px'
const otSinceLastServiceWidth = '100px'
const otTotalWidth = '100px'
const isActiveWidth = '16px'


export function Header() {
    return (
        <Fragment>
        <Stack direction='row'>
            <Typography variant='h6' width={editWidth}> </Typography>
            <Typography variant='h6' width={serviceIconWidth}> </Typography>
            <Typography variant='h6' width={labelWidth}>Label</Typography>
            <Typography variant='h6' width={serialWidth}>Serial #</Typography>
            <Typography variant='h6' width={orgLabelWidth}>Belongs to</Typography>
            <Typography variant='h6' width={lastServiceWidth}>Last service</Typography>
            <Stack direction='column'>
                <Typography variant='subtitle1' width={timeHeaderWidth}><b>Time in use:</b></Typography>
                <Stack direction='row'>
                    <Typography variant='h6' width={otSinceLastServiceWidth}>since last</Typography>
                    <Typography variant='h6' width={otTotalWidth}>total</Typography>
                </Stack>
            </Stack>
            <Typography variant='h6' width={isActiveWidth}>Active?</Typography>
        </Stack>
        <Divider/>
        </Fragment>
    )
}

export function Body(
    props: {devices: Array<IDevice>, 
            updateAction?: ActionCreatorWithPayload<IDevice, string>
            }) {
    return (
        <Fragment>
         { props.devices.map((x) => 
            <Stack sx={x.isOverdueForService ? {color:'red'}: {}} direction='row' key={x.rowKey}>
                <EditEntityButton width={editWidth} item={x} />
                <ServiceEntityButton width={serviceIconWidth} item={x}/>
                <Typography width={labelWidth}>{x.label}</Typography>
                <Typography width={serialWidth}>{x.rowKey}</Typography>
                <Typography width={orgLabelWidth}>{x.orgLabel}</Typography>
                <Typography width={lastServiceWidth}>{x.lastServiceDate}</Typography>
                <Typography width={otSinceLastServiceWidth}>{x.operatingTimeSinceLastService}</Typography>
                <Typography width={otTotalWidth}>{x.totalOperatingTime}</Typography>
                {x.isActive ? <CheckIcon width={isActiveWidth} fontSize='small'/> : ''}
            </Stack>)} 
        </Fragment>
    )
}
