import { useAppSelector } from "../routes/monitor/monitorReduxHooks"
import getInsighTxHTML from "./InsighTx"
import { selectPrediction } from "./slicePrediction"

export default function InsighTxTab() {
    const d = useAppSelector(selectPrediction)
    
    let resulstMessage, listItems, missing

    let newBody 

    if (d.data === 'false') {
        newBody = `
        <h1 style="color: #0074cc;"> INSIGHTx </h1>

    <div>
        <div style="display: flex; justify-content: flex-end; width: 100%;">
        </div>

        <div style="flex: 1; text-align: center; padding: 10px;">
            
            <span style="color: #0074cc;"> This hospital does not have permission to use InsighTx </span>
        </div>
    </div>
        `
    }

    else if (d.data){
        const data = JSON.parse(d.data)

        missing = data.missing//'Cytokines' // get rid of later
        missing.push('Cytokines')

        let factors: string [] = data['top5'] // change word formatting.....
        //['Last observed Cstat', 'Last observed Delta PO<sub>2</sub>', 'Max observed Delta PO<sub>2</sub>', 'Lowest observed Delta PO<sub>2</sub>', 'Last observed Cdyn']

        listItems = factors.map(factor => `<li>${factor}</li>`);
        
        resulstMessage = data.outcome

        if (resulstMessage){
            let time = ''
            if (resulstMessage.includes('>=')){
                time =  'after'
            } else if (resulstMessage.includes('<')){
                time = 'within'
            }

            resulstMessage = ` extubated ${time} 72 hours after transplant`

            if (time === ''){
                resulstMessage = 'declined'
            }
        }
        newBody = getInsighTxHTML(resulstMessage, listItems, missing)
    } else {
        newBody = getInsighTxHTML('', [], [])
    }

    return (
        <>
        {d?  <div dangerouslySetInnerHTML={{ __html: newBody }} />:null}
        </>
    )
}