import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, MenuItem, Stack, TextField } from '@mui/material';
import { DateTimeField, DateTimePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ChangeEvent, useState } from 'react';
import { selectDevices, selectHospitals, selectProfile } from '../../../common/ProfileSlice';
import { BloodTypes, DonorTypes, IProcHeader, SexTypes, TSex } from '../../../data/Procedure';
import { EVLPIndicationsDataDictionary } from '../../../data/ddEVLPIndications';
import MessageAlert, { TAlertMessageType } from '../../../utils/MessageAlert';
import NumericInput from '../../../utils/NumericInput';
import { useAppDispatch, useAppSelector } from '../TechReduxHooks';
import { add } from './ProceduresSlice';
import { createProcedureAsync } from './fetchApi';
import { clearStorage } from '../../run/RunStore';

export const MinAge = 5
export const MaxAge = 100
export const MinHeight = 100
export const MaxHeight = 230
export const MinWeight = 10
export const MaxWeight = 250


function calculatePtlc(sex:TSex, height_cm: number, age_years: number) {
    if (sex === 'male') {
        return  Math.round((0.08 * height_cm + 0.003 * age_years - 7.333) * 100) / 100 
    } else {
        return Math.round((0.059 * height_cm - 4.537) * 100) / 100
    }

}

export const DEFAULT_SEX: TSex = 'male'
export const DEFAULT_WEIGHT_KG: number = 70
export const DEFAULT_HEIGHT_CM: number = 170
export const DEFAULT_AGE_YEARS: number = 35


export default function AddProcDialog({ show, closeCallback }: { show: boolean, closeCallback: (isOk: boolean) => void }) {
    const [errorText, setErrorText] = useState<TAlertMessageType>(undefined)
    const devices = useAppSelector(selectDevices)
    const hospitals = useAppSelector(selectHospitals)
    const dispatch = useAppDispatch()
    const profile = useAppSelector(selectProfile)

    const initialData: IProcHeader = {
        evlpCaseNumber: '',
        perfusionist: '',
        lastUpdatedAt: 0,
        lung: 'both',
        age_years: DEFAULT_AGE_YEARS,
        sex: DEFAULT_SEX,
        height_cm: DEFAULT_HEIGHT_CM,
        weight_Kg: DEFAULT_WEIGHT_KG,
        cardiacOutput_LPmin: 0,
        bodyMassIndex: 0,
        idealBodyWeight_Kg: 0,
        donorType: 'unspecified',
        bloodType: 'unspecified',
        crossClampDT: Date.now(),
        performedOn: '',
        initiatedBy: profile === undefined ? '' : profile!.person.eMail,
        performedBy: '',
        notify: '',
        partitionKey: profile!.org.rowKey,
        rowKey: crypto.randomUUID(),
        step: 'Procedure',
        disposition: 'init',
        startTime: 0,
        currentTime: 0,
        evlpKitNumber: '',
        isLungsAccepted: false,
        timeZone: profile!.org.timeZone,
        pTLC: calculatePtlc(DEFAULT_SEX, DEFAULT_HEIGHT_CM, DEFAULT_AGE_YEARS)
    
    }

    const [d, setD] = useState({ ...initialData })
    const [tempCCDT, setCCDT] = useState<dayjs.Dayjs|null>(dayjs(Date.now())) 

    const onCancelClick = () => {
        closeCallback(false)
    }

    const onOkClick = async () => {
        if (d.performedOn === '' || d.notify === '') {
            setErrorText('Please enter missing data')
            return
        }

        const addRet = await createProcedureAsync(d)
        if (addRet === '') {
            dispatch(add(d))
            closeCallback(true)
        } else {
            setErrorText(addRet)
        }
        clearStorage()

    }

    const recalculateDerivedData = (ud: IProcHeader) => {
        ud.bodyMassIndex = Math.round(10 * ud.weight_Kg / (ud.height_cm * ud.height_cm / 10000)) / 10
        // From Run
        ud.idealBodyWeight_Kg = Math.round((ud.height_cm * ud.height_cm / 10000)
                                        * (ud.sex === 'male' ? 23 : 22) * 10) / 10
        ud.cardiacOutput_LPmin = Math.sqrt(((ud.height_cm * ud.idealBodyWeight_Kg) / 3600)) * 2.4
        ud.recordCreatedAt = Date.now() // to preserve sort order after adding to the list
        ud.pTLC = calculatePtlc(ud.sex, ud.height_cm, ud.age_years)
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        const ud = { ...d }
        try {
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            ud[id] = e.target.type === 'checkbox' ? e.target.checked : e.target.value
            recalculateDerivedData(ud)
        } catch (ex) {
            console.log('Error trying to assign value',ex)
        }
        setD(ud)
    }



    const onSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        const ud = { ...d }
        try {
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            ud[id] = e.target.value
            recalculateDerivedData(ud)
        } catch (ex) {
            console.log('Error trying to assign selected value',ex)
        }
        setD(ud)
    }

    const onNumberChange = (n: number | undefined, e:React.ChangeEvent<HTMLInputElement>) => {
        const id = e.target.id ?? e.target.name // sometimes id isn't defined
        const ud = { ...d }
        try {
            // @ts-ignore: TS7053 // It's ok to index an object by key, but typescript doesn't like it when the object properties of different types
            ud[id] = n ? n : ud[id]
            recalculateDerivedData(ud)
        } catch (ex) {
            console.log('Error trying to assign value',ex)
        }
        setD(ud)
    }


    const onCCDTChange: DateTimePickerProps<dayjs.Dayjs>['onChange'] = (date) => {
        setCCDT(date)
        if (date) {
            const ud = { ...d }
            ud.crossClampDT = (date?.toDate()).getTime()
            setD(ud)
        }

    };
    

    return (
        <Dialog open={show} fullWidth maxWidth='lg' >
            <DialogTitle>New Procedure</DialogTitle>
            <DialogContent>
                <MessageAlert msg={errorText} />
                <FormControl>
                    <strong>Procedure information</strong>
                    <Grid container spacing={1} sx={{mt:'2px', mb:'10px'}}>
                            <Grid item xs={4}>
                                <TextField fullWidth id='evlpCaseNumber' label='EVLP ID' value={d.evlpCaseNumber} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth required select name='performedOn' label='Perform on' value={d.performedOn} onChange={onSelectionChange}>
                                    {devices?.map(e => <MenuItem key={e.rowKey} value={e.rowKey}><strong>{e.label}:</strong> &nbsp;{e.rowKey} </MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth required select name='notify' label='Notify' value={d.notify} onChange={onSelectionChange}>
                                    {hospitals?.map(e => <MenuItem key={e.rowKey} value={e.rowKey}><strong>{e.label}:</strong> &nbsp;{e.fullName} </MenuItem>)}
                                </TextField>
                            </Grid>
                    </Grid>
                    <strong>Donor information</strong>
                    <Grid container spacing={1} sx={{mt:'2px', mb:'10px'}}>
                        <Grid item xs={4}>
                            <Stack direction={'row'} alignItems="center">
                                <DateTimeField fullWidth value={tempCCDT} label='Cross clamp date/time' format='YYYY-MM-DD HH:mm' onChange={onCCDTChange} />
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth select name='donorType' label='Donor Type' value={d.donorType} onChange={onSelectionChange}>
                                {DonorTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth select name='bloodType' label='Donor Blood Type' value={d.bloodType} onChange={onSelectionChange}>
                                    {BloodTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput fullWidth id='age_years'
                                variant='outlined'
                                step={1}
                                label='Age (years)' 
                                initValue={d.age_years} 
                                onNumberChanged={(n, e) => onNumberChange(n, e)} 
                                min={MinAge}
                                max={MaxAge}/>
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput fullWidth id='height_cm'
                                variant='outlined'
                                step={1}
                                label='Height (cm)' 
                                initValue={d.height_cm} 
                                onNumberChanged={(n, e) => onNumberChange(n, e)} 
                                min={MinHeight}
                                max={MaxHeight}/>
                        </Grid>
                        <Grid item xs={4}>
                            <NumericInput fullWidth id='weight_Kg'
                                variant='outlined'
                                step={.1}
                                label='Weight (kg)' 
                                initValue={d.weight_Kg} 
                                onNumberChanged={(n, e) => onNumberChange(n, e)} 
                                min={MinWeight}
                                max={MaxWeight}/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField select fullWidth name='sex' label='Sex' required value={d.sex} onChange={onSelectionChange}>
                                {SexTypes.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth id='CO_lpm' label='Cardiac Output (lpm)' value={d.cardiacOutput_LPmin?.toFixed(2)} disabled />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth id='pTLC' label='pTLC' value={d.pTLC} disabled />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth id='ibw' label='Ideal Body Weight (IBW)' value={d.idealBodyWeight_Kg} disabled />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth id='bmi' label='Body Mass Index (BMI)' value={d.bodyMassIndex} disabled />
                        </Grid>
                    </Grid>
                </FormControl>
                <Grid container>
                    <Grid item xs={12}>
                        <strong>EVLP Indications</strong>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{mt:'2px', mb:'0px'}} rowSpacing={'0px'} >
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox id='isConsernsOfAspiration' value={d.isConsernsOfAspiration} onChange={onChange}/>} 
                                label={EVLPIndicationsDataDictionary.isConsernsOfAspiration.label} />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox id='isLowDonorPO2' value={d.isLowDonorPO2} onChange={onChange} />} 
                                label={EVLPIndicationsDataDictionary.isLowDonorPO2.label} />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox id='isFromMarginalDCD' value={d.isFromMarginalDCD} onChange={onChange}/>} 
                                label={EVLPIndicationsDataDictionary.isFromMarginalDCD.label} />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox id='isHighRiskHistory' value={d.isHighRiskHistory} onChange={onChange}/>} 
                                label={EVLPIndicationsDataDictionary.isHighRiskHistory.label} />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox id='isPresenceOfEndema' value={d.isPresenceOfEndema} onChange={onChange}/>} 
                                label={EVLPIndicationsDataDictionary.isPresenceOfEndema.label} />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel control={<Checkbox id='isPoorCompliance' value={d.isPoorCompliance} onChange={onChange}/>} 
                                label={EVLPIndicationsDataDictionary.isPoorCompliance.label} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField sx={{mt:'12px'}} fullWidth id='otherIndications' value={d.otherIndications} onChange={onChange}
                                label={EVLPIndicationsDataDictionary.otherIndications.label} />
                        </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClick}>Cancel</Button>
                <Button onClick={onOkClick}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}

export function AddProc({ show, closeCallback }: { show: boolean, closeCallback: (isOk: boolean) => void }) {}
