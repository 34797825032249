import PersonIcon from '@mui/icons-material/Person';
import { Box, Divider, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import EulaUnsignedDialog from '../../common/EluaUnsignedDialog';
import { acquireClientId, getProfileAsync, selectClientId, selectProfile, selectStatus as selectProfileStatus } from '../../common/ProfileSlice';
import { getEluaFlagAsync } from '../../utils/PrinicpalUtils';
import WaitingLabel from '../../utils/WaitingLabel';
import { replaceHeader } from '../../utils/replaceHeader';
import { useAppDispatch, useAppSelector } from './TechReduxHooks';
import { persistor, store } from './TechStore';
import { getProceduresAsync, selectStatus as selectProceduresStatus } from './current/ProceduresSlice';
import { Close } from '@mui/icons-material';
import { getPreferencesAsync, getUserEmail } from '../../common/slicePreferences';
import HelpBox from '../../common/HelpBox';
import { PersistGate } from 'redux-persist/integration/react';
import WifiStatus from '../../common/WifiStatus';

export default function Tech() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Technician />
                </LocalizationProvider>
            </PersistGate>
        </Provider>
    )
}

function Technician() {
    const [tabIndex, setTabIndex] = useState(0)
    const [eulaSigned, setEulaSigned] = useState<boolean | undefined>(undefined)
    const currentStatus = useAppSelector(selectProceduresStatus)
    const archiveStatus = 'idle'
    const aboutStatus = useAppSelector(selectProfileStatus)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const dispatch = useAppDispatch()
    const profile = useAppSelector(selectProfile)
    const clientId = useAppSelector(selectClientId)

    const [reportUrl, setReportUrl] = useState('')
    const updateUrl = (url: string):void=>{setReportUrl(url)}
    const updateTab = (num: number):void=>{setTabIndex(num)}
    const navigate = useNavigate()

    useEffect(() => {
        replaceHeader('Perfusionist', `${window.location.origin}/perfIcon.ico`)
    }, [])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const flag = await getEluaFlagAsync('tech');
                setEulaSigned(flag);
                if (!localStorage.getItem('flag')){
                    localStorage.setItem('flag', String(flag))
                }
            } catch (error) {
                const flag = localStorage.getItem('flag')
                if (!flag || flag ==="false"){
                    setEulaSigned(false); // Set a default value or handle the error case
                    console.error('Error fetching flag:', error);
                } else {
                    setEulaSigned(Boolean(flag));
                }
            }
        };
        fetchData();
    }, [])

    useEffect(() => {
        dispatch(acquireClientId())
        dispatch(getProceduresAsync())
        const table = 'tech'
        if (navigator.onLine){
            getUserEmail().then((eMail) => dispatch(getPreferencesAsync({table, eMail})))
            dispatch(getProfileAsync())
        }

    }, [dispatch])


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const linkRef = useRef(null)
    const onLogout = () => {
        if (linkRef.current) {
            (linkRef.current as HTMLAnchorElement).click()
        }
    }

    const closeReport = () => {
        navigate('current')
        navigate(0)
        setReportUrl('')
    }

    if (eulaSigned === undefined) {
        return (<></>)
    } else {

        return (
            <Box sx={{ width: '100%' }}>
                <Stack direction={'row'}>
                    <Tabs value={tabIndex} onChange={handleChange} sx={{ mb: '5px' }}>
                        <Tab value={0} label={<WaitingLabel title='Current' target={currentStatus} />} to='current' component={Link} />
                        <Tab value={1} label={<WaitingLabel title='Archive' target={archiveStatus} />} to='archive' component={Link} />
                        <Tab value={2} label={<WaitingLabel title='About' target={aboutStatus} />} to='about' component={Link} />
                    
                        { reportUrl !== ''
                      ? <Tab value={3} label={<WaitingLabel title={                        
                            <span>
                                Report 
                                <IconButton size="small" onClick={closeReport}> <Close/>  </IconButton>
                            </span>
                        } 
                            target={[]} />} to={`report`} component={Link}  iconPosition="end" />
                      : null
                    }
                    
                    </Tabs>
                    <Stack direction="row" sx={{ position: 'absolute', top: 0, right: 8 }}>
                        <WifiStatus/>
                        <HelpBox/>
                        <IconButton size='small' onClick={handleClick} >
                            <PersonIcon />
                        </IconButton>
                    </Stack>

                    <Menu anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <Stack sx={{ p: '10px' }}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {` ${profile?.person.honorific} ${profile?.person.firstName} ${profile?.person.lastName}`}
                            </Typography>
                            <Divider />
                            <Typography><strong>ClientID:</strong> {clientId}</Typography>
                            <Typography> <strong>eMail: </strong> {profile?.person.eMail}</Typography>
                            <Typography> <strong>Phone #: </strong> {profile?.person.phoneNumber}</Typography>
                        </Stack>
                        <Divider />
                        <a ref={linkRef} style={{ visibility: 'hidden' }} href='/.auth/logout'>not visible</a>
                        <MenuItem onClick={onLogout} >Log out</MenuItem>
                    </Menu>
                </Stack>
                <Outlet context={{reportUrl, updateUrl, updateTab}}/>
                <EulaUnsignedDialog eulaSigned={eulaSigned} role={'tech'} />
            </Box>
        )
    }
}
