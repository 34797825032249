import { Typography } from '@mui/material'
import { ReactElement } from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { TDataField } from '../data/ProcData'
import { dataDictionary } from '../data/dd'
import { TimelineValuesArray } from './transposeData'

export interface IDataChartProps {
    data: TimelineValuesArray
    elements: Array<TDataField>
    width?: number
    height?: number
}

type TDataSeriesProps = {
    color: string
    dotShape: ReactElement
}


const chartStrokeColors: Array<TDataSeriesProps> = [
    {color: 'green', dotShape: <span>a</span> } ,
    {color: 'blue', dotShape: <span>a</span> },
    {color: 'magenta', dotShape: <span>a</span> },
    {color: 'cyan', dotShape: <span>a</span> },
    {color: 'purple', dotShape: <span>a</span> },
    {color: 'darkgrey', dotShape: <span>a</span> },
    {color: 'teal', dotShape: <span>a</span> },
    {color: 'lightblue', dotShape: <span>a</span> },
    {color: 'yellow', dotShape: <span>a</span> }, 
]

const renderLegend = (props: any) => {
    const dd = dataDictionary
    const payload: any[] = props.payload 
    return (
        <p style={{margin:0, textAlign:'right'}}>{
            payload.map(x =>  
                <Typography component="span"  key={x.dataKey} sx={{color:(x as any).color, fontWeight:'bold', fontSize:'14px'}}>
                    {dd[x.dataKey as TDataField].label}&nbsp;</Typography>)
        }</p>
    )
}

export function DataChart(props:IDataChartProps) {

    return(
        <ResponsiveContainer width='100%' height={props.height}  >
                <LineChart data={props.data} style={{margin:0}}>
                    <CartesianGrid stroke='#ccc' />
                    <XAxis dataKey='label' />
                    <Legend content={renderLegend} verticalAlign='top'   />
                    <YAxis domain={['dataMin', 'dataMax']} />
                    {props.elements.map((element, index) =>{
                        return (<Line key={index} type='linear' dataKey={element} stroke={chartStrokeColors[index].color} />)
                    })}
                </LineChart>
        </ResponsiveContainer>
    )
}
