import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IMonitorDoctor } from "../data/Person"

export interface IPersonState {
    data: IMonitorDoctor[] | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IPersonState = {
    data: null,
    status: 'idle'
}

export const getTechsAsync = createAsyncThunk(
    'techs/getTechFromReport',
    async (id: string | undefined) => {
        const requestUrl = `/api/getTechFromReport?partitionKey=${id}`
        const response = await fetch(requestUrl)
        if (response.ok) {
            const json: IMonitorDoctor[] = await response.json()
            return json
        } else {
            console.log(`Error fetching monitor techs data from ${id}. Status: ${response.status}`)
            return null
        }
    }
)

export interface ISetCheck {
    id: string,
    value: boolean
}

export const techSlice = createSlice({
    name: 'tech',
    initialState: initialState,
    reducers: {
        setSend: (state, action: PayloadAction<ISetCheck>) => {
            let index = state.data!.findIndex(x => x.rowKey === action.payload.id)
            state.data![index].isSelectedForReport = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTechsAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getTechsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload!
            })
    }
})

export const selectTechs = (state: { techSlice: { data: any } }) => {
    const data = state.techSlice.data?.filter((x: { isActive: any }) => x.isActive)
    if (data === undefined)
        return null
    return data
}
export const selectCanSend = (state: { techSlice: { data: any } }) => {
    if (state.techSlice.data) {
        return state.techSlice.data!.reduce(
            (accumulator: boolean, currentValue: { isSelectedForReport: boolean }) => 
            accumulator || currentValue.isSelectedForReport!, false)
    } else {
        return false
    }
}
export const { setSend } = techSlice.actions
export default techSlice.reducer