import { useAppSelector } from "../AdminReduxHooks"
import Page from "./Page"
import { selectAll, add, update } from "./HospitalsSlice"


export default function Hosptal() {
    const orgs = useAppSelector(selectAll)

    return (<Page items={orgs} itemType={'hospital'} addAction={add} updateAction={update} />)
}
