import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TOrg } from '../../../data/Common'
import { IOrg } from "../../../data/Org"

export interface IOrganizationsState {
    all: Array<IOrg>
    status: 'idle' | 'loading' | 'failed'
}

export function createOrgSliceHelper(
    name: TOrg, 
    initialState: IOrganizationsState, 
    actionCreator: any) {
    return createSlice({
        name: name,
        initialState,
        reducers: {
          add: (state, action: PayloadAction<IOrg>) => {
            state.all.push(action.payload)
          },
          update: (state, action: PayloadAction<IOrg>) => {
            const index = state.all.findIndex(x => x.rowKey === action.payload.rowKey) 
            if (index !== -1) {
              state.all[index] = action.payload
            }
          }
        },
        extraReducers: (builder) => {
            builder
              .addCase(actionCreator.pending, (state) => {
                state.all.splice(0)
                state.status = 'loading';
              })
              .addCase(actionCreator.fulfilled, (state, action) => {
                state.status = 'idle';
                state.all = action.payload;
              });
          },
      })    
}

