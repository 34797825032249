import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TEntryField } from '../../data/ProcData'
import { TSteadyStep } from '../../data/WorkflowSteps'
import aGetFormCompletion from './aGetFormCompletion'
import DF, { ViewNumberField as VF } from './DictionaryNumberField'
import EntryItem from './RampupEntryItem'
import { useAppSelector } from './RunReduxHooks'
import { selectData } from './sliceTimeline'

export const fieldList:TEntryField[] = [
    'LAGlucose', 'LALactate', 
    'LApH', 'LAPCO2', 'LAPO2', 
    'PApH', 'PAPCO2', 'PAPO2'    
]

export default function ATab3BGA({completion} : {completion: (allChecked: boolean) => void}) {
    const { step } = useParams()
    const stepData = useAppSelector(selectData)
    
    useEffect(() => {
        completion(aGetFormCompletion(stepData, step as TSteadyStep, fieldList))
    }, [stepData, step, completion])

    const GRIDSPACING = 2
    return (
        <>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={4}>
                        <DF step={step} field='LApH' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='LAPCO2' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='LAPO2' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={6}>
                        <DF step={step} field='LAGlucose' fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <DF step={step} field='LALactate' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={4}>
                        <DF step={step} field='PApH' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='PAPCO2' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <DF step={step} field='PAPO2' fullWidth />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem>
                <Grid container spacing={GRIDSPACING}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4}>
                        <VF step={step} field='DeltaPF' fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                </Grid>
            </EntryItem>
        </>
    )

}