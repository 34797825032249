import { Checkbox, FormControlLabel, Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router-dom';
import { TSetupStep } from '../../data/WorkflowSteps';
import AutoScrollContainer from '../../utils/AutoScrollContainer';
import { useAppDispatch, useAppSelector } from './RunReduxHooks';
import { selectSetupState, setcheck} from './sliceSetup';

export default function UISetup() {
    const { step } = useParams()
    const currentSection = useAppSelector(selectSetupState)[step as TSetupStep]
    const dispatch = useAppDispatch()

    const handleChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setcheck({itemId: id, section: step as TSetupStep, value: event.target.checked}))
    }
 
    return (
        <AutoScrollContainer bottomOffset={70}>
                {currentSection!.header !== undefined

                    ?   <>
                            <Paper elevation={0} key={Math.random()} sx={{ mt: 2, mb:1, width: '98%', pb: '5px' }}>
                                <Typography variant='h5'>{currentSection!.header}</Typography>
                                <Divider/>
                            </Paper>
                        </>
                    : null
                }
                {Object.entries(currentSection!.items!).map(([key, item]) => {
                    return (
                        <Paper elevation={2} key={key} sx={{ mt: 1, width: '98%', pb: '5px' }}>
                            <FormControlLabel 
                                control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }} 
                                checked={item.checked} 
                                onChange={(e) => handleChange(key, e)} />}
                                label={<Typography variant='h5'>{item.label}</Typography>} />
                            {item.subItems?.map(subitem => <div key={Math.random()} style={{ marginLeft: '100px' }}>
                                <Typography variant='h5'>{subitem}</Typography></div>)}
                        </Paper>)
                })}
        </AutoScrollContainer>
    )
}

