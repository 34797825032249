import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useRef } from 'react';





//rgba(0, 0, 0, 0.5)
export default function FlyingWait({ show: showAsWaiting, children }: { show: boolean, children?: any }) {
  const containerRef = useRef(null)
  const waitingSX = showAsWaiting ? {pointerEvents:'none'} : {}
  return (
    <Box sx={{ position:'relative', ...waitingSX}} ref={containerRef} >
      <Backdrop open={showAsWaiting} sx={{position:'absolute', background:'rgba(0, 0, 0, 0.5)'}} >
          <CircularProgress size='96px' />  
      </Backdrop>
      {children}  
    </Box>
  )  

    
}

