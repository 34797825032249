import { configureStore } from '@reduxjs/toolkit'
import procHeaderReducer from './sliceMonitorProcHeader'
import dataReducer from './sliceMonitorData'
import mediaContentReducer from './silceMediaContent'
import doctorsReducer from '../../common/sliceMonitorDoctors'
import techReducer from '../../common/sliceTechs'
import preferencesReducer from '../../common/slicePreferences'
import predictionReducer from '../../common/slicePrediction'

import notesReducer from './silceNotes'

export const store = configureStore({
    reducer: {
      procHeaderSlice: procHeaderReducer,
      dataSlice: dataReducer,
      mediaContentSlice: mediaContentReducer,
      doctorsSlice: doctorsReducer,
      notesSlice: notesReducer,
      techSlice: techReducer,
      preferencesSlice: preferencesReducer,
      predictionSlice: predictionReducer
    }
  })
 

export type MonitorState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

