import { Box } from '@mui/material';
import { useState, useRef, useEffect, useLayoutEffect, PropsWithChildren } from 'react';

interface IAutoScrollContainerProps {
    bottomOffset: number
    sx?: any
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    };
}

export default function AutoScrollContainer(props: PropsWithChildren<IAutoScrollContainerProps>) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const parentRef = useRef<HTMLDivElement>(null)
    const [bodyHeight, setBodyHeight] = useState(0)
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions.height])

    useLayoutEffect(() => {
        if (parentRef?.current?.offsetTop) {
            setBodyHeight(windowDimensions.height - parentRef.current!.offsetTop! - props.bottomOffset)
        }
    }, [windowDimensions.height, props.bottomOffset])
    return (
        <Box ref={parentRef}>
            <Box
                sx={{ height: bodyHeight, overflowY: 'auto',pb:'10px',  ...props.sx}} justifyContent='center'>
                {props.children}
            </Box>
        </Box>
    )

}
