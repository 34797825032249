import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBasicEntityBase } from '../../data/Common';
import { IProcHeader, TLung, TProcDisposition, TSex } from '../../data/Procedure';
import { getNextStep, getPreviousStep, getStepTimeFromStartMin, getWorkflowStateFromStep, SteadySteps, TWorkflowState, TTimelineStep, TSetupStep } from '../../data/WorkflowSteps';
import { getBypassWaitingForNext, getStepRemainingTimePercent, getTimeFromStartHHMMSS, getTimeFromStartMin, getWarpedMilliseconds } from './allTime';
import { RunState } from './RunStore';
import { saveProcedureAsync, saveProcedureFromOfflineAsync } from './saveThunk';
import { getProcDataAsync } from './sliceTimeline';

const Fixed_respiratoryRate_bpm = 7
const Fixed_PEEP_cmH20 = 5
const Fixed_FiO2_Percent = 21


export interface IProcHeaderState {
    data: IProcHeader | null
    status: 'idle' | 'loading' | 'failed'
    startTime: number,  // this is duplicated with .data since frequent updates of the data cause multiple problems
    currentTime: number,
    currentStepPercentage: number
    dataLoaded: boolean
}


const initialState: IProcHeaderState = {
    data: null,
    status: 'idle',
    startTime: 0,  // this is duplicated with .data since massive updates cause flickering
    currentTime: 0,
    currentStepPercentage: 0,
    dataLoaded: false
}

export function calculateStateParameters(input: IProcHeader) {
    input.idealBodyWeight_Kg = Math.round((input.height_cm * input.height_cm / 10000)
        * (input.sex === 'male' ? 23 : 22) * 10) / 10

    if (input.sex === 'male') {
        input.pTLC =  Math.round((0.08 * input.height_cm + 0.003 * input.age_years - 7.333) * 100) / 100 
    } else {
        input.pTLC = Math.round((0.059 * input.height_cm - 4.537) * 100) / 100
    }
    
    input.cardiacOutput_LPmin = Math.sqrt(((input.height_cm * input.idealBodyWeight_Kg) / 3600)) * 2.4
    let evlpMultiplier = 0,
        ventilationMultiplier = 0
    switch (input.lung) {
        case 'both': evlpMultiplier = .4; ventilationMultiplier = 1.00; break
        case 'left': evlpMultiplier = .16; ventilationMultiplier = .40; break
        case 'right': evlpMultiplier = .24; ventilationMultiplier = .60; break
    }
    input.evlpMaxFlow_LPmin = input.cardiacOutput_LPmin * evlpMultiplier
    input.rampUpParameters = {
        '0:00': Math.round(input.evlpMaxFlow_LPmin * .10 * 10) / 10,
        '0:10': Math.round(input.evlpMaxFlow_LPmin * .20 * 10) / 10,
        '0:20': Math.round(input.evlpMaxFlow_LPmin * .30 * 10) / 10,
        '32°C': Math.round(input.evlpMaxFlow_LPmin * .30 * 10) / 10,
        '0:30': Math.round(input.evlpMaxFlow_LPmin * .50 * 10) / 10,
        '0:40': Math.round(input.evlpMaxFlow_LPmin * .80 * 10) / 10,
        '0:50': Math.round(input.evlpMaxFlow_LPmin * 1.0 * 10) / 10

    }
    input.steadyStateVV_mL = 10 * Math.round((input.idealBodyWeight_Kg * 7 * ventilationMultiplier) / 10)
    input.assessmentVV_mL = 10 * Math.round((input.idealBodyWeight_Kg * 10 * ventilationMultiplier) / 10)
    input.recruitmentVV_mL = 10 * Math.round((input.idealBodyWeight_Kg * 15 * ventilationMultiplier) / 10)

    input.bodyMassIndex = Math.round(10 * input.weight_Kg / (input.height_cm * input.height_cm / 10000)) / 10

    input.respiratoryRate_bpm = Fixed_respiratoryRate_bpm
    input.PEEP_cmH20 = Fixed_PEEP_cmH20
    input.FiO2_Percent = Fixed_FiO2_Percent
}


export interface ISetBoolean{
    item: string
    value: boolean
}

export const procHeaderSlice = createSlice({
    name: 'runProcHeader',
    initialState,
    reducers: {
        startProcedure:(state) => {
            state.data!.step = getNextStep(state.data!.step)
            state.startTime = Date.now()
            state.currentTime = Date.now()
        },
        finishProcedure: (state) => {
            state.data!.disposition = 'finished'
        },
        updateCurrentTime: (state, action) => {
            if (state.data === undefined || state.startTime === 0 || state.data!.disposition === 'finished') {
                return
            }
            state.currentTime = action.payload
            const minFromStart = getTimeFromStartMin(state.startTime, state.currentTime)
            const currentStep = state.data!.step
            if (currentStep === '0:20') { // Waiting for 32 C
                state.currentStepPercentage = -1 // Indeterminate progress bar
                return
            }

            const currentStepStartMin = getStepTimeFromStartMin(currentStep as TTimelineStep) 
            const nextStepStartMin = getStepTimeFromStartMin(getNextStep(currentStep) as TTimelineStep) 
            state.currentStepPercentage = getStepRemainingTimePercent(state.startTime, state.currentTime, currentStepStartMin, nextStepStartMin)
            if (minFromStart >= nextStepStartMin) {
                if (currentStep ===  SteadySteps[SteadySteps.length - 2]) {
                    // Do nothing for now, just stay here
                } else {
                    state.data!.step = getNextStep(state.data!.step)
                }
            }
        },
        setBoolean: (state, action: PayloadAction<ISetBoolean>) => {
            // @ts-ignore: TS7053
            state.data![action.payload.item] = action.payload.value 
        },
        setOtherIndications: (state, action:PayloadAction<string>) => {
            state.data!['otherIndications'] = action.payload
        },
        advance: (state) => {
            
            const nextStep = getNextStep(state.data!.step)
            state.data!.step = nextStep
            if (nextStep === '32°C') {
                state.data!.PABGAat32MinExpirationTime = state.currentTime + getWarpedMilliseconds(5)
            }
        },
        goBack: (state) => {
            if (state.data!.step !== 'Procedure') {
                state.data!.step = getPreviousStep(state.data!.step)
            }
        },
        setlung: (state, action: PayloadAction<TLung>) => {
            state.data!.lung = action.payload;
            calculateStateParameters(state.data!)
        },
        setgender: (state, action: PayloadAction<TSex>) => {
            state.data!.sex = action.payload;
            calculateStateParameters(state.data!)
        },
        setAge: (state, action: PayloadAction<number>) => {
            state.data!.age_years = action.payload;
        },
        setheightcm: (state, action: PayloadAction<number>) => {
            state.data!.height_cm = action.payload;
            calculateStateParameters(state.data!)
        },
        setweightkg: (state, action: PayloadAction<number>) => {
            state.data!.weight_Kg = action.payload;
            calculateStateParameters(state.data!)
        },
        setBloodType: (state, action) => {
            state.data!.bloodType = action.payload;
            calculateStateParameters(state.data!)
        },
        setDonorType: (state, action) => {
            state.data!.donorType = action.payload;
            calculateStateParameters(state.data!)
        },
        setsteadystatevv: (state, action: PayloadAction<number>) => {
            state.data!.steadyStateVV_mL = action.payload;
        },
        setassessmentvv: (state, action: PayloadAction<number>) => {
            state.data!.assessmentVV_mL = action.payload;
        },
        setrecruitmenttvv: (state, action: PayloadAction<number>) => {
            state.data!.recruitmentVV_mL = action.payload;
        },
        setrespiratoryrate: (state, action: PayloadAction<number>) => {
            state.data!.respiratoryRate_bpm = action.payload;
        },
        setpeep: (state, action: PayloadAction<number>) => {
            state.data!.PEEP_cmH20 = action.payload;
        },
        setfio2: (state, action: PayloadAction<number>) => {
            state.data!.FiO2_Percent = action.payload;
        },
        setIsLungsAccepted: (state, action: PayloadAction<boolean>) => {
            state.data!.isLungsAccepted = action.payload
        },
        setEvlpKitNumber: (state, action: PayloadAction<string>) => {
            state.data!.evlpKitNumber = action.payload
        },
        setEvlpCaseNumber: (state, action: PayloadAction<string>) => {
            state.data!.evlpCaseNumber = action.payload
        },
        setPerfusionist: (state, action: PayloadAction<string>) => {
            state.data!.perfusionist = action.payload
        },
        setHeader: (state, action: PayloadAction<IProcHeader>) => {
            state.data = action.payload
            calculateStateParameters(state.data)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProcHeaderAndDataAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getProcHeaderAndDataAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                const init = action.payload
                state.data = init
                if (init!.startTime !== undefined) {
                    state.currentTime = init!.currentTime
                    state.startTime = init!.startTime
                }
                state.dataLoaded = true;
                state.data!.disposition = 'ongoing'
            })
            .addCase(saveProcedureAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(saveProcedureAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                // TODO: process result - react on error
            })
            .addCase(saveProcedureFromOfflineAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(saveProcedureFromOfflineAsync.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(finishProcedureAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(finishProcedureAsync.fulfilled, (state, action) => {
                const ret = action.payload
                state.status = 'idle';
                if (ret !== null) {
                    state.data!.disposition = ret
                }
                // TODO: process result - reacto on error
            })

            .addCase(finishProcedureOfflineAsync.pending, (state) => {
                state.status = 'loading'
            })

            .addCase(finishProcedureOfflineAsync.fulfilled, (state, action) => {
                const ret = action.payload
                state.status = 'idle';
                if (ret !== null) {
                    state.data!.disposition = ret
                }
            })

        }
    }
)

export function getRampupParametersIndexFromStepIndex(generalStepIndex: string) {
    const retval = parseInt(generalStepIndex) - 7 < 4
        ? parseInt(generalStepIndex) - 7 // lenght of Clinical and Setup
        : parseInt(generalStepIndex) - 7 - 1 // Take in account empty flow rate for 32C step
    return retval 
}

export const selectProcHeader = (state: RunState) => state.procHeaderSlice.data
export const selectStep = (state: RunState) => state.procHeaderSlice.data?.step
export const selectStatus = (state: RunState) => state.procHeaderSlice.status
export const selectIsFinished = (state: RunState) => (state.procHeaderSlice.data?.disposition ?? 'init')   === 'finished'

export const selectRampupParameters = (state: RunState) => state.procHeaderSlice.data!.rampUpParameters
export const selectStepCompletionPercent = (state: RunState) => state.procHeaderSlice.currentStepPercentage

export const selectElaspedTimeHHMMSS = (state: RunState) => getTimeFromStartHHMMSS(state.procHeaderSlice.startTime, state.procHeaderSlice.currentTime)
export const selectCurrentTime = (state: RunState) => state.procHeaderSlice.currentTime
export const selectStartTime = (state: RunState) => state.procHeaderSlice.startTime

export const selectWorkflowState = (state: RunState) => getWorkflowStateFromStep(state.procHeaderSlice.data!.step)
export const selectDataLoaded = (state: RunState) => state.procHeaderSlice.dataLoaded

export const selectCanAdvance = (state: RunState) => {
    const step = state.procHeaderSlice.data!.step
    const currentState: TWorkflowState = getWorkflowStateFromStep(step)

    if (currentState === 'setup') {
        if (step === 'Procedure') {
            return state.procHeaderSlice.data?.evlpKitNumber !== '' && state.procHeaderSlice.data?.evlpCaseNumber !== ''
        } else {
            return  (((state.setupStepsSlice[step as TSetupStep]?.allChecked) || getBypassWaitingForNext()) && step !== 'Start')
        }
    } else {
        return false
    }
}

export const selectCanStartProcedure = (state: RunState) => {
    const step = state.procHeaderSlice.data!.step
    const currentState: TWorkflowState = getWorkflowStateFromStep(step)
    if (currentState === 'setup') {
        return step === 'Start' && (state.setupStepsSlice[step]?.allChecked || getBypassWaitingForNext())
    } else {
        return false
    }
}

export const selectCanGoBack = (state: RunState) => {
    const currentState: TWorkflowState = getWorkflowStateFromStep(state.procHeaderSlice.data!.step)
    return currentState === 'setup' && state.procHeaderSlice.data!.step !== 'Procedure'
}



export const { 
    setBoolean, setOtherIndications, advance, goBack, setlung, setgender, setheightcm, setAge, setHeader,
    setweightkg, setsteadystatevv,
    setassessmentvv, setrecruitmenttvv, setpeep, setrespiratoryrate, setfio2,
    updateCurrentTime, startProcedure, 
    setIsLungsAccepted, setEvlpKitNumber, setEvlpCaseNumber,
    setPerfusionist,
    setBloodType, setDonorType
} = procHeaderSlice.actions

export default procHeaderSlice.reducer

export const getProcHeaderAndDataAsync = createAsyncThunk(
    'procHeader/fetchProcHeader',
    async (identity: IBasicEntityBase, {dispatch, getState}) => {
        const requestUrl = `/api/getProcHeader?partitionKey=${identity.partitionKey}&rowKey=${identity.rowKey}`
        const response = await fetch(requestUrl)
        if (response.ok) {
            let json: IProcHeader = await response.json()
            if (json.startTime !== 0) { // We're in a timeline state
                await dispatch(getProcDataAsync(identity))
            } else {
                calculateStateParameters(json)
            }
            return json
        } else {
            console.log(`Error fetching proc header ${identity.partitionKey}:${identity.rowKey}. Status: ${response.status}`)
            return null
        }
    }
)


export const finishProcedureAsync = createAsyncThunk(
    'procHeader/finishProcedure',
    async (identity: IBasicEntityBase, {dispatch}) => {
        const requestUrl = `/api/finishProcedure?partitionKey=${identity.partitionKey}&rowKey=${identity.rowKey}`
        const response = await fetch(requestUrl)
        if (response.ok) {
            return 'finished' as TProcDisposition
        } else {
            console.log(`Error finishing procedure ${identity.partitionKey}:${identity.rowKey}. Status: ${response.status}`)
            return null
        }
    }
)

export const finishProcedureOfflineAsync = createAsyncThunk(
    'procHeader/finishProcedureOffline',
    async (identity: IBasicEntityBase) => {
        if (!identity.partitionKey || !identity.rowKey){
            return 'finished'
        }
        localStorage.setItem('finishProcedure', JSON.stringify(identity))
        return 'finished' as TProcDisposition
    }
)
