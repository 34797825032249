import CheckIcon from '@mui/icons-material/Check';
import { Divider, Stack, Typography } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Fragment } from 'react';
import { IOrg } from '../../../data/Org';
import { IPerson } from '../../../data/Person';
import EditEntityButton from './EditEntityButton';

const editWidth = '36px'
const orgLabelWidth = '150px'
const firstNameWidth = '200px'
const lastNameWidth = '200px'
const emailWidth = '300px'
const phoneWidth = '150px'
const isActiveWidth = '16px'


export function Header() {
    return (
        <Fragment>
        <Stack direction='row'>
            <Typography variant='h6' width={editWidth}> </Typography>
            <Typography variant='h6' width={orgLabelWidth}>Org. Label</Typography>
            <Typography variant='h6' width={firstNameWidth}>First Name</Typography>
            <Typography variant='h6' width={lastNameWidth}>Last Name</Typography>
            <Typography variant='h6' width={emailWidth}>eMail</Typography>
            <Typography variant='h6' width={phoneWidth}>Phone #</Typography>
            <Typography variant='h6' width={isActiveWidth}>Active?</Typography>
        </Stack>
        <Divider/>
        </Fragment>
    )
}

export function Body(
    props: {persons: Array<IPerson>, 
            updateAction: ActionCreatorWithPayload<IPerson, string>
            orgs:Array<IOrg>}) {
    return (
        <Fragment>
         { props.persons.map((x) => 
            <Stack direction='row' key={x.rowKey}>
                <EditEntityButton width={editWidth} item={x} items={props.persons} 
                    updateAction={props.updateAction} orgs={props.orgs}/>
                <Typography width={orgLabelWidth}>{x.orgLabel}</Typography>
                <Typography width={firstNameWidth}>{x.firstName}</Typography>
                <Typography width={lastNameWidth}>{x.lastName}</Typography>
                <Typography width={emailWidth}>{x.eMail}</Typography>
                <Typography width={phoneWidth}>{x.phoneNumber}</Typography>
                {x.isActive ? <CheckIcon width={isActiveWidth} fontSize='small'/> : ''}
            </Stack>)} 
        </Fragment>
    )
}
