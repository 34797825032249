import { CircularProgress } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { Body as ArchiveBody, Header as ArchiveHeader, TDeleteRequest } from '../../../common/ArchiveTable'
import { Body as CurrentBody, Header as CurrentHeader } from '../../../common/CurrentTable'
import { IProcHeader } from '../../../data/Procedure'
import MessageBox, { TMBReturnValue } from '../../../utils/MessageBox'
import { ResizableTable } from '../../../utils/ResizableTable'
import { useAppSelector } from '../AdminReduxHooks'
import { selectAll as selectHospitals } from '../org/HospitalsSlice'
import ViewSelector, { TSelectedView } from './ViewSelector'


export default function Device() {
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [selectedView, setSelectedView] = useState<TSelectedView>('current')
    const [deleteData, setDeleteData] = useState<{partitionKey: string, rowKey: string}|null>(null)

    const onViewSelectionChanged = (selectedView: TSelectedView) => {
        setSelectedView(selectedView)
    }

    const onConfirmationClosed = async (ret: TMBReturnValue) => {
        setShowConfirmation(false)
        if (ret === 'IDYES') {
            await deleteProcedureAsync(deleteData!.partitionKey, deleteData!.rowKey)
        }
        setDeleteData(null)
    }


    const deleteRequested = (partitionKey: string, rowKey: string) => {
        setDeleteData({partitionKey:partitionKey, rowKey:rowKey})
        setShowConfirmation(true)
    }
    return (
        <Fragment>
            <ViewSelector 
                initialSelection={selectedView} 
                selectionCallback={onViewSelectionChanged}/>
            <ResizableTable 
                header={selectedView === 'current' 
                        ? <CurrentHeader/> 
                        : <ArchiveHeader/>}
                body={<BodyWrapper key={Math.random()} target={selectedView} deleteRequest={deleteRequested}/>}
            />
            <MessageBox open={showConfirmation}
                    buttonsType='MB_YESNO'
                    message='Are you sure you want to delete the procedure?'
                    callback={(ret) => onConfirmationClosed(ret)}
                    severity='warning' />
        </Fragment>
    )
}

async function deleteProcedureAsync(partitionKey: string, rowKey:string) {
    await fetch(`/api/deleteArchivedProcedure?partitionKey=${partitionKey}&rowKey=${rowKey}`, {method:'delete'})
}


async function getArchiveAsync() {
    const response = await fetch('/api/getArchive?role=admin')
    return response.ok ? await response.json() : response.statusText
}

async function getCurrentAsync() {
    const response = await fetch('/api/getProcedures?role=admin')
    return response.ok ? await response.json() : response.statusText
}


function BodyWrapper({target, deleteRequest}:{target: TSelectedView, deleteRequest?:TDeleteRequest}) {
    const [data, setData] = useState<IProcHeader[] | null>(null)
    const [showProgress, setShowProgress] = useState(true)
    const hospitals = useAppSelector(selectHospitals)
    useEffect(() => {
        const ac = new AbortController()
        const fetchData = async () => {
            setShowProgress(true)
            const fetchResult = target === 'archive'
                ? await getArchiveAsync()
                : await getCurrentAsync()
            if (typeof fetchResult !== 'string') {
                setData(fetchResult)
            }
            setShowProgress(false)
        }

        fetchData()
        return () => { setData(null); ac.abort() }
    }, [target])

    if (showProgress) {
        return <CircularProgress size='96px' sx={{ position: 'absolute', top: '45%', left: '43%', transform: 'translate(-45%, -43%)' }} />
    } else {
        return (
            <>
                {data === null
                    ? 'No data'
                    : target === 'archive' 
                        ? <ArchiveBody hospitals={hospitals} rows={data!} deleteRequest={deleteRequest}/>
                        : <CurrentBody hospitals={hospitals} rows={data!}/>
                }
            </>)
    }

}

