import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ReactNode, useState } from "react";
import MessageAlert from './MessageAlert';

export interface IFormDialogProps {
    show: boolean
    title: string
    closeCallback: (isOk: boolean) => Promise<string> 
    form: ReactNode
}

export default function FormDialog(props: IFormDialogProps) {
    const [errorText, setErrorText] = useState('')
    const closeRequest = async (isOk: boolean) => {
        const returnedMessage = await props.closeCallback(isOk) 
        if (returnedMessage.length > 0) {
            setErrorText(returnedMessage)
            setTimeout(() => setErrorText(''), 15000)
        }
    }

    return (
        <Dialog open={props.show} fullWidth maxWidth='md' >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <MessageAlert msg = {errorText}/>
                {props.form}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeRequest(false)}>Cancel</Button>
                <Button onClick={() => closeRequest(true)}>OK</Button>
            </DialogActions>
        </Dialog>)
}