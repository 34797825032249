export const EntityTypes =       ['lab',  'tech', 'device', 'procedure', 'hospital', 'doctor', 'userLogs', 'userActionLogs'] as const
export const enum EntityTypeIndex {lab = 0, tech,   device,   procedure,   hospital,   doctor, userLogs, userActionLogs }
export type TEntity = typeof EntityTypes[number]

export const PersonTypes = [EntityTypes[EntityTypeIndex.tech], EntityTypes[EntityTypeIndex.doctor]] as const
export const enum PersonTypeIndex {tech = 0, doctor }
export type TPerson = typeof PersonTypes[number]

export const OrgTypes = [EntityTypes[EntityTypeIndex.lab], EntityTypes[EntityTypeIndex.hospital]] as const;
export const enum OrgTypeIndex { lab = 0, hospital }
export type TOrg = typeof OrgTypes[number];

export interface IBasicEntityBase {
    partitionKey: string | TOrg  
    rowKey: string 
}

export interface IEntityBase extends IBasicEntityBase {
    isActive?: boolean
}







