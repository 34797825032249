
export default function getInsighTxHTML(resulstMessage: string, listItems: string[], missing: string[]){

  const notEnoughDataForm = `<h1 style="color: #0074cc;"> INSIGHTx </h1>

  <div>
      <div style="display: flex; justify-content: flex-end; width: 100%;">
      </div>

      <div style="flex: 1; text-align: center; padding: 10px;">
         INSIGHTx available after the third assessment
      </div>
  </div>`

  const html = `<h1 style="color: #0074cc;"> INSIGHTx </h1>

  <div>
      <div style="display: flex; justify-content: flex-end; width: 100%;">
      </div>

      <div style="flex: 1; text-align: center; padding: 10px;">
          Donors with similar lungs are likely to be
          <span style="color: #0074cc;">${resulstMessage}</span>
      </div>
  </div>

  <div style="height: 2px; background-color: #0074cc; margin: 10px;"></div>
  
  <div style="display: flex; flex-direction: row;">
  
    <!-- Left Column -->
    <div style="flex: 1; padding-right: 20px;">
      <div style="padding: 10px;">
        <h3>Lung Performance</h3>
        <div>Important factors driving InsighTx Analysis: Top 5 Parameters</div>
        <ol>${listItems.join('')}</ol>
      </div>
    </div>
  
    <!-- Right Column -->
    <div style="flex: 1; padding-left: 20px;">
    <div style="padding: 10px; border: 1px solid #ccc; background-color: #f9f9f9;">
        <h3>InsighTx Prediction Notes</h3>
        <div>The following data was not available for the InsighTx Prediction:</div>
        ${missing.length? 
          `<ol style="list-style-type: disc;">
          <li><b>Biological:</b> ${missing.join(', ')}</li>
      </ol>
      `
          : '<div style="padding: 10px;"> There is no missing data </div>'}

          <div style="padding-top: 15px;"> 
          <strong>Impact on model performance</strong>
          <ol type="i">
              ${missing.length?`<li> The absence of these parameters impacts the model AUC by <1% </li>`:``}
              <li> The baseline prediction with no EVLP Data is Recipient Extubation <72h </li>
          </ol>
      </div>

      </div>

    </div>    
  </div>
  
  </div>
  `
  //return notEnoughDataForm
  return resulstMessage === ''? notEnoughDataForm : html
}