import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdminRootState } from '../../AdminStore'
import { getServiceSettingsAsync } from './ServiceSettingsThunk'
import { EditableServiceSettings } from './ServiceTypes'

export interface IServiceSettingsState {
    settings: EditableServiceSettings | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IServiceSettingsState = {
    settings: null,
    status: 'idle'
}

export const serviceSettingsSlice = createSlice({
    name: 'serviceSettings',
    initialState,
    reducers: {
        update: (state, action: PayloadAction<EditableServiceSettings>) => {
            state.settings = action.payload
        
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getServiceSettingsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getServiceSettingsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.settings = action.payload
            });
    },
})

export const { update } = serviceSettingsSlice.actions

export const selectAll = (state: AdminRootState) => state.serviceSettings.settings
export const selectStatus = (state: AdminRootState) => state.serviceSettings.status
export default serviceSettingsSlice.reducer
