import TabulatedDataView from '../../common/TabulatedDataView'
import { useAppSelector } from './monitorReduxHooks'
import { selectTransposed } from './sliceMonitorData'

export default function TabTabulated() {
    const d = useAppSelector(selectTransposed)
    return (
        d === undefined
            ? <>'Loading data'</>
            : <TabulatedDataView data={d} />
    )
}


