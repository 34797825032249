import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getProfileAsync } from '../../common/ProfileSlice';
import { replaceHeader } from '../../utils/replaceHeader';
import { useAppDispatch, useAppSelector } from './RunReduxHooks';
import { saveProcedureAsync } from './saveThunk';
import { getProcHeaderAndDataAsync, selectDataLoaded, selectIsFinished, selectProcHeader, selectStep, updateCurrentTime } from './sliceRunProcHeader';

export default function Run() {
  const { partitionKey, rowKey } = useParams()
  const dispatch = useAppDispatch()
  const proc = useAppSelector(selectProcHeader)
  const dataLoaded = useAppSelector(selectDataLoaded)
  const navigate = useNavigate()
  const workflowStateStepIndex = useAppSelector(selectStep)
  const isFinished = useAppSelector(selectIsFinished)


  useEffect(() => {
    replaceHeader('Wizard', `${window.location.origin}/runIcon.ico`)
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        dispatch(saveProcedureAsync());
      
      // This part is optional, but browsers usually show a generic message
      // when you don't return a string from the event handler.
      // By returning a string, you can provide a custom message to the user.
      event.returnValue = 'Are you sure you want to leave? Your data may not be saved.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);



  useEffect(() => {
    const timerId = setInterval(() => dispatch(updateCurrentTime(Date.now())), 1000) // We don't warp it, it's a main heartbeat
    return () => clearInterval(timerId)
  }, [dispatch])

  useEffect(() => {
    if (proc && dataLoaded) {
        dispatch(saveProcedureAsync())
    }
  }, [proc, proc?.step, dispatch, dataLoaded])

  useEffect(() => {
    const saveTimerId = setInterval(() => {
      if (dataLoaded) {
          dispatch(saveProcedureAsync())
      }
    },
      1 * 60 * 1000) // Still, the save is once a minute not to overload the api

    return () => clearInterval(saveTimerId)

  }, [dispatch, dataLoaded])

  useEffect(() => {
    dispatch(getProcHeaderAndDataAsync({ partitionKey: partitionKey!, rowKey: rowKey! }))
    dispatch(getProfileAsync()) 
  }, [dispatch, partitionKey, rowKey])

  useEffect(() => {
    if (proc !== null) {
      if (isFinished) {
        navigate('finish')
      } else {
        if (dataLoaded) {
          navigate(`s/${workflowStateStepIndex}`)
        }
      }
    }
  }, [proc, workflowStateStepIndex, isFinished, dataLoaded, navigate])

  return (
    <>

      {partitionKey === undefined || rowKey === undefined
        ? 'Invalid parameters'
        : proc === null || (!dataLoaded)
          ? <CircularProgress size='36px' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
          : <Outlet />
      }
    </>
  )
}

