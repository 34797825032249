import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPerson } from '../../../data/Common'
import { IPerson } from "../../../data/Person"

export interface IPersonsState {
    all: Array<IPerson>
    status: 'idle' | 'loading' | 'failed'
}

export function createPersonSliceHelper(
    name: TPerson, 
    initialState: IPersonsState, 
    actionCreator: any) {
    return createSlice({
        name: name,
        initialState,
        reducers: {
          add: (state, action: PayloadAction<IPerson>) => {
            state.all.push(action.payload)
          },
          update: (state, action: PayloadAction<IPerson>) => {
            const index = state.all.findIndex(x => x.rowKey === action.payload.rowKey) 
            if (index !== -1) {
              state.all[index] = action.payload
            }
          }
        },
        extraReducers: (builder) => {
            builder
              .addCase(actionCreator.pending, (state) => {
                state.all.splice(0)
                state.status = 'loading';
              })
              .addCase(actionCreator.fulfilled, (state, action) => {
                state.status = 'idle';
                state.all = action.payload;
              });
          },
      })    
}

