import { useAppSelector } from "../AdminReduxHooks"
import Page from "./Page"
import { selectAll, add, update } from "./TechsSlice"
import { selectAll as selectAllLabs } from "../org/LabsSlice"


export default function Tech() {
    const techs = useAppSelector(selectAll)
    const orgs = useAppSelector(selectAllLabs)

    return (<Page items={techs} itemType={'tech'} addAction={add} updateAction={update} orgs={orgs} />)
}
