import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchPersonsAsync } from "../common/FetchApi"

export const getTechsAsync = createAsyncThunk(
  'techs/fetchTechs',
  async () => {
      const response = await fetchPersonsAsync('tech')
      return response
  }
)

export const getDoctorsAsync = createAsyncThunk(
  'doctors/fetchDoctors',
  async () => {
      const response = await fetchPersonsAsync('doctor')
      return response
  }
)









