import CheckIcon from '@mui/icons-material/Check';
import { Divider, Stack, Typography } from '@mui/material';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Fragment } from 'react';
import { IDevice } from '../../../data/Device';
import EditEntityButton from './EditEntityButton';

const editWidth = '36px'
const labelWidth = '150px'
const orgLabelWidth = '150px'
const isActiveWidth = '16px'

export function Header() {
    return (
        <Fragment>
        <Stack direction='row'>
            <Typography variant='h6' width={editWidth}/>
            <Typography variant='h6' width={labelWidth}>Label</Typography>
            <Typography variant='h6' width={orgLabelWidth}>Belongs to</Typography>
            <Typography variant='h6' width={isActiveWidth}>Active?</Typography>
        </Stack>
        <Divider/>
        </Fragment>
    )
}

export function Body(
    props: {devices: Array<IDevice>, 
            updateAction?: ActionCreatorWithPayload<IDevice, string>
            }) {
    return (
        <Fragment>
         { props.devices.map((x) => 
            <Stack sx={x.isOverdueForService ? {color:'red'}: {}} direction='row' key={x.rowKey}>
                <EditEntityButton width={editWidth} item={x} />
                <Typography width={labelWidth}>{x.label}</Typography>
                <Typography width={orgLabelWidth}>{x.orgLabel}</Typography>
                {x.isActive ? <CheckIcon width={isActiveWidth} fontSize='small'/> : ''}
            </Stack>)} 
        </Fragment>
    )
}
