import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../routes/admin/AdminReduxHooks';
import { selectAllActionLogs } from '../routes/admin/userLogs/UserActionLogsSlice';
import { getUserActionLogsAsync } from '../routes/admin/userLogs/UserLogsThunk';

interface LogsDialogProps {
    openLogsDialog: boolean;
    handleOpenLogsDialog: (open: string | undefined) => void;
    partitionKey: string;
    rowKey: string;
}

const LogsDialog: React.FC<LogsDialogProps> = ({ openLogsDialog, handleOpenLogsDialog, partitionKey, rowKey }) => {
    const logs = useAppSelector(selectAllActionLogs) as unknown as string;
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserActionLogsAsync({partitionKey: partitionKey, rowKey: rowKey}));
    }, [dispatch, partitionKey, rowKey]);

    return (
        <Dialog 
            open={openLogsDialog} 
            onClose={() => handleOpenLogsDialog(undefined)}  
            sx={{
                '& .MuiBackdrop-root': {
                  backgroundColor: 'transparent', // Change to the desired background color
                  opacity: 1,

                },
              }}
            >
            <DialogTitle>Procedure Data Logs</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {logs ? JSON.parse(logs).userEmail  : ''}
                </DialogContentText>

                <DialogContentText id="alert-dialog-warning" style={{ fontWeight: 'bold' }}>
                    {logs ? JSON.parse(logs).data?.map((log: any) => JSON.stringify(log)) : 'No logs to show'}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleOpenLogsDialog(undefined)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LogsDialog;
