import { Button, Checkbox, Dialog, DialogActions, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import { ChangeEvent, useState } from 'react'
import Numpad from '../../utils/Numpad'
import { clearBypassWaitingForNext, clearSecondsInMinute, getBypassWaitingForNext, getSecondsInMinute, setBypassWaitingForNext, setSecondsInMinute } from './allTime'

export interface TestSettingDialogProps {
    show: boolean
    closeCallback: () => void
}

export default function TestSettingDialog({ show, closeCallback }: TestSettingDialogProps) {
    const [showCurtian, setShowCurtian] = useState(true)
    return (
        <Dialog maxWidth='sm' fullWidth open={show} >
            <DialogTitle>Traferox EVLP Wizard v{`${process.env.REACT_APP_VERSION}`}</DialogTitle>
            {showCurtian
                ? <Curtian confirmCallback={(confirmed) => confirmed ? setShowCurtian(false) : closeCallback()} />
                : <TestingSettings finishCallback={closeCallback} />
            }
        </Dialog>
    )
}


function Curtian({ confirmCallback }:{confirmCallback: (isConfirmed: boolean) => void}) {
    const [errorMessage, setErrorMessage] = useState('')

    const numpadFinished = (result: string) => {
        if (result === '5864') {
            confirmCallback(true)
        } else {
            setErrorMessage('Codes mismatch. Please try again')
        }
    }
    return (
        <>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" mt='20px'>
        Please enter security code 
            <Numpad resultcallback={numpadFinished} encodeResultDisplay={true} />
            <Typography mt='10px' variant='h6' color='red' textAlign='left' >{errorMessage}</Typography>
        </Grid>
        <DialogActions>
            <Button onClick={() => confirmCallback(false)}>Cancel</Button>
        </DialogActions>
        </>
    )
}

function TestingSettings({finishCallback}: {finishCallback:() => void}) {
    const [warp, setWarp] = useState(getSecondsInMinute())
    const [bypass, setBypass] = useState(getBypassWaitingForNext())
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWarp(+event.target.value)
    }

    const onCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBypass((e.target as HTMLInputElement).checked)
    }

    const onOk = () => {
        try {
            setSecondsInMinute(warp);
            setBypassWaitingForNext(bypass)
            finishCallback()
        } catch {
            alert('Invalid input')
        }
    }

    const onReset = () => {
        clearSecondsInMinute()
        clearBypassWaitingForNext()
        setWarp(getSecondsInMinute())
        setBypass(getBypassWaitingForNext())
    }

    return (
        <>
            <TextField sx={{ m: '10px' }}
                id="warpFactor"
                label="Enter number of seconds in a minute"
                type="number"
                inputProps={{ min: 10, step: 10, max: 60 }}
                onChange={onChange}
                value={warp}
            />
            <FormControlLabel sx={{ m: '10px' }} control={<Checkbox checked={bypass} onChange={onCheckChange} />} label="Bypass waiting for next" />
            <Button onClick={onReset}>Reset the values</Button>
            <DialogActions>
                <Button onClick={onOk} >OK</Button>
                <Button onClick={finishCallback}>Cancel</Button>
            </DialogActions>
        </>
    )
}

