import { FormControlLabel, Checkbox, Typography, Divider, Grid, Paper } from '@mui/material';
import { useEffect } from 'react';
import { isLungsFinished } from './finishUtils';
import { useAppDispatch, useAppSelector } from './RunReduxHooks';
import { FinishChecksLungs, selectFinishChecks, setcheck } from './sliceFinish';
import { selectProcHeader, setIsLungsAccepted } from './sliceRunProcHeader';
import { saveProcedureAsync } from './saveThunk';

export default function FTab1Lungs({ completion, declined, setDeclined, accepted, setAccepted, count, setCount }: 
        { completion: (allChecked: boolean) => void, declined: boolean, accepted: boolean, setDeclined: (b: boolean) => void,  
            setAccepted: (b: boolean) => void, count: number, setCount: (n: number) => void}) {
    const finishChecks = useAppSelector(selectFinishChecks)
    const procHeader = useAppSelector(selectProcHeader)
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        dispatch(saveProcedureAsync())
    }, [procHeader?.isLungsAccepted, dispatch])

    
    useEffect(()=>{
        if (accepted){
            dispatch(setIsLungsAccepted(true))
        }

    })
    
    useEffect(() => {
            completion(isLungsFinished(procHeader?.isLungsAccepted, finishChecks.lungsChecks))
    }, [finishChecks.lungsChecks, procHeader, accepted, completion])

    const handleLungsChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setcheck({ target: 'lungsChecks', index: index, value: event.target.checked }))
        if (event.target.checked)
            setCount(count+1)
        else
            setCount(count-1)
    };

    const handleIsLungsAcceptedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (declined){
            return
        }

        if (count && accepted){
            return
        }

        const target = event.target.checked
        
        if (!accepted){
            dispatch(setIsLungsAccepted(target))
        }
        setAccepted(target)
    };

    const handleIsLungsDeclineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (accepted)
            return

        const target = !declined
        setDeclined(target)
        dispatch(setIsLungsAccepted(!target))

    };

    return (
        <>
            <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                checked={declined}
                onChange={(e) => handleIsLungsDeclineChange(e)} />}
                label={<Typography variant='h5'>Lungs Declined</Typography>} />
            <Divider />
            
            <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                checked={accepted}
                onChange={(e) => handleIsLungsAcceptedChange(e)} />}
                label={<Typography variant='h5'>Lungs Accepted</Typography>} />
            <Divider />
            <Grid container justifyContent='center' sx={{ mt: '10px', pl: '30px' }}>
                {
                    FinishChecksLungs.map((element, index) =>
                        <Grid key={Math.random()} item xs={12}>
                            <Paper elevation={2} sx={{ mt: 1 }}>
                                <FormControlLabel disabled={!accepted} control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                                    checked={finishChecks.lungsChecks[element.checkIndex!]}
                                    onChange={(e) => handleLungsChange(element.checkIndex!, e)} />}
                                    label={<Typography variant='h5'>{element.label}</Typography>} />
                            </Paper>
                        </Grid>)
                }
            </Grid>
            <Divider />
            
        </>
    )
}