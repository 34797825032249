import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IBasicEntityBase } from '../../data/Common'
import { TMediaContent } from '../../data/Media'
import { MonitorState } from './monitorStore'
import { TStep } from '../../data/WorkflowSteps'

export interface IMediaContentState {
    data: TMediaContent | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IMediaContentState = {
    data: null,
    status: 'idle',
}

export const getMediaContentAsync = createAsyncThunk(
    'mediaContent/fetchMediaContent',
    async (identity: IBasicEntityBase) => {
        const requestUrl = `/api/getMediaContent?partitionKey=${identity.partitionKey}&rowKey=${identity.rowKey}`
        const response = await fetch(requestUrl)
        if (response.ok) {
            let json: TMediaContent = await response.json()
            return json
        } else {
            console.log(`Error fetching media content for procedure  ${identity.partitionKey}:${identity.rowKey}. Status: ${response.status}`)
            return null
        }
    }
)

export interface IImageComparisonFlagParams {
    device: string
    proc: string
    time: TStep
    name: string
    flag: boolean

}

export const setImageComparisonFlagAsync = createAsyncThunk(
    'mediaContent/setImageComparisonFlagAsync',
    async (p: IImageComparisonFlagParams) => {
        const requestUrl = 
            '/api/setImageComparisonFlag?'+
            `device=${p.device}&proc=${p.proc}&time=${p.time}&name=${p.name}&flag=${p.flag.toString()}`
            const response = await fetch(requestUrl)
        if (response.ok) {
            return p
        } else {
            console.log(`Error setting image comparison flag. p:${p.proc}, n:${p.name}`)
            return null
        }
    }
)

export const mediaContentSlice = createSlice({
    name: 'mediaContent',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMediaContentAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getMediaContentAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                const init = action.payload
                state.data = init
            })
            .addCase(setImageComparisonFlagAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(setImageComparisonFlagAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                const ret = action.payload
                if (ret !== null) {
                    const target = state.data![ret.time]!.find(c => c.name === action.payload?.name)
                    if (target) {
                        target.iscomparison = action.payload!.flag
                    }
                }
            })
        }
    }
)


export const selectMediaContent = (state: MonitorState) => state.mediaContentSlice.data
export const selectStatus = (state: MonitorState) => state.mediaContentSlice.status
export default mediaContentSlice.reducer


