import { createSlice } from "@reduxjs/toolkit"
import { AdminRootState } from "../AdminStore"
import { getUserActionLogsAsync } from "./UserLogsThunk"
import { ActionQueue } from "../../../data/Person"

export interface IUserActionLogState {
    all: ActionQueue | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IUserActionLogState = {
    all: null,
    status: 'idle'
}

export const userLogsSlice = createSlice({
    name: 'actionLogs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserActionLogsAsync.pending, (state) => {
                state.all = null
                state.status = 'loading';
            })
            .addCase(getUserActionLogsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.all = action.payload[0];
            });
    },
})



export const selectAllActionLogs = (state: AdminRootState) => state.userActionLogs.all
export const selectActionLogsStatus = (state:  AdminRootState) => state.userActionLogs.status

export default userLogsSlice.reducer