import { Paper } from '@mui/material';

export type TEntryItemProps = {hide?:boolean, children:any, sx?: any}

export default function EntryItem(props: TEntryItemProps) {
    return (
        props.hide
        ? null
        : <Paper elevation={2} sx={{ p: 1, m: 1, ...props.sx }}>
            {props.children}
        </Paper>

    )
}
