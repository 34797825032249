import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TSetupStep } from '../../data/WorkflowSteps'
import { RunState } from './RunStore'

type TCheckItem = {
    label: string
    checked: boolean
    subItems?: string[]
}

type TCheckItems = {
    [key in string]: TCheckItem
}

type TCheckSection = {
    allChecked: boolean
    label: string
    header?: string
    items: TCheckItems
}

type TSetupStateData = {
    [key in TSetupStep]: TCheckSection 
}


const initialState: TSetupStateData = {
    'Procedure': {
        allChecked:true, label: 'Procedure',
        items:{}
        },
    Cart: {
        allChecked: false, label: 'Cart',
        items: {
            '0': {checked: false, label: 'Place the Cart close to an electrical outlet'},
            '1': {checked: false, label: 'Lock the Cart casters'},
            '2': {checked: false, label: 'Plug the Cart into the electrical outlet'},
            '3': {checked: false, label: 'Check that the charging icon is shown on the Cart Control screen'},
            '4': {checked: false, label: 'Turn on Perfusion Console and Ventilator'},
        }
    },
    System: {
        allChecked: false, label: 'System',
        items: {
            '0': {checked: false, label: 'Perform ‘Self-Test’ on cart control screen'},
            '1': {checked: false, label: 'Connect the oxygen supply to the ventilator inlet (on the side of the Cart)'},
            '2': {checked: false, label: 'Connect EVLP gas line to the connection on the back of the cart'},
            '3': {checked: false, label: 'Connect ventilation tube to the ventilator outlet and place within track'},
            '4': {checked: false, label: 'Insert drip tray liner into the cart drip tray'},
            '5': {checked: false, label:  'Open blue flow sensor latch'},
            '7': {checked: false, label: 'Pull locking rods outward and place the Organ Chamber into the Cart'},
            '8': {checked: false, label: 'Press flow tubing into flow sensor and close the blue latch'},
            '9': {checked: false, label: 'Push locking rods inward to lock the organ chamber'},
           '10': {checked: false, label: 'Ensure temperature reading is present (room temperature)'},
           '11': {checked: false, label: 'Assemble LA knob onto organ chamber'},
           '12': {checked: false, label: 'Connect the hoses of the heater/cooler to the Cart'},
        }
    },
    Ventilator: {
        allChecked: false, label: 'Ventilator',
        items: {
            '0': {checked: false, label: 'Connect ventilation tube to the organ chamber inlet' },
            '1': {checked: false, label: 'Calibrate the oxygen sensor on the ventilator'},
            '2': {checked: false, label: 'Select the Human EVLP profile on the ventilator and perform the circuit test'},
            '3': {checked: false, label: 'Assemble ventilation filter branch and engage airway clamp'},
        }
    },
    Priming: {
        allChecked: false, label: 'Priming',
        items: {
            '0': {checked: false, label: 'Enter the Prime System mode on the cart control screen'},
            '1': {checked: false, label: 'Pour 2.0 L of perfusate into the fill port. Close fill port. Ensure flow reading is present'},
            '2': {checked: false, label: 'Zero perfusion flow sensor using perfusion console'},
            '3': {checked: false, label: 'Add EVLP medications to circuit', subItems:[
                'o	500 mg methylprednisolone ',
                'o	3000 UI unfractionated heparin',
                'o	500 mg antibiotic (imipenem/cilastatin)'
            ]},
            '4': {checked: false, label: 'Gradually ramp up the perfusion flow to 6000 RPM to de-air the circuit. Ensure there are no air bubbles present in the circuit'},
            '5': {checked: false, label: 'Reduce pump speed to 2000 RPM'},
        }
    },
    Cannulae: {
        allChecked: false, label: 'Post-Cannulation',
        items: {
            '0': {checked: false, label: 'Place cannulated lungs into the Organ Chamber' },
            '1': {checked: false, label: 'Connect the LA and PA pressure sensors to the Organ Chamber. Ensure pressure values are present'},
            '2': {checked: false, label: 'Enter the Run Perfusion mode on the Cart Control screen'},
            '3': {checked: false, label: 'Zero the pressure sensors on the perfusion console'},
        }
    },
    Start: {
        allChecked: false, label:'Start',
        items: {
            '0': {checked: false, label: 'Occlude LA pressure control knob until a flow of 500 ml/min is achieved (maintain speed at 2000 RPM)'},
            
            '1': {checked: false, label: 'Connect the lungs to the circuit as follows:',
                subItems:[
                    'o	Clamp the LA branch and open LA/PA connection. Direct flow into the PA cannula',
                    'o	De-air lung through PA cannula. Adjust speed as needed',
                    'o	Connect PA cannula to PA branch once de-aired',
                    'o	Direct LA flow into the LA branch. Connect once de-aired',
                    'o	Un-clamp LA branch and return pump speed to 2000 RPM. Ensure no air bubbles are present in circuit',
                ]
            },
        },
        }
    }



export interface ISetSetupCheck {
    section: TSetupStep
    itemId: string
    value: boolean
}




export const setupStepsSlice = createSlice({
    name: 'setupSteps',
    initialState: initialState,
    reducers: {
        setcheck: (state, action: PayloadAction<ISetSetupCheck>) => {
            state[action.payload.section]!.items[action.payload.itemId]!.checked = action.payload.value
            const allChecked = Object.values(state[action.payload.section]!.items).find(x => !x.checked) === undefined
            state[action.payload.section]!.allChecked = allChecked
                
        }
    }
})

export const selectSetupState = (state: RunState) => state.setupStepsSlice
export const { setcheck } = setupStepsSlice.actions
export default setupStepsSlice.reducer

