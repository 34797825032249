import { createAsyncThunk } from '@reduxjs/toolkit'
import { IProcSave } from '../../data/ProcSave'
import { RunState } from './RunStore'
import { updateLastUpdatedTime } from './sliceLastSavedTimeStore'

export const saveProcedureAsync = createAsyncThunk(
    'procHeader/saveProcedure',
    async (dummy:void, {getState, dispatch}) => {
        const procHeaderSlice  = (getState() as RunState).procHeaderSlice
        const savePayload:IProcSave = {
            header: {...procHeaderSlice.data!},
            data: (getState() as RunState).timelineSlice.data
        }
        // We're copying the time from the state to the header because
        // we didn't store frequiently changing values in the data to 
        // avoid excessive refreshes
        const currentTime = procHeaderSlice.currentTime
        savePayload.header.currentTime = currentTime
        savePayload.header.startTime = procHeaderSlice.startTime
        const savedProc = localStorage.getItem('savedProcedure')
        if (navigator.onLine && !savedProc){
            dispatch(updateLastUpdatedTime(currentTime))
            await fetch('/api/saveProcedure', { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(savePayload)
          })
        } else {
            localStorage.setItem('savedProcedure', JSON.stringify(savePayload))
        }
    }
)

export const saveProcedureFromOfflineAsync = createAsyncThunk(
    'procHeader/saveProcedureFromOffline',
    async (savePayload: string) => {
        try {
            await fetch('/api/saveProcedure', { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(savePayload)
            })
        } catch (e) {
            console.log(`Error ${e}in saving procedure from offline data`)
        }
    }
)