import EngineeringOutlinedIcon  from '@mui/icons-material/EngineeringOutlined'
import { IconButton } from '@mui/material'
import { Fragment, useState } from 'react'
import { IDevice } from '../../../data/Device'
import EditDialog from './EditServiceDialog'

export interface IEditEntityButtonProps {
    width: string
    item: IDevice
} 

export default function ServiceEntityButton(props: IEditEntityButtonProps) {
    const [showDialog, setShowDialog] = useState(false)
    const onDialogFinished = (isOk: boolean) => {
        setShowDialog(false)
    }

    return (
        <Fragment>
            <IconButton sx={{mt:'-5px'}} onClick={() =>setShowDialog(true)} >
                <EngineeringOutlinedIcon width={props.width} fontSize='small'/>
            </IconButton>
            <EditDialog key={Math.random()} show={showDialog} closeCallback={onDialogFinished} item={props.item}/> 
        </Fragment>
    )    
}

