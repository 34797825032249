import { TDataDictionaryBase } from './ddBase'
import { IEVLPIndications } from './Procedure'

export type TEVLPIndicationsDataDictionary = TDataDictionaryBase<keyof IEVLPIndications>
export const EVLPIndicationsDataDictionary: TEVLPIndicationsDataDictionary = {
    isConsernsOfAspiration: { label: 'Concerns of aspiration', step: 1 },
    isLowDonorPO2: { label: 'Low donor PO2', step: 1 },
    isFromMarginalDCD: { label: 'Lung from marginal DCD', step: 1 },
    isHighRiskHistory: { label: 'High risk history', step: 1 },
    isPresenceOfEndema: { label: 'Presence of pulmonary edema', step: 1 },
    isPoorCompliance: { label: 'Poor lung/lobe compliance', step: 1 },
    otherIndications: { label: 'Other indications', step: 1 }
}
