import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBasicEntityBase } from '../data/Common';
import { IMonitorDoctor } from '../data/Person';
import { MonitorState } from '../routes/monitor/monitorStore';

export interface IMonitorDoctorsState {
    data: IMonitorDoctor[] | null
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IMonitorDoctorsState = {
    data: null,
    status: 'idle'
}

export const getMonotorDoctorsAsync = createAsyncThunk(
    'monitorDoctors/getDoctors',
    async (identity: IBasicEntityBase) => {
        const requestUrl = `/api/getHospitalDoctors?partitionKey=${identity.partitionKey}&rowKey=${identity.rowKey}`
        const response = await fetch(requestUrl)
        if (response.ok) {
            const json: IMonitorDoctor[] = await response.json()
            return json
        } else {
            console.log(`Error fetching monitor doctors data from ${identity.partitionKey}: ${identity.rowKey}. Status: ${response.status}`)
            return null
        }
    }
)

export interface ISetCheck {
    id: string,
    value: boolean
}

export const monitorDoctorsSlice = createSlice({
    name: 'monitorDoctors',
    initialState: initialState,
    reducers: {
        setSend: (state, action: PayloadAction<ISetCheck>) => {
            let index = state.data!.findIndex(x => x.rowKey === action.payload.id)
            state.data![index].isSelectedForReport = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMonotorDoctorsAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getMonotorDoctorsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload!
            })
    }
})

export const selectDoctors = (state: MonitorState) => {
    const data = state.doctorsSlice.data?.filter(x => x.isActive)
    if (data === undefined)
        return []
    return data
}
export const selectStatus = (state: MonitorState) => state.doctorsSlice.status
export const selectCanSend = (state: MonitorState) => {
    if (state.doctorsSlice.data) {
        return state.doctorsSlice.data!.reduce((accumulator, currentValue) => accumulator || currentValue.isSelectedForReport!, false)
    } else {
        return false
    }
}

export const { setSend } = monitorDoctorsSlice.actions
export default monitorDoctorsSlice.reducer