import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBasicEntityBase } from '../../data/Common'
import { IProcHeader } from '../../data/Procedure'
import { MonitorState } from './monitorStore'
import { getProcDataAsync } from './sliceMonitorData'

export interface IProcHeaderState {
    data: IProcHeader | null
    allowUpdates: boolean
    status: 'idle' | 'loading' | 'failed'
}

const initialState: IProcHeaderState = {
    data: null,
    allowUpdates: true,
    status: 'idle',

}

export const getProcHeaderAsync = createAsyncThunk(
    'monitorProcHeader/fetchProcHeader',
    async (identity: IBasicEntityBase, {dispatch}) => {
        const requestUrl = `/api/getProcHeader?partitionKey=${identity.partitionKey}&rowKey=${identity.rowKey}`
        const response = await fetch(requestUrl)
        if (response.ok) {
            let json: IProcHeader = await response.json()
            dispatch(getProcDataAsync(identity))
            return json
        } else {
            console.log(`Error fetching proc header ${identity.partitionKey}:${identity.rowKey}. Status: ${response.status}`)
            return null
        }
    }
)

export const procHeaderSlice = createSlice({
    name: 'monitorProcHeader',
    initialState,
    reducers: {
        setAllowUpdates: (state, action: PayloadAction<boolean>) => {
            state.allowUpdates = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProcHeaderAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getProcHeaderAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                const init = action.payload
                state.data = init
            })
        }
    }
)


export const selectProcHeader = (state: MonitorState) => state.procHeaderSlice.data
export const selectStatus = (state: MonitorState) => state.procHeaderSlice.status
export const selectAllowUpdates = (state: MonitorState) => state.procHeaderSlice.allowUpdates

export const { setAllowUpdates } = procHeaderSlice.actions
export default procHeaderSlice.reducer


