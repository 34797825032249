import { useEffect, useState } from 'react';
import { selectProfile } from '../../../common/ProfileSlice';
import { IProcHeader } from '../../../data/Procedure';
import AutoScrollContainer from '../../../utils/AutoScrollContainer';
import { useAppDispatch, useAppSelector } from '../TechReduxHooks';
import AddProcDialog from './AddProcDialog';
import { archiveProcedureAsync, cancelProcedureAsync } from './fetchApi';
import NavBar from './NavBar';

import ProcCard from './ProcCard';
import { getProceduresAsync, remove, selectAll, startProcedureAsync, update } from './ProceduresSlice';


export default function Current() {

    const [showAddDialog, setShowAddDialog] = useState(false)
    const procedures = useAppSelector(selectAll)
        .slice()
        .sort((first, second) => second.recordCreatedAt! - first.recordCreatedAt!)

    const profile = useAppSelector(selectProfile)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const interval = setInterval(() => {
            if (!showAddDialog) {
                dispatch(getProceduresAsync())
            }
        }, 1 * 60 * 1000);
        return () => clearInterval(interval);
    }, [dispatch, showAddDialog]);

    
    const onAddClicked = () => {
        setShowAddDialog(true)
    }
    const onAddClosed = (isOk: boolean) => {
        setShowAddDialog(false)
    }

    const onCancelProcedure = async (i: IProcHeader) => {
        await cancelProcedureAsync(i.partitionKey, i.rowKey)
        dispatch(remove(i.rowKey))
    }

    const onArchiveProcedure = async (i: IProcHeader) => {
        await archiveProcedureAsync(i.partitionKey, i.rowKey)
        dispatch(remove(i.rowKey))
    }

    const onFirstButton = (i: IProcHeader) => {
        switch (i.disposition) {
            case 'init':
                dispatch(startProcedureAsync({
                    rowKey: i.rowKey,
                    partitionKey: i.partitionKey,
                    lockingClient: 'clientId',
                    performedBy: profile!.person.eMail
                }))
                window.open(`/run/${i.partitionKey}/${i.rowKey}/s/0`, '_blank')
                break
            case 'ongoing':
                window.open(`/monitor/${i.partitionKey}/${i.rowKey}`, '_blank')
                break
            case 'idle':
                window.open(`/run/${i.partitionKey}/${i.rowKey}/s/0`, '_blank')
                const updated = { ...i }
                updated.disposition = 'ongoing'
                dispatch(update(updated))
                break;
            case 'finished': 
            case 'abandoned': 
                window.open(`/monitor/${i.partitionKey}/${i.rowKey}`, '_blank')
                break
    }
    }

    return (
        <>
            <AutoScrollContainer bottomOffset={70} >
                {procedures.map(p =>
                    <ProcCard d={p} key={p.rowKey} sx={{ mt: '5px' }}
                        onFirstButton={() => onFirstButton(p)}
                        onSecondButton={p.disposition === 'init' 
                                        ? () => onCancelProcedure(p) 
                                        : p.disposition === 'finished' || p.disposition === 'abandoned'
                                          ? () => onArchiveProcedure(p)
                                          : undefined} />
                )}
            </AutoScrollContainer>
            <NavBar onAddClicked={onAddClicked} />
            {profile !== undefined
                ? <AddProcDialog key={Math.random()} show={showAddDialog} closeCallback={onAddClosed} />
                : ''
            }
        </>)
}

