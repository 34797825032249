import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { TDataField, TEntryField } from '../../data/ProcData'
import { TTimelineStep } from '../../data/WorkflowSteps'
import { dataDictionary } from '../../data/dd'
import NumericInput from '../../utils/NumericInput'
import { useAppDispatch, useAppSelector } from './RunReduxHooks'
import { selectData, setfield } from './sliceTimeline'
import { selectProcHeader } from './sliceRunProcHeader'
import { updateActionLogsAsync } from '../admin/common/FetchApi'

type TDictionaryEntryFieldSpecificProps = { 
    field: TEntryField, 
    step: string | undefined,
    errorOnEmptyInit?: boolean
    onDictionaryFieldChange?: (field: TEntryField, newValue: number | undefined) => void 
}
type TDictionaryEntryFieldProps     = TDictionaryEntryFieldSpecificProps & TextFieldProps

type TDictionaryEntryFormFieldSpecificProps = { 
    field: TEntryField, 
    initialValue: number | undefined, 
    errorOnEmptyInit?: boolean
    onDictionaryFieldChange: (field: TEntryField, newValue: number | undefined) => void
}
type TDictionaryEntryFormFieldProps = TDictionaryEntryFormFieldSpecificProps & TextFieldProps



type TDictionaryViewFieldSpecificProps = { field: TDataField, step: string | undefined }
type TDictionaryViewFieldProps = TDictionaryViewFieldSpecificProps & TextFieldProps


export function ViewNumberField(props: TDictionaryViewFieldProps) {
    const field = (props as TDictionaryEntryFieldSpecificProps).field
    const step = props.step as TTimelineStep
    const fieldValue = useAppSelector(selectData)[step][field]
    const fd = dataDictionary[field]
    let displayFieldValue = fieldValue
    if (displayFieldValue !== undefined) {
        if (fd.step === 0.1) {
            displayFieldValue = (fieldValue as number).toFixed(1)
        } else if (fd.step === 0.01) {
            displayFieldValue = (fieldValue as number).toFixed(2)
        } else {
            displayFieldValue = (fieldValue as number).toFixed(0)
        }
    }

    return (
        <TextField type="number"
            disabled
            variant='standard'
            label= {fd.label}
            value={fieldValue === undefined ? '' : displayFieldValue}
            {...props}
            InputProps={
                {
                    endAdornment: <InputAdornment position="end">{fd.unit}</InputAdornment>,
                }
            }
            inputProps={{
                style: { textAlign: 'right', fontWeight: 'bold' },
                maxLength: 130,
                step: fd.step,
                min: fd.min,
                max: fd.max,
            }} />
    )
}

export default function DictionaryNumberField(props: TDictionaryEntryFieldProps) {
    const field = (props as TDictionaryEntryFieldSpecificProps).field
    const fieldValue = useAppSelector(selectData)[props.step as TTimelineStep][field] as number
    const dispatch = useAppDispatch()
    const fd = dataDictionary[field]
    const readonlyHeader = useAppSelector(selectProcHeader)


    const handleChange = (newNumber: number) => {
        const newField = {
            step: props.step as TTimelineStep,
            fieldName: field,
            value: newNumber
        }

        dispatch(setfield(newField))


        const proc_id = JSON.stringify({partitionKey: readonlyHeader?.partitionKey, rowKey: readonlyHeader?.rowKey})
        const email = readonlyHeader?.performedBy
        updateActionLogsAsync(email?email:'', proc_id, JSON.stringify(newField))
        
    }
    const otherProps = {...props}
    delete otherProps.step

    
    return (
        <NumericInput
            step={fd.step}
            label={fd.label} 
            min={fd.min}
            max={fd.max}
            unit={fd.unit}
            minRecomended={fd.minRecomended}
            maxRecomended={fd.maxRecomended}
            initValue={fieldValue} 
            onNumberChanged={(newNumber:number) => handleChange(newNumber)}
            errorOnEmptyInit = {props.errorOnEmptyInit}
            {...otherProps as TextFieldProps}
            />
    )
}

export function DictionaryFormNumberField(props: TDictionaryEntryFormFieldProps) {
    const field = (props as TDictionaryEntryFormFieldSpecificProps).field
    const fd = dataDictionary[field]

    const handleChange = (newNumber:number) => {
        props.onDictionaryFieldChange(field, newNumber)
    }

    return (
        <NumericInput
            step={fd.step}
            label={fd.label} 
            min={fd.min}
            max={fd.max}
            minRecomended={fd.minRecomended}
            maxRecomended={fd.maxRecomended}
            unit={fd.unit}
            initValue={props.initialValue} 
            onNumberChanged={(newNumber:number) => handleChange(newNumber)}
            errorOnEmptyInit = {props.errorOnEmptyInit}
            {...props}
            />
    )
}

