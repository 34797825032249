import { Link as MUILink, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { replaceHeader } from '../../utils/replaceHeader';
import WaitingLabel from '../../utils/WaitingLabel';
import { getWarpedMilliseconds } from '../run/allTime';
import { useAppDispatch, useAppSelector } from './monitorReduxHooks';
import { getMediaContentAsync, selectStatus as selectMediaContentStatus } from './silceMediaContent';
import { getNotesAsync, selectStatus as selectNotesStatus } from './silceNotes';
import { selectStatus as selectDataStatus } from './sliceMonitorData';
import { getMonotorDoctorsAsync, selectStatus as selectDoctorsStatus } from '../../common/sliceMonitorDoctors';
import { getProcHeaderAsync, selectAllowUpdates, selectStatus as selectProcHeaderStatus } from './sliceMonitorProcHeader';
import { getTechsAsync } from '../../common/sliceTechs';
import { getPreferencesAsync } from '../../common/slicePreferences';
import { getUserInfoAsync } from '../../utils/PrinicpalUtils';
import { selectPredictionStatus, setPredictionAsync } from '../../common/slicePrediction';

let refreshInterval: NodeJS.Timer

export default function Monitor() {
    const {partitionKey, rowKey} = useParams()
    const [tabValue, setTabValue] = useState(0)
    const dispatch = useAppDispatch()
    const procHeaderStatus = useAppSelector(selectProcHeaderStatus)
    const procDataStatus = useAppSelector(selectDataStatus)
    const mediaContentStatus = useAppSelector(selectMediaContentStatus)
    const doctorsStatus = useAppSelector(selectDoctorsStatus)
    const notesStatus = useAppSelector(selectNotesStatus)
    const navigate = useNavigate()
    const updatesAllowed = useAppSelector(selectAllowUpdates)
    const predictionStatus = useAppSelector(selectPredictionStatus)

    useEffect(() => {
        replaceHeader('Monitor', `${window.location.origin}/monitorIcon.ico`)
    },[])
    
    useEffect( () => {
        refreshInterval = setInterval(()=> {
                if (updatesAllowed) {
                    dispatch(getProcHeaderAsync({ partitionKey: partitionKey!, rowKey: rowKey! }))
                    dispatch(getMediaContentAsync( { partitionKey: partitionKey!, rowKey: rowKey! } ))
                    dispatch(getNotesAsync( { partitionKey: partitionKey!, rowKey: rowKey! } ))
                } 
            }
            , getWarpedMilliseconds(1))
        return () => clearInterval(refreshInterval)
    }, [updatesAllowed, dispatch, partitionKey, rowKey]) 

    useEffect( () => {
        navigate('procedure')
    }, []) // eslint-disable-line

    useEffect( () => {
        dispatch(getProcHeaderAsync({ partitionKey: partitionKey!, rowKey: rowKey! }))
        dispatch(getMediaContentAsync( { partitionKey: partitionKey!, rowKey: rowKey! } ))
        dispatch(getMonotorDoctorsAsync( { partitionKey: partitionKey!, rowKey: rowKey! } ))
        dispatch(getNotesAsync( { partitionKey: partitionKey!, rowKey: rowKey! } ))
        dispatch(getTechsAsync( partitionKey ))
        dispatch(setPredictionAsync( { partitionKey: partitionKey!, rowKey: rowKey! }))
    }, [dispatch, partitionKey, rowKey])


    useEffect( () => {
        getUserInfoAsync().then((user) => {
            const roles = user?.userRoles
            let table: 'doctor' | 'tech' | null = null
            if (roles.indexOf('doctor') > -1)
                table = 'doctor'
            else if (roles.indexOf('tech') > -1)
                table = 'tech'
            const eMail = user.userDetails
            if (table)
                dispatch(getPreferencesAsync({table, eMail}))
        } )

    }, [dispatch])
  
    const handleTabValueChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }
    
    // const send = async () => {
    //     await SendDoctorInvite('mike.shtyrev@gmail.com')
    // }
    // change doc status for insightx
    return (
        <>
            <Tabs value={tabValue} onChange={handleTabValueChange}  sx={{ mb: '5px' }}>
                <Tab value={0} label={<WaitingLabel title='Procedure' target={[procHeaderStatus, procDataStatus]} />} to={'procedure'} component={Link} />
                <Tab value={1} label={<WaitingLabel title='Tabulated' target={[procHeaderStatus, procDataStatus]} />} to={'tabulated'} component={Link} />
                <Tab value={2} label={<WaitingLabel title='Graphical' target={[procHeaderStatus, procDataStatus]} />} to={'graphical'} component={Link} />
                <Tab value={3} label={<WaitingLabel title='Media' target={[mediaContentStatus, notesStatus]} />} to={'media'} component={Link} />
                <Tab value={4} label={<WaitingLabel title='Report' target={[predictionStatus, doctorsStatus]} />} disabled={predictionStatus === 'loading' || doctorsStatus  === 'loading'} to={'report'} component={Link} />
                <Tab value={5} label={<WaitingLabel title='InsighTx' target={predictionStatus} />} disabled={predictionStatus === 'loading'} to={'InsighTx'} component={Link} /> 
            </Tabs>
            <MUILink href='/.auth/logout' sx={{ position: 'absolute', top: 0, right: 8 }}>Log out</MUILink>

            <Outlet />
        </>
    )
}