import { Box, Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import DF from './DictionaryNumberField'
import EntryItem from './RampupEntryItem'
import { useAppDispatch, useAppSelector } from './RunReduxHooks'
import { selectCurrentTime, selectProcHeader, setBoolean } from './sliceRunProcHeader'
import { selectData } from './sliceTimeline'

export default function UIRampupBGAOnPASampleSelector() {
    const currentTime = useAppSelector(selectCurrentTime)
    const procHeader = useAppSelector(selectProcHeader)
    const fiveMinExpirationTime = procHeader?.PABGAat32MinExpirationTime
    if (fiveMinExpirationTime && fiveMinExpirationTime < currentTime && !procHeader.isPABGADataEnteredAt32Cplus5min) {
        return (<PABGAEntryUI/>)
    } else {
        return (<></>)
    }
}

function PABGAEntryUI() {
    const [isDoneDisabled, setIsDoneDisabled] = useState(true)
    const data = useAppSelector(selectData)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (data['32°C'].PApH && data['32°C'].PAPCO2 && data['32°C'].PAPO2) {
            setIsDoneDisabled(false)
        }
    },[data])

    return(
    <Box sx={{mt:2}}>
            <EntryItem >
                <Typography variant='h5' color='warning.main'>Perform BGA on PA sample. Ensure pCO2 is within 35-45 mm Hg. Enter data and click 'Done' when ready</Typography>
                <Typography mt='10px' variant='h5'>Enter BGA variables</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <DF fullWidth field={'PApH'} step={'32°C'} />
                    </Grid>
                    <Grid item xs={4}>
                        <DF fullWidth field={'PAPCO2'} step={'32°C'} />
                    </Grid>
                    <Grid item xs={4}>
                        <DF fullWidth field={'PAPO2'} step={'32°C'} />
                    </Grid>
                </Grid>
            </EntryItem>
            <EntryItem >
                <Grid container spacing={1}>
                    <Grid item xs={11}>
                        <Typography variant='h5' color='warning.main'>Adjust EVLP gas flow as needed</Typography>
                    </Grid>
                    <Grid item xs={1} sx={{textAlign:'right'}}>
                        <Button disabled={isDoneDisabled} onClick={() => dispatch(setBoolean({item:'isPABGADataEnteredAt32Cplus5min', value:true }))}>Done</Button>
                    </Grid>
                </Grid>
                
            </EntryItem>
            
        </Box>
    )
}