import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchDevicesAsync } from "../common/FetchApi"

export const getDevicesAsync = createAsyncThunk(
  'devices/fetchDevices',
  async () => {
      const response = await fetchDevicesAsync()
      return response
  }
)










