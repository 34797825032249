import { CircularProgress } from '@mui/material'
import ProcInfoView from '../../common/ProcInfoView'
import { useAppSelector } from './monitorReduxHooks'
import { selectProcHeader } from './sliceMonitorProcHeader'

export default function TabProcedure() {
    const d = useAppSelector(selectProcHeader)
    return (
        <>
            {d
                ? <>
                    <ProcInfoView d={d}/>
                  </>
                : <CircularProgress size='36px' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
        </>
    )
}

